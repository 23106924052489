import * as React from 'react';
import { useSelector } from 'react-redux';
import { getContractsToCompare } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { getPropsAsArray } from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import CompareContractsPDFButton from 'features/riesterProfiCheck/insurance_contracts/compare/pdf/CompareContractsPDFButton';

interface MyTableRowProps {
    title: string;
    com1: string;
    val1: string;
    val2: string;
    com2: string;
}

const MyTableRow: React.FC<MyTableRowProps> = ({ title, com1, val1, val2, com2 }) => {
    return (
        <TableRow key={title}>
            <TableCell component="th" scope="row" style={{ fontWeight: 'bold', width: '20%' }}>
                {title}
            </TableCell>
            <TableCell style={{ width: '25%' }} align="left">
                {com1}
            </TableCell>
            <TableCell align="center">{val1}</TableCell>
            <TableCell align="center">{val2}</TableCell>
            <TableCell style={{ width: '25%' }} align={'left'}>
                {com2}
            </TableCell>
        </TableRow>
    );
};

const CompareContractsPage: React.FC = () => {
    const contracts = useSelector(getContractsToCompare);


    const contract1 = contracts[0];
    const contract2 = contracts[1];


    const contract1Props = getPropsAsArray(contract1, true);
    const contract2Props = getPropsAsArray(contract2, true);

    return (
        <NavigateBackScaffold
            title={`${contract1.name} | ${contract2.name}`}
            actions={
                <>
                    <CompareContractsPDFButton />
                </>
            }
        >
            <TableContainer component={Paper} style={{ marginBottom: '24px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Anbieter (Tarif)</TableCell>
                            <TableCell align="left">Kommentar</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">
                                {contract1.name}
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">
                                {contract2.name}
                            </TableCell>
                            <TableCell align="left">Kommentar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contract1Props.map((prop, index) => {
                            if (index === 0) {
                                return <></>;
                            } else {
                                return (
                                    <MyTableRow
                                        title={prop.header.title}
                                        com1={contract1Props[index].comment}
                                        val1={contract1Props[index].prop}
                                        val2={contract2Props[index].prop}
                                        com2={contract2Props[index].comment}
                                    />
                                );
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </NavigateBackScaffold>
    );
};

export default CompareContractsPage;
