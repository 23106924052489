import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';
import TableHeaders, { TableHeadersWithoutNewline } from 'features/riesterProfiCheck/consultation/models/TableHeaders';
import TableHeader from 'features/riesterProfiCheck/consultation/models/TableHeader';
import _, { filter } from 'lodash';
import { Contracts } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import moment from 'moment';

export const getOldContracts = (contracts: Contracts) => {
    return filter(contracts.entities, (contract) => {
        if (!contract.validUntil) {
            return false;
        }
        return moment().isAfter(moment(contract.validUntil, 'DD.MM.YYYY'));
    });
};

export const getNewContracts = (contracts: Contracts) => {
    return filter(contracts.entities, (contract) => {

        if (moment().isBefore(moment(contract.validFrom, 'DD.MM.YYYY'))) {
            return false
        }
        if (moment().isAfter(moment(contract.validFrom, 'DD.MM.YYYY')) && !contract.validUntil) {
            return true;
        }

        return moment().isBefore(moment(contract.validUntil, 'DD.MM.YYYY'));
    });
};
export const orderContracts = (contracts: Array<InsuranceContractModel>): Array<InsuranceContractModel> => {
    const sorted = _.orderBy(
        contracts,
        [
            (contract) => {
                if (contract.name === undefined) {
                    console.error(contract);
                    return 'AAA';
                }
                return contract.name.toLowerCase();
            }
        ],
        ['asc']
    );

    const bsv: Array<InsuranceContractModel> = filter(sorted, (contract: InsuranceContractModel) => contract.vertragsart === 'BSV');
    const bsp: Array<InsuranceContractModel> = filter(sorted, (contract: InsuranceContractModel) => contract.vertragsart === 'BSP');
    const fsp: Array<InsuranceContractModel> = filter(sorted, (contract: InsuranceContractModel) => contract.vertragsart === 'FSP');
    const rest: Array<InsuranceContractModel> = filter(
        sorted,
        (contract: InsuranceContractModel) => contract.vertragsart !== 'FSP' && contract.vertragsart !== 'BSV' && contract.vertragsart !== 'BSP'
    );
    return [...rest, ...fsp, ...bsp, ...bsv];
};

export const getValidityString = (contract: InsuranceContractModel) => {
    if (contract.validFrom && contract.validFrom !== '' && contract.validUntil && contract.validUntil !== '') {
        return contract.validFrom.toString() + ' - ' + contract.validUntil.toString();
    } else if (contract.validUntil === '' && contract.validFrom && contract.validFrom !== '') {
        return 'ab ' + contract.validFrom.toString();
    } else {
        return 'unbekannt';
    }
};

export const getPropsAsArray = (
    contract: InsuranceContractModel,
    showEffektivkosten: boolean
): Array<{
    header: TableHeader;
    prop: string;
    comment: string;
}> => {
    let result = [
        {
            header: TableHeaders[0],
            prop: contract.name.toString(),
            comment: contract.nameCom.toString()
        },
        { header: TableHeadersWithoutNewline[1], prop: contract.vertragsart.toString(), comment: contract.vertragsartCom.toString() },
        { header: TableHeadersWithoutNewline[2], prop: contract.garantierteRente.toString(), comment: contract.garantierteRenteCom.toString() },
        { header: TableHeadersWithoutNewline[3], prop: contract.garantierterRentenfaktor.toString(), comment: contract.garantierterRentenfaktorCom.toString() },
        { header: TableHeadersWithoutNewline[4], prop: contract.besserungsoption.toString(), comment: contract.besserungsoptionCom.toString() },
        { header: TableHeadersWithoutNewline[5], prop: contract.beitragspause.toString(), comment: contract.beitragspauseCom.toString() },
        { header: TableHeadersWithoutNewline[6], prop: contract.verschiebungRentenbeginn.toString(), comment: contract.verschiebungRentenbeginnCom.toString() },
        { header: TableHeadersWithoutNewline[7], prop: contract.maxRgz.toString(), comment: contract.maxRgzCom.toString() }
    ];

    if (showEffektivkosten) {
        result.push(
            showEffektivkosten && {
                header: TableHeadersWithoutNewline[8],
                prop: contract.effektivkosten.toString(),
                comment: contract.effektivkosten.toString()
            }
        );
    }

    return result.concat([
        { header: TableHeadersWithoutNewline[9], prop: contract.vkRente.toString(), comment: contract.vkRenteCom.toString() },
        { header: TableHeadersWithoutNewline[10], prop: contract.mindestbeitrag.toString(), comment: contract.mindestbeitragCom.toString() },
        { header: TableHeadersWithoutNewline[11], prop: contract.mindestlaufzeit.toString(), comment: contract.mindestlaufzeitCom.toString() },
        { header: TableHeadersWithoutNewline[12], prop: contract.bu.toString(), comment: contract.buCom.toString() },
        { header: TableHeadersWithoutNewline[13], prop: contract.schieberegler.toString(), comment: contract.schiebereglerCom.toString() },
        { header: TableHeadersWithoutNewline[14], prop: contract.solvency2.toString(), comment: contract.solvency2Com.toString() },
        { header: TableHeadersWithoutNewline[15], prop: getValidityString(contract), comment: '' }
    ]);
};
