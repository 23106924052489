import * as React from 'react';
import DrawerAvatar from 'app/components/drawer/DrawerAvatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import DrawerMenuItem from 'app/components/drawer/DrawerMenuItem';
import HomeIcon from '@material-ui/icons/Home';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import BackupIcon from '@material-ui/icons/Backup';
import PolicyIcon from '@material-ui/icons/Policy';
import { logout } from 'features/app_user/auth/authSlice';
import { RootState, useAppDispatch } from 'app/store';
import ChangeProfileIconMenuItem from 'app/components/drawer/ChangeProfileIconDialog';
import Routes from 'app/routes/Routes';
import useWindowDimensions from 'utils/useWindowDimensions';
import { appVersion } from 'config';
import { Button, Grid, Typography } from '@material-ui/core';
import { asGermanDateDMY } from 'utils/DateFormatter';
import { useSelector } from 'react-redux';
import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import { isBasisversion } from 'utils/appUserUtils';

const MainDrawerContent: React.FC<WithAppVersionProps> = (props: WithAppVersionProps) => {

    const validUntil = useSelector((state: RootState) => state.appUser.appUser?.validUntil);
    const appUser = useSelector((state: RootState) => state.appUser.appUser!);
    const hasTestLicense = useSelector((state: RootState) => state.appUser.appUser?.hasTestLicense);
    const dispatch = useAppDispatch();
    const { height, width } = useWindowDimensions();
    return (
        <>
            <Grid container direction={'column'} alignItems={'stretch'}>
                <DrawerAvatar/>
                <Divider/>
                <List>
                    <DrawerMenuItem title={'Startseite'} icon={HomeIcon} link={'/app/home'}/>
                </List>
                <Divider/>
                <List>
                    <ChangeProfileIconMenuItem height={height} width={width}/>
                    <DrawerMenuItem icon={InfoIcon} title={'Informationen'} link={Routes.Info}/>
                    <DrawerMenuItem icon={BackupIcon} title={'Datensicherung'} link={Routes.Backup}/>
                    <DrawerMenuItem icon={PolicyIcon} title={'Datenschutz & Impressum'} link={Routes.Imprint}/>
                    <DrawerMenuItem
                        icon={LogoutIcon}
                        title={'Abmelden'}
                        link={'/login'}
                        action={async () => {
                            dispatch(logout(appUser));
                            // console.log('Logged out');
                        }}
                    />
                </List>
                <Divider/>

                {
                    (!isBasisversion(appUser)) ? <Typography style={{ textAlign: 'left', paddingTop: 16, paddingLeft: 16, fontSize: 12 }}>Lizenz gültig
                    bis: {asGermanDateDMY(new Date(validUntil || 0))}</Typography> : <Typography style={{ textAlign: 'left', paddingTop: 16, paddingLeft: 16, fontSize: 12 }}>Lizenz abgelaufen am {asGermanDateDMY(new Date(validUntil || 0))}, Basisversion</Typography>
                }
                
                {
                    (hasTestLicense) ? <Typography style={{ textAlign: 'left', paddingTop: 16, paddingLeft: 16, fontSize: 12 }}>Testversion</Typography> : '' //  && !isBasisversion
                }

                {props.isUptoDate && <Typography style={{
                    textAlign: 'left',
                    paddingTop: 16,
                    paddingLeft: 16,
                    fontSize: 12,
                    color: 'green'
                }}>AppVersion: {appVersion} (aktuell)</Typography>}
                {!props.isUptoDate && <>
                    <Typography style={{
                        textAlign: 'left',
                        paddingTop: 16,
                        paddingLeft: 16,
                        fontSize: 12,
                        color:'red'
                    }}>AppVersion: {appVersion} (veraltet) </Typography>
                    <Button variant={'contained'} color={'primary'} style={{margin: 12}} onClick={() => {
                        window.open(window.location.pathname, "_self");
                    }
                    }>Jetzt aktualisieren!</Button>

                </>}
            </Grid>
        </>
    );
};

export default withAppVersion(MainDrawerContent);
