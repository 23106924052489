import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'app/store';
import { AuthActions } from 'features/app_user/auth/authSlice';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import { WebShopUrl } from 'config';

const LoginFailedDialog: React.FC = () => {
    const loginError = useSelector<RootState>((state) => state.auth.error);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleClose = () => {
        dispatch(AuthActions.setError(undefined));
    };

    const handleResetPassword = () => {
        dispatch(AuthActions.setError(undefined));
        history.push(Routes.Auth.ResetPassword);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={loginError !== undefined}>
            <DialogTitle>Anmeldung fehlgeschlagen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {loginError && JSON.stringify(loginError).replace(/"/g, '').replace('{}', '')}
                    {loginError && JSON.stringify(loginError).toLowerCase().includes('webshop') && <a href={WebShopUrl}>Zum Shop</a>}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={() => handleResetPassword()}>Passwort zurücksetzen</Button>
                    <Button variant={'contained'} color={'primary'} onClick={() => handleClose()}>
                        Erneut versuchen
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default LoginFailedDialog;
