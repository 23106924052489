import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { filter } from 'lodash';

import { RootState } from 'app/store';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';
import { filters } from 'features/riesterProfiCheck/consultation/questions/domain/QuestionFilter';
import { asGermanDateDMYHHMM } from 'utils/DateFormatter';
import { Contracts, getContracts } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';
import {
    getNewContracts,
    orderContracts
} from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractUtils';

import moment from 'moment';

const initialState: Consultation = {
    id: uuidv4(),
    title: asGermanDateDMYHHMM(new Date()),
    date: null,
    customerId: '',
    questionValues: [],
    selectedContractIds: [],
    additionalContractIds: [],
    showEffektivkosten: false,
    showValidity: true,
    compareProvidersEnabled: false
};

export const consultationSessionSlice = createSlice({
    name: 'consultationSession',
    initialState: initialState,
    reducers: {
        resumeConsultation: (state: Consultation, action: PayloadAction<Consultation>) => {
            state.compareProvidersEnabled = false;
            return action.payload;
        },
        startConsultation: (state: Consultation, action: PayloadAction<string | undefined>) => {
            if (action.payload === undefined) {
                throw Error('Cannot start Consultation without Customer!');
            }
            state = {
                ...initialState,
                date: new Date().getTime(),
                customerId: action.payload,
                compareProvidersEnabled: false,
                showEffektivkosten: false,
                showValidity: false
            };

            return state;
        },

        setTitle: (state: Consultation, action: PayloadAction<string>) => {
            state.title = action.payload;
        },

        setQuestionEnabled: (state: Consultation, action: PayloadAction<number>) => {
            state.questionValues[action.payload] = true;
            state.selectedContractIds = [];
        },
        setQuestionDisabled: (state: Consultation, action: PayloadAction<number>) => {
            state.questionValues[action.payload] = false;
            state.selectedContractIds = [];
        },
        resetQuestionFilteredContracts: (state: Consultation) => {
            state.selectedContractIds = [];
        },

        setAdditionalContracts: (state: Consultation, action: PayloadAction<Array<InsuranceContractModel>>) => {
            state.additionalContractIds = action.payload.map((c) => c.id);
        },

        setSelectedContracts: (state: Consultation, action: PayloadAction<Array<InsuranceContractModel>>) => {
            state.selectedContractIds = action.payload.map((c) => c.id);
        },
        toggleSelectContract: (state: Consultation, action: PayloadAction<InsuranceContractModel>) => {
            const index = state.selectedContractIds.findIndex((id) => id === action.payload.id);
            if (index === -1) {
                state.selectedContractIds.push(action.payload.id);
            } else {
                state.selectedContractIds.splice(index, 1);
            }
        },

        addAdditionalConractId: (state: Consultation, action: PayloadAction<InsuranceContractModel>) => {
            state.additionalContractIds.push(action.payload.id);
        },

        toggleShowValidity: (state: Consultation) => {
            state.showValidity = !state.showValidity;
        },
        toggleShowEffektivkosten: (state: Consultation) => {
            state.showEffektivkosten = !state.showEffektivkosten;
        },
        toggleCompareProvidersEnabled: (state: Consultation) => {
            state.compareProvidersEnabled = !state.compareProvidersEnabled;
        },

        overwriteConsultations: (state: Consultation, action: PayloadAction<Consultation>) => {
            state.additionalContractIds = action.payload.additionalContractIds
            state.compareProvidersEnabled = action.payload.compareProvidersEnabled
            state.customerId = action.payload.customerId
            state.date = action.payload.date
            state.id = action.payload.id
            state.questionValues = action.payload.questionValues
            state.selectedContractIds = action.payload.selectedContractIds
            state.showEffektivkosten = action.payload.showEffektivkosten
            state.showValidity = action.payload.showValidity
            state.title = action.payload.title
        },
    }
});

const filterContracts = (contracts: Contracts, questionValues: Array<boolean>): Array<InsuranceContractModel> => {
    let filteredContracts: Array<InsuranceContractModel> = getNewContracts(contracts);
    questionValues.forEach((isSelected: boolean, index: number) => {
        if (index !== 2 && isSelected) {
            filteredContracts = filters[index](filteredContracts);
        }
    });

    return orderContracts(filteredContracts);
};

//export const AllContractsAsArray = (contracts: Contracts) => contracts.entities;

const selectedContractIds = (state: RootState) => state.currentConsultation.selectedContractIds;
const questionValues = (state: RootState) => state.currentConsultation.questionValues;

export const questionFilteredContracts = createSelector(getContracts, questionValues, filterContracts);




export const getSelectedContracts = createSelector(getContracts, selectedContractIds, (contracts, ids) =>
    ids.length === 0 ? [] : ids.map((id) => contracts.entities[id])
);

export const getAdditionalContracts = createSelector(
    getContracts,
    (state: RootState) => state.currentConsultation.additionalContractIds,
    (contracts, ids) => ids.map((id) => contracts.entities[id])
);

export const ConsultationActions = consultationSessionSlice.actions;
export default consultationSessionSlice.reducer;
