import * as React from 'react';
import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Icon, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowDown';
import IconLoad from '@material-ui/icons/RestorePage';
import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { makeStyles } from '@material-ui/core/styles';
import Routes from 'app/routes/Routes';
import { useAppDispatch } from 'app/store';
import { useHistory } from 'react-router-dom';
import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';
// import { SavedConsultationActions } from 'features/riesterProfiCheck/consultation/saved_consultations/savedConsultationsSlice';
import DeleteConsultationButton from 'features/riesterProfiCheck/customers/view/overview/components/detail/DeleteConsultationButton';
// import { useState } from 'react';
import ResultPDFButton from 'features/riesterProfiCheck/consultation/result/pdf/ResultPDFButton';
import { asGermanDateDMY } from 'utils/DateFormatter';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightMedium
    },
    date: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.grey[500]
    }
}));

interface Props {
    savedConsultation: Consultation;
}

const SavedConsultationItem: React.FC<Props> = ({ savedConsultation }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const onResumeConsultationClicked = (consultation: Consultation) => {
        dispatch(ConsultationActions.resumeConsultation(consultation));
        history.push(Routes.Consultation.Questions);
    };

    const onOpenPdfClick = async () => {
        dispatch(ConsultationActions.resumeConsultation(savedConsultation));
    }

    return (
        <ExpansionPanel style={{ width: '100%' }} variant={'outlined'} elevation={0} square>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Grid direction={'row'} justify={'space-between'}>
                    <Typography className={classes.heading}>{savedConsultation.title}</Typography>
                    <Typography className={classes.date}>{asGermanDateDMY(new Date(savedConsultation.date || new Date().getTime()))}</Typography>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container direction={'row'} spacing={2}>
                    <Grid item md={4}>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            fullWidth
                            startIcon={<IconLoad />}
                            onClick={() => onResumeConsultationClicked(savedConsultation)}
                        >
                            Beratung öffnen
                        </Button>
                    </Grid>
                    <Grid item md={4}>
                        <ResultPDFButton beforeCreatePdf={onOpenPdfClick} ButtonElement={
                            <Button variant={'outlined'} color={'primary'} fullWidth startIcon={<Icon>picture_as_pdf</Icon>}>
                            PDF Öffnen
                        </Button>}/>
                    </Grid>
                    <Grid item md={4}>
                        <DeleteConsultationButton savedConsultation={savedConsultation} />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default SavedConsultationItem;
