import { AppUserModel } from 'features/app_user/data/AppUserModel';

export const isLicenseValid: (appUser: AppUserModel) => boolean = (appUser: AppUserModel) => {
    // console.log(appUser);
    return (appUser.hasWebLicense && appUser.validUntil > new Date().getTime()) || (appUser.hasTestLicense == true);
}

export const isBasisversion: (appUser: AppUserModel) => boolean = (appUser: AppUserModel) => {
    // console.log('isBasisversion', appUser)
    return appUser.hasWebLicense && appUser.validUntil < new Date().getTime() && appUser.hasTestLicense == true;
}
