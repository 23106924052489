import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import PdfContractDetailView from 'features/riesterProfiCheck/insurance_contracts/detail_view/pdf/PdfContractDetailView';
import CreateAndOpenPDFButton from 'core/pdf/PDFButton';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';

interface Props {
    contract: InsuranceContractModel;
    showEffektivkosten: boolean;
}

const ContractDetailViewPDFButton: React.FC<Props> = ({ contract, showEffektivkosten }) => {

    return (
        <CreateAndOpenPDFButton pdfDocument={<PdfContractDetailView contract={contract} showEffektivkosten={showEffektivkosten} />}/>
    );
};

export default ContractDetailViewPDFButton;
