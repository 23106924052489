import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import Firebase from 'core/firebase/Firebase';
import { AppUserModel, AppUserData } from 'features/app_user/data/AppUserModel';
import { AppUserActions } from 'features/app_user/appUserSlice';
import { WebShopUrl } from 'config';
import { logger } from 'codelyzer/util/logger';
import { asGermanDateDMY } from 'utils/DateFormatter';
import { isBasisversion, isLicenseValid } from 'utils/appUserUtils';
import { SnackbarActions } from 'features/snackbar/snackbarSlice';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';

var createHash = require('create-hash')
// var _ = require('lodash/core');

export interface AuthState {
    loading: boolean;
    error: any;
}

const initialState: AuthState = {
    loading: false,
    error: undefined
};

function BrowserCheck() {
    var N = navigator.appName, ua = navigator.userAgent, tem;
    var M = ua.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) { M[2] = tem[1]; }
    M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
    return M;
}

const browserName = () => {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        return 'opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        return 'chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
        return 'safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        return 'firefox';
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
        return 'ie';//crap
    } else {
        return 'web';
    }
}

const jsonCheckSum = (json: AppUserData) => {

    var jsonTmp: AppUserData = { ...json };
    jsonTmp.backupDate = 0;

    const data = JSON.stringify(jsonTmp, null, 2)
    var hash = createHash('md5')
    return hash.update(data).digest("hex");

    // return hash.read()
}

const saveData = (user: AppUserModel, databaseUserRef: any, databaseBackupsRef: any) => {
    // Save Storage
    const obj = localStorage;
    // console.log(obj, JSON.parse(obj.getItem('persist:root') ?? ''))

    if (obj.getItem('persist:root')) {
        const dataApp = JSON.parse(obj.getItem('persist:root') ?? '');
        const browserAgent = BrowserCheck().join('_').replace( /\./g, "_" ); // browserName()

        console.log(browserAgent)

        if (dataApp.savedConsultations || dataApp.customerStore) {

            let datenow = Date.now()

            const dataCons: AppUserData = {
                'backupDate': datenow,
                'currentConsultation': dataApp.currentConsultation,
                'customerStore': dataApp.customerStore,
                'savedConsultations': dataApp.savedConsultations
            }


            const dataConsLite: AppUserData = {
                'backupDate': datenow,
                'currentConsultation': '',
                'customerStore': '',
                'savedConsultations': '',
                'checkSum': jsonCheckSum(dataCons)
            }

            var backupBrowser: AppUserData = {};
            for (var browser in user.appData) {
                if (browser == browserAgent) {
                    backupBrowser = user.appData[browser]
                }
            }

            // console.log(jsonCheckSum(dataCons), backupBrowser.checkSum, dataCons, backupBrowser.checkSum) // _.isEqual(dataCons, backupBrowser), 
            if (!user.appData || jsonCheckSum(dataCons) != backupBrowser.checkSum) {
                // console.log('###UPDATE')
                // SnackbarActions.show('Datenbank erfolgreich gespeichert!')
                // dispatch(
                //     AuthActions.setError(
                //         'Ihren Daten werden gesichert, dies kann je nach Größe einen Moment dauern.'
                //     )
                // );
                databaseUserRef.update({
                    ['appData/' + browserAgent]: dataConsLite
                });

                databaseBackupsRef.update({
                    ['appData/' + browserAgent]: dataCons
                });

                return true;
                // dispatch(SnackbarActions.show('Datenbank erfolgreich gespeichert!'));
                // await new Promise((r) => setTimeout(r, 3000));
                // dispatch(SnackbarActions.hide());
            }
        }

    }
    return false;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state: AuthState, action: PayloadAction<string | undefined>) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export const logout = (appUser?: AppUserModel): AppThunk => async (dispatch) => {
    try {
        // Backup
        if (appUser && typeof appUser.id != 'undefined') {
            // const appUser = useSelector((state: RootState) => state.appUser.appUser!);
            // console.log('######', appUser, appUser.id)

            // if (appUser && appUser.uid) {
            const databaseUserRef = Firebase.database().ref('users').child(appUser.id);
            const databaseBackupsRef = Firebase.database().ref('backups').child(appUser.id);
            databaseUserRef.update({
                'lastLogout': Date.now()
            });

            saveData(appUser, databaseUserRef, databaseBackupsRef);
            // }
        }


        await Firebase.auth().signOut();
        // console.log('manual logout');
        dispatch(AppUserActions.onLogout());
    } catch (err) {
        console.error(err);
    }
};

export const attemptLogin = (props: { email: string; password: string }): AppThunk => async (dispatch) => {

    const email = props.email.toLowerCase().trim();
    const password = props.password

    dispatch(AuthActions.setLoading(true));
    try {
        const authResponse = await Firebase.auth().signInWithEmailAndPassword(email, password);

        if (authResponse.user) {
            const databaseUserRef = Firebase.database().ref('users').child(authResponse.user?.uid);
            const databaseBackupRef = Firebase.database().ref('backups').child(authResponse.user?.uid);

            const databaseUser = await databaseUserRef.once('value');

            if (!databaseUser.exists) {
                throw Error('User not found');
            }

            const user = databaseUser.val() as AppUserModel;

            // set LastLogin
            databaseUserRef.update({
                'lastLogin': Date.now()
            });

            if (!user.hasWebLicense) {
                dispatch(
                    AuthActions.setError(
                        'Ihre Lizenz ist nur für die iOS Version gültig. Bitte besuchen Sie unseren Shop unter https://shop.dfiav.de, falls Sie die Web Version zusätzlich erwerben möchten.'
                    )
                );
                return;
            }

            if (!isLicenseValid(user)) {
                dispatch(AuthActions.setError(`Ihre Lizenz ist am ${asGermanDateDMY(new Date(user.validUntil))} abgelaufen. Bitte erneuern Sie Ihre Lizenz in unserem Webshop: `));
                return;
            }

            if (saveData(user, databaseUserRef, databaseBackupRef)) {
                dispatch(SnackbarActions.show('Datenbank erfolgreich gesichert!'));
            }

            // if (!isBasisversion(user)) {
            //     dispatch(AuthActions.setError(`Ihre Testlizenz ist am ${asGermanDateDMY(new Date(user.validUntil))} abgelaufen. Bitte erneuern Sie Ihre Lizenz in unserem Webshop: `));
            //     return;
            // }

            dispatch(AuthActions.setError(undefined));
            dispatch(AppUserActions.onLoginSuccess(user));
        }
    } catch (err) {
        // console.error(err);
        // @ts-ignore: no Type
        dispatch(dispatch(AuthActions.setError(err)));
    }
    dispatch(AuthActions.setLoading(false));
};

export const AuthActions = authSlice.actions;
export default authSlice.reducer;
