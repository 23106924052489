import { Page, Text, View, Image } from '@react-pdf/renderer';
import { asGermanDateDMY } from 'utils/DateFormatter';
import React from 'react';
import AppUser from 'features/app_user/domain/AppUser';
import Customer from 'features/riesterProfiCheck/customers/domain/Customer';
import { Center, Column, Grow, Row, Section, PDFStyles, Whitespace } from 'core/pdf/CustomPdfComponents';
import { PrimaryColor } from 'app/style/AppColors';

interface Props {
    appUser: AppUser;
    customer: Customer;
}

const AddressAndDateSection = (props: { appUser: AppUser }) => (
    <Row style={{ ...PDFStyles.row, padding: 10, paddingRight: 40 }}>
        <Grow>
            <Row>
                <Image
                    style={{ marginRight: 12, borderRadius: 100, borderStyle: 'solid', borderColor: PrimaryColor, borderWidth: 2 }}
                    src={props.appUser.getImgSrc()}
                />
                <Column>
                    <Text style={{ marginBottom: '8px' }}>{props.appUser.getTitle()}</Text>
                    <Text>{props.appUser.getFullName()}</Text>
                    <Text>{props.appUser.getStreetAndNumber()}</Text>
                    <Text>{props.appUser.getPostalCodeAndCity()}</Text>
                </Column>
            </Row>
        </Grow>
        <View>
            <Text>{asGermanDateDMY(new Date())}</Text>
        </View>
    </Row>
);

const TitleSection = (props: { customer: Customer }) => (
    <Center>
        <Text style={{ fontSize: 32, color: PrimaryColor, fontWeight: 'bold' }}>Vorsorge-Profi-Check</Text>
        <Text style={{ fontSize: 14, marginTop: 12 }}>für</Text>
        <Text style={{ fontSize: 18, marginTop: 2 }}>{props.customer.fullName}</Text>
    </Center>
);

const PdfDeckblatt = (props: Props) => (
    <Page size="A4" style={PDFStyles.page}>
        <Section>
            <AddressAndDateSection appUser={props.appUser} />
            <Whitespace height={250} />
            <TitleSection customer={props.customer} />
        </Section>
    </Page>
);

export default PdfDeckblatt;
