import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { Document } from '@react-pdf/renderer';
import PdfTable from 'core/pdf/PdfTable';
import CreateAndOpenPDFButton from 'core/pdf/PDFButton';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';

interface Props {
    contracts: Array<InsuranceContractModel>
}

const ContractsOverviewPDFButton: React.FC<Props> = ({contracts}) => {


    const showEffektivkosten = useSelector((state: RootState) => state.currentConsultation.showEffektivkosten);

    const DownloadDocument = (
        <Document>
            <PdfTable selectedContracts={contracts} showEffektivkosten={showEffektivkosten} />
        </Document>
    );

    return <CreateAndOpenPDFButton pdfDocument={DownloadDocument} />
};

export default ContractsOverviewPDFButton;
