import { Button, Icon, Grid, DialogTitle } from '@material-ui/core';
import SimpleReactCropper from 'lib/cropper/ProfileImageCropper';
import * as React from 'react';
import DrawerMenuItem from 'app/components/drawer/DrawerMenuItem';
import IconChangeProfilePicture from '@material-ui/icons/AccountBox';
import { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import useWindowDimensions from 'utils/useWindowDimensions';

interface Props {
    height: number;
    width: number;
}

const ChangeProfileIconDialog: React.FC<Props> = ({height, width}) => {

    const [open, setOpen] = useState(false);
    const history = useHistory();

    const upload = (blob: Blob) => {
        const blobToBase64 = function (blob: Blob, callback: any) {
            const reader = new FileReader();
            reader.onload = function () {
                const dataUrl = reader.result;
                //const base64 = dataUrl.split(',')[1];
                callback(dataUrl);
                // console.log(dataUrl);
            };
            reader.readAsDataURL(blob);
        };

        blobToBase64(blob, (base64Data: any) => {
            localStorage.setItem('profilePicture', base64Data);
            setOpen(false);
            history.push(history.location);
        });
    };

    const afterCrop = () => {
        console.log('close cropper');
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <>
            <DrawerMenuItem icon={IconChangeProfilePicture} title={'Profilbild ändern'} link={'/app/home'} action={() => setOpen(true)} />
            { open && (<Dialog open={open} onClose={handleClose}>
                <DialogTitle>Profilbild ändern</DialogTitle>
                <DialogContent>
                    <SimpleReactCropper
                        handleClose={handleClose}
                        aspectRatio={4 / 4}
                        width={500}
                        height={500}
                        upload={upload}
                        afterCrop={afterCrop}
                        containerStyle={{ maxHeight: height / 2, maxWidth: width / 2, borderRadius: '10px' }}
                    />
                </DialogContent>
            </Dialog>)}
        </>
    );
};

export default ChangeProfileIconDialog;
