import * as React from 'react';
import { useEffect, useState } from 'react';

interface Props {
    callback: () => void;
    ms?: number;
}

const LongPressListener: React.FC<Props> = ({ children, callback, ms = 400 }) => {
    const [startLongPress, setStartLongPress] = useState(false);

    useEffect(() => {
        let timerId: any;
        if (startLongPress) {
            timerId = setTimeout(callback, ms);
        } else {
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [callback, ms, startLongPress]);

    return (
        <div
            onMouseDown={() => setStartLongPress(true)}
            onMouseUp={() => setStartLongPress(false)}
            onMouseLeave={() => setStartLongPress(false)}
            onTouchStart={() => setStartLongPress(true)}
            onTouchEnd={() => setStartLongPress(false)}
        >
            {children}
        </div>
    );
};

export default LongPressListener;
