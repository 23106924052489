import React from 'react';
import { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import SaveConsultationDialog from 'features/riesterProfiCheck/consultation/saved_consultations/components/SaveConsultationDialog';
import IconSave from '@material-ui/icons/Save';
import { useHistory } from 'react-router-dom';

interface Props {
    redirectRoute?: string;
}

const SaveConsultationComponent: React.FC<Props> = (props) => {
    const history = useHistory();

    const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);

    const onSaveIconPressed = () => {
        setOpenSaveDialog(true);
    };

    const onDialogClose = () => {
        setOpenSaveDialog(false);
    };

    // console.log(openSaveDialog);
    return (
        <>
            <SaveConsultationDialog open={openSaveDialog} onDialogClose={onDialogClose} />
            <Tooltip title="Beratung speichern">

                <IconButton onClick={() => onSaveIconPressed()}>

                    <IconSave />
                </IconButton>
            </Tooltip>
        </>);
};

export default SaveConsultationComponent;
