import { green, red } from '@material-ui/core/colors';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';
import * as React from 'react';

export const defaultCellStyle = {
    padding: '4px',
    textAlign: 'center',
    border: '1px solid white',
    minWidth: '50pt',
    fontSize: 11
} as React.CSSProperties;

export const defaultHeaderStyle = {
    padding: '6px',
    textAlign: 'center'
} as React.CSSProperties;

export const redColor = red[300];
export const greenColor = green[300];
//export const renderGarantierteRente = (contract: InsuranceContract) => <div style={{ backgroundColor:  contract.garantierteRente.toString() === 'Ja' ? green[500] : red[500]}}>{contract.garantierteRente}</div>

export const getGarantierteRenteCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: hasNeutralColor(contract.garantierteRente.toString()) ? 'transparent' : contract.garantierteRente.toString() === 'Ja' ? greenColor : redColor
    } as React.CSSProperties;
};
export const getGarantierterRentenfaktorCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: hasNeutralColor(contract.garantierterRentenfaktor.toString()) ? 'transparent' : getColorYesNo(contract.garantierterRentenfaktor.toString())
    } as React.CSSProperties;
};
export const getBesserungsoptionCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: hasNeutralColor(contract.besserungsoption.toString()) ? 'transparent' :  getColorYesNo(contract.besserungsoption.toString())
    } as React.CSSProperties;
};
export const getBeitragspauseCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: hasNeutralColor(contract.beitragspause.toString()) ? 'transparent' : getColorYesNo(contract.beitragspause.toString())
    } as React.CSSProperties;
};

export const getVerschiebungRentenbeginnCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: calcVerschiebungRentenbeginnColor(contract)
    } as React.CSSProperties;
};

export const getEffektivkostenCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return defaultCellStyle as React.CSSProperties;
};

export const getMaxRGZCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: calcMaxRGZColor(contract)
    } as React.CSSProperties;
};

export const getVKRenteCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: calcVKRenteColor(contract)
    } as React.CSSProperties;
};

export const getMindestBeitragCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: calcMindestBeitragCellStyle(contract)
    } as React.CSSProperties;
};

export const getMindestLaufzeitCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: calcMindestLaufzeitCellStyle(contract)
    } as React.CSSProperties;
};

export const getBUCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: hasNeutralColor(contract.bu.toString()) ? 'transparent' : getColorYesNo(contract.bu.toString())
    } as React.CSSProperties;
};

export const getSchiebeReglerCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: hasNeutralColor(contract.schieberegler.toString()) ? 'transparent' : getColorYesNo(contract.schieberegler.toString())
    } as React.CSSProperties;
};

export const getSolvencyCellStyle = (contracts: InsuranceContractModel[], contract: InsuranceContractModel): React.CSSProperties => {
    return {
        ...defaultCellStyle,
        backgroundColor: getColorYesNo(contract.solvency2.toString())
    } as React.CSSProperties;
};

const calcMindestLaufzeitCellStyle = (contract: InsuranceContractModel) => {
    const value = contract.mindestlaufzeit;

    if (typeof value === 'number') {
        if (value < 10) {
            return greenColor;
        } else if (value >= 22) { // > 20
            return redColor;
        }
    } else if (hasNeutralColor(value)) {
        return 'transparent';
    } else {
        if (value === 'keine') {
            return greenColor;
        }
    }

    return 'transparent';
};

const calcMindestBeitragCellStyle = (contract: InsuranceContractModel) => {
    const value = contract.mindestbeitrag;

    if (typeof value === 'number') {
        if (!isNaN(value)) {
            if (value < 20) {
                return greenColor;
            } else if (value >= 20) {
                return redColor;
            }
        }
    }

    return 'transparent';
};

const calcVKRenteColor = (contract: InsuranceContractModel) => {
    const value = contract.vkRente;

    if (hasNeutralColor(value.toString())) {
        return 'transparent';
    }

    if (value.toString().includes('> 2')) {
        return redColor;
    }

    const parsedValue = parseFloat(value.toString().replace(',', '.'));

    if (parsedValue <= 1.25) {
        return greenColor;
    } else if (parsedValue <= 2) {
        return 'transparent';
    } else if (parsedValue > 2) {
        return redColor;
    } else {
        return 'transparent';
    }
};

const calcMaxRGZColor = (contract: InsuranceContractModel) => {
    const value = contract.maxRgz;

    if (typeof value === 'string') {

        if (hasNeutralColor(value)) {
            return 'transparent';
        } else if (value === 'RKW 85' || value === 'RKW bis 85') {
            return redColor;
        } else if (value === 'RKA') {
            return greenColor;
        } else {
            const extractedNumber = parseInt(value.replace(/\D/g, ''));
            return extractedNumber >= 30 ? greenColor : redColor;
        }
    } else {
        return value >= 30 ? greenColor : redColor;
    }
};

const calcVerschiebungRentenbeginnColor = (contract: InsuranceContractModel) => {
    const value = contract.verschiebungRentenbeginn;
    if (typeof value === 'number') {
        return value >= 80 ? greenColor : redColor;
    } else if (hasNeutralColor(value)) {
        return 'transparent';
    } else {
        return getColorYesNo(value);

    }
};

const hasNeutralColor = (value: string) => {
    return value.includes('?') || value === 'keine Angabe' || value === '';
};

const getColorYesNo = (value: string) => {
    if (value === 'Ja') {
        return greenColor;
    } else if (value === 'Nein') {
        return redColor;
    } else {
        return 'transparent';
    }
};
