import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Theme } from '@material-ui/core';

const OpaqueButton = withStyles((theme: Theme) => ({
    root: {
        '&$disabled': {
            backgroundColor: theme.palette.grey[300]
        },
        zIndex: 90
    },
    disabled: {}
}))(Button);

export default OpaqueButton;
