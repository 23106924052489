import * as React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
// import InfoIcon from '@material-ui/icons/Info';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
// import { InfoOutlined } from '@material-ui/icons';
import HomePageButton from './HomePageButton';
import DrawerScaffold from '../../app/components/scaffold/DrawerScaffold';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import {
    BeenhereOutlined,
    CalendarViewDayOutlined,
    ExploreOutlined,
    InfoOutlined,
    PermDeviceInformation,
    ShoppingCartOutlined,
    SystemUpdate,
    VideoLibraryOutlined
} from '@material-ui/icons';


import { WebShopUrl } from 'config';
import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import { RootState, useAppDispatch } from 'app/store';
// import DisabledHomePageButton from 'features/home/DisabledHomePageButton';

import LoginBasisversionDialog from 'features/app_user/auth/login/LoginBasisversionDialog';
import { isBasisversion } from 'utils/appUserUtils';

import { useSelector } from 'react-redux';

const HomePage: React.FC<WithAppVersionProps> = (props) => {
    const history = useHistory();
    // const dispatch = useAppDispatch();

    const appUser = useSelector((state: RootState) => state.appUser.appUser!);

    return (
        <DrawerScaffold
            title={'Vorsorge-Profi-Check'}
            actions={
                <>
                </>
            }
            drawerContent={<MainDrawerContent />}
        >
            {
                (isBasisversion(appUser)) ? <LoginBasisversionDialog /> : ''
            }
            <Grid container spacing={3}>
                {!props.isUptoDate &&
                    <Grid item xs={12} md={4}>
                        <HomePageButton
                            red={true} icon={SystemUpdate} text={'Update benötigt!'}
                            onItemClicked={() => {
                                window.open(window.location.pathname, '_self');
                            }} />
                    </Grid>
                }
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={BeenhereOutlined} text={'Profi-Checks'}
                        onItemClicked={() => history.push(Routes.ProfiChecksPage)} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={ExploreOutlined} text={'Beratungs-Tools'} onItemClicked={() => history.push(Routes.Tools)} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={CalendarViewDayOutlined} text={'Solvency II'}
                        onItemClicked={() => history.push(Routes.SolvencyII.Overview)} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton disabled icon={VideoLibraryOutlined} text={'Videos'} subtitle={'Wird aktuell überarbeitet'}
                        onItemClicked={() => {/* history.push(Routes.VideoPage) */ }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={ShoppingCartOutlined} text={'Shop'} onItemClicked={() => {
                        var win = window.open(WebShopUrl, '_blank');
                        win?.focus();
                    }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton disabled icon={PermDeviceInformation} text={'Vorsorge-Wiki'} subtitle={'In Planung'}
                        onItemClicked={() => { }} />
                </Grid>

                <Grid item style={{ marginLeft: '-70px', marginTop: '5px' }}>
                    <PopupState variant={'popover'} popupId={'popup'}>
                        {(popupState) => (
                            <div>
                                <IconButton{...bindTrigger(popupState)}>
                                    <SvgIcon component={InfoOutlined} style={{ fontSize: 18, }}
                                        color={'primary'} />
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div style={{ padding: 8, maxWidth: 250 }}>
                                        <p>Hier entsteht ein umfassendes Nachschlagewerk zum Thema Altersvorsorge</p>
                                    </div>
                                </Popover>
                            </div>
                        )}
                    </PopupState>
                </Grid>
            </Grid>
        </DrawerScaffold>
    );
};

export default withAppVersion(HomePage);
