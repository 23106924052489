import React from 'react';
import { useSelector } from 'react-redux';
// import { RootState } from 'app/store';

import { getContractsToCompare } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import { PdfCompareContracts } from 'features/riesterProfiCheck/insurance_contracts/compare/pdf/PdfCompareContracts';
import CreateAndOpenPDFButton from 'core/pdf/PDFButton';

const CompareContractsPDFButton: React.FC = () => {
    const contractsToCompare = useSelector(getContractsToCompare);
    // const showEffektivkosten = useSelector((state: RootState) => state.currentConsultation.showEffektivkosten);



    return (<CreateAndOpenPDFButton pdfDocument={<PdfCompareContracts contracts={contractsToCompare} />} />
    );
};

export default CompareContractsPDFButton;
