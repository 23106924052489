import * as React from 'react';
import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import DrawerScaffold from '../../app/components/scaffold/DrawerScaffold';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import {
    CalendarViewDayOutlined,
    SystemUpdate,
    PeopleAltOutlined,
    ListAltOutlined,
    VideoLibraryOutlined,
    ShoppingCartOutlined, ExploreOutlined, PermDeviceInformation
} from '@material-ui/icons';
import { WebShopUrl } from 'config';
import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import { useAppDispatch } from 'app/store';
import { ContractsActions } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import HomePageButton from 'features/home/HomePageButton';
import HomePageButtonRed from 'features/home/HomePageButtonRed';
// import CustomTooltip from 'features/home/DisabledHomePageButton';
// import Typography from '@material-ui/core/Typography';
import DisabledHomePageButton from 'features/home/DisabledHomePageButton';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import { InfoOutlined } from '@material-ui/icons';

const HomePage: React.FC<WithAppVersionProps> = (props) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    return (
        <DrawerScaffold
            title={'Riester-Profi-Check'}
            actions={
                <>
                </>
            }
            drawerContent={<MainDrawerContent />}
        >
            <Grid container spacing={3}>
                {!props.isUptoDate &&
                    <Grid item xs={12} md={4}>
                        <HomePageButton
                            red={true} icon={SystemUpdate} text={'Update benötigt!'}
                            onItemClicked={() => {
                                window.open(window.location.pathname, '_self');
                            }} />
                    </Grid>
                }
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={PeopleAltOutlined} text={'Kunden-Beratung'}
                        onItemClicked={() => history.push(Routes.Customer.Master)} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButtonRed icon={ListAltOutlined} text={'Tarifübersicht'} subtitle={'nur zur internen Verwendung'}
                        onItemClicked={() => {
                            dispatch(ContractsActions.setFilter({ new: true, old: false }));
                            history.push(Routes.Contracts.Overview);
                        }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={CalendarViewDayOutlined} text={'Solvency II'}
                        onItemClicked={() => history.push(Routes.SolvencyII.Overview)} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton disabled icon={VideoLibraryOutlined} text={'Videos'} subtitle={'Wird aktuell überarbeitet'}
                        onItemClicked={
                            () => {/* history.push(Routes.VideoPage) */ }
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={ShoppingCartOutlined} text={'Shop'} onItemClicked={() => {
                        var win = window.open(WebShopUrl, '_blank');
                        win?.focus();
                    }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={ExploreOutlined} text={'Beratungs-Tools'} onItemClicked={() => history.push(Routes.ToolsRiester)} />
                </Grid>
                {/* 
                <Grid item xs={12} md={4}>
                    <DisabledHomePageButton icon={
                            <SvgIcon component={PermDeviceInformation} style={{ fontSize: 40}}/>} text={'Vorsorge-Wiki'}
                                                tooltipContent={'Hier entsteht ein umfassendes Nachschlagewerk zum Thema Altersvorsorge'}/>
                </Grid>
                */}
                <Grid item xs={12} md={4}>
                    <PopupState variant={'popover'} popupId={'popup'}>
                        {(popupState) => (
                            <div>
                                <DisabledHomePageButton {...bindTrigger(popupState)} icon={
                                    <SvgIcon component={ListAltOutlined} style={{ fontSize: 40 }} />} text={'Vorsorge-Wiki'}
                                    tooltipContent={'Hier entsteht ein  umfassendes\n Nachschlagewerk zum Thema\n Altersvorsorge'} />
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div style={{ padding: 8, maxWidth: 350 }}>
                                        <p>Hier entsteht ein  umfassendes Nachschlagewerk zum Thema Altersvorsorge</p>
                                    </div>
                                </Popover>
                            </div>
                        )}
                    </PopupState>
                    {/* <HomePageButton disabled icon={PermDeviceInformation} text={'Vorsorge-Wiki'} subtitle={'In Planung'}
                                    onItemClicked={() =>{}}/> */}
                </Grid>
                <Grid item style={{ marginLeft: '-70px', marginTop: '5px' }}>
                    <PopupState variant={'popover'} popupId={'popup'}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)}>
                                    <SvgIcon component={InfoOutlined} style={{ fontSize: 18, }}
                                        color={'primary'} />
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div style={{ padding: 8, maxWidth: 350 }}>
                                        <p>Hier entsteht ein  umfassendes Nachschlagewerk zum Thema Altersvorsorge</p>
                                    </div>
                                </Popover>
                            </div>
                        )}
                    </PopupState>
                </Grid>
            </Grid>
        </DrawerScaffold>
    );
};

export default withAppVersion(HomePage);
