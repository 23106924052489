import * as React from 'react';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import { useAppDispatch } from 'app/store';
import CustomerForm from 'features/riesterProfiCheck/customers/view/create/CustomerForm';
import { Button } from '@material-ui/core';

const CreateCustomerPage: React.FC = () => {
    const dispatch = useAppDispatch();

    return (
        <NavigateBackScaffold title={'Kunde anlegen'} actions={<></>}>
            <CustomerForm edit={false} />

        </NavigateBackScaffold>
    );
};

export default CreateCustomerPage;
