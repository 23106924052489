import { Font, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

export const PDFStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontSize: 10,
        fontFamily: 'Roboto',
        margin: 20
    },
    section: {
        margin: 10,
        flexDirection: 'column'
    },
    row: {
        flexDirection: 'row'
    },
    column: {
        flexDirection: 'column'
    },
    center: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
    },
    circle: {
        width: 24,
        height: 24,
        backgroundColor: 'green',
        borderRadius: 50,
        border: '2 solid red'
    }
});

export const Grow = (props: any) => <View style={{ flexGrow: 1 }}>{props.children}</View>;
export const Whitespace = (props: { height: number }) => <View style={{ height: props.height }}></View>;
export const Row = (props: any) => <View style={{ ...PDFStyles.row, ...props.style }}>{props.children}</View>;
export const Column = (props: any) => <View style={PDFStyles.column}>{props.children}</View>;
export const Center = (props: any) => <View style={PDFStyles.center}>{props.children}</View>;
export const Section = (props: any) => <View style={PDFStyles.section}>{props.children}</View>;
export const Circle = (props: any) => <View style={PDFStyles.circle}>{props.children}</View>;
