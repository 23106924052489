import CustomerModel from 'features/riesterProfiCheck/customers/data/CustomerModel';
import CustomerAddress from 'features/riesterProfiCheck/customers/data/CustomerAdress';

export default class Customer implements CustomerModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    address?: CustomerAddress | undefined;

    constructor(id: string, firstName: string, lastName: string, email: string, address?: CustomerAddress) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.address = address;
    }

    get fullName() {
        return this.firstName + ' ' + this.lastName;
    }

    get cityWithPostalCode() {
        return this.address?.postalCode + ' ' + this.address?.city;
    }

    toString() {
        return JSON.stringify(this);
    }
}

export const CustomerFromModel = (model: CustomerModel) => new Customer(model.id, model.firstName, model.lastName, model.email, model.address);
