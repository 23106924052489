import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardContent, Grid, Icon, makeStyles } from '@material-ui/core';

import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';

interface Props {
    question: any;
    index: number;
    questionValues: Record<number, boolean>;
    bigView: boolean;
}

const useStyles = makeStyles({
    selected: {
        background: '#A7893D',
        color: 'white'
    },
    unselected: {
        color: '#A7893D',
        background: 'white'
    }
});

const QuestionView: React.FC<Props> = ({ question, index, questionValues, bigView }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const toggleQuestion = () => {
        dispatch(questionValues[index] ? ConsultationActions.setQuestionDisabled(index) : ConsultationActions.setQuestionEnabled(index));
    };

    return (
        <Grid item container direction="row" spacing={1} key={index} style={{ width: '100%', cursor: bigView ? 'auto' : 'pointer' }}
              onClick={() => {
                  if (!bigView) {
                      toggleQuestion();
                  }
              }}>
            <Grid xs={2} item onClick={() => toggleQuestion()}>
                <Card
                    style={{
                        height: '100%',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        cursor: 'pointer'
                    }}
                    className={questionValues[index] ? classes.selected : ''}
                >
                    <h2>{(index + 1).toString()}</h2>
                </Card>
            </Grid>
            <Grid xs={10} item>
                <Card>
                    <CardContent>
                        <h3>{question.title}</h3>

                        <p>{question.content}</p>
                        <p style={{fontWeight: 'bold'}}>{question.question}</p>
                        <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={{padding: '24pt'}}>
                            {bigView && (<><Button
                                style={{marginRight: '12pt'}}
                                startIcon={<Icon>block</Icon>}
                                variant={'outlined'}
                                color={'primary'}
                                onClick={() => {
                                    dispatch(ConsultationActions.setQuestionDisabled(index));
                                }}
                            >
                                Nein
                            </Button>
                                <Button
                                    style={{marginLeft: '12pt'}}
                                    startIcon={<Icon>check</Icon>}
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={() => {
                                        dispatch(ConsultationActions.setQuestionEnabled(index));
                                    }}
                                >
                                    Ja
                                </Button></>)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
export default QuestionView;
