import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';

const NoCustomerSelectedView: React.FC = () => {
    return (
        <Grid container direction={'column'} alignContent={'center'} justify={'center'}>
            <Typography variant={'h6'}>Wählen Sie einen Kontakt aus, um eine Beratung zu starten</Typography>
        </Grid>
    );
};

export default NoCustomerSelectedView;
