import * as React from 'react';
import { useSelector } from 'react-redux';
import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';
import MaterialTable from 'material-table';
import { RootState, useAppDispatch } from 'app/store';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Icon,
    IconButton,
    Switch, Tooltip,
    Typography
} from '@material-ui/core';
import IconFilter from '@material-ui/icons/SettingsOutlined';
import DrawerRightScaffold from 'app/components/scaffold/DrawerRightScaffold';
import IconCompare from '@material-ui/icons/CompareArrows';
import { hasGreenName } from 'features/riesterProfiCheck/consultation/questions/domain/GreenNameColor';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';

import {
    defaultCellStyle,
    defaultHeaderStyle,
    getBeitragspauseCellStyle,
    getBesserungsoptionCellStyle,
    getGarantierteRenteCellStyle,
    getGarantierterRentenfaktorCellStyle,
    getEffektivkostenCellStyle,
    getVerschiebungRentenbeginnCellStyle,
    getMaxRGZCellStyle,
    getVKRenteCellStyle,
    getMindestBeitragCellStyle,
    getMindestLaufzeitCellStyle,
    getBUCellStyle,
    getSchiebeReglerCellStyle,
    getSolvencyCellStyle
} from 'features/riesterProfiCheck/consultation/result/domain/CellColors';
import TableTooltip from 'features/riesterProfiCheck/consultation/result/components/TableTooltip';

import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import {
    getNewContracts,
    getOldContracts,
    getValidityString, orderContracts
} from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { ContractsActions, getContractIdsToCompare, getContracts } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import { TableHeadersWithoutNewline } from 'features/riesterProfiCheck/consultation/models/TableHeaders';
import LongPressListener from 'features/riesterProfiCheck/consultation/result/components/ContractNameCell';
import OpaqueButton from 'app/components/buttons/OpaqueButton';
import ContractsOverviewPDFButton from 'features/riesterProfiCheck/insurance_contracts/overview/pdf/ContractsOverviewPDFButton';
import { useState } from 'react';
import useWindowDimensions from 'utils/useWindowDimensions';


const ContractsOverviewPage: React.FC = () => {

    const { height, width } = useWindowDimensions();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [showEffektivkosten, setShowEffektivkosten] = useState(true);
    const [showValidity, setShowValidity] = useState(true);


    const contractsFilter = useSelector((state: RootState) => state.contracts.filter);

    const allContracts = useSelector(getContracts);

    let contracts: Array<InsuranceContractModel>;
    if (contractsFilter.new && !contractsFilter.old) {
        contracts = getNewContracts(allContracts);
    } else if (contractsFilter.old && !contractsFilter.new) {
        contracts = getOldContracts(allContracts);
    } else {
        contracts = allContracts.ids.map((id) => allContracts.entities[id]);
    }

    contracts = orderContracts(contracts);

    /**
     * COMPARE CONTRACTS
     */
    const contractsToCompareIds = useSelector(getContractIdsToCompare);
    const compareContractsEnabled = useSelector((state: RootState) => state.currentConsultation.compareProvidersEnabled);
    const onCompareIconPressed = () => {
        dispatch(ConsultationActions.toggleCompareProvidersEnabled());
        resetContractToCompareIds();
    };
    const setContractsToCompareIds = (ids: InsuranceContractModel[]) => {
        dispatch(ContractsActions.setContractIdsToCompare(ids));
    };
    const resetContractToCompareIds = () => {
        dispatch(ContractsActions.resetContractIdsToCompare());
    };

    const contractsData = contracts.map((o) => {
        if (contractsToCompareIds.includes(o.id)) {
            return { ...o, tableData: { checked: true } };
        }

        return { ...o };
    });

    const onShowValidityChanged = () => setShowValidity(!showValidity);
    const onShowEffektivkostenChange = () => {setShowEffektivkosten(!showEffektivkosten)
    };


    const drawerContent = (
        <div style={{ padding: '8px 16px' }}>
            <Typography style={{ marginTop: '12px' }} color={'primary'}>
                Anzeige
            </Typography>
            <Divider style={{ marginBottom: '8px' }}/>
            <FormGroup>
                <FormControlLabel
                    control={<Switch color={'primary'} checked={showEffektivkosten}
                                     onChange={onShowEffektivkostenChange}/>}
                    label="Effektivkosten"
                />
                <FormControlLabel
                    control={<Switch color={'primary'} checked={showValidity} onChange={onShowValidityChanged}/>}
                    label="Gültigkeit"/>
            </FormGroup>
        </div>
    );

    const actions = (
        <>
            <Tooltip title="Tarife vergleichen">
                <IconButton onClick={onCompareIconPressed}>
                    <IconCompare color={compareContractsEnabled ? 'primary' : 'action'}/>
                </IconButton>
            </Tooltip>
            <ContractsOverviewPDFButton contracts={contracts}/>
            <ButtonGroup orientation={'horizontal'} variant="text"
                         aria-label="contained primary button group">
                <Button color={contractsFilter.new && !contractsFilter.old ? 'primary' : 'default'}
                        onClick={() => dispatch(ContractsActions.setFilter({ old: false, new: true }))}>
                    Aktuelle Tarife
                </Button>
                <Button color={contractsFilter.old && !contractsFilter.new ? 'primary' : 'default'}
                        onClick={() => dispatch(ContractsActions.setFilter({ old: true, new: false }))}>
                    Alttarife
                </Button>
                <Button color={contractsFilter.new && contractsFilter.old ? 'primary' : 'default'}
                        onClick={() => dispatch(ContractsActions.setFilter({ old: true, new: true }))}>
                    Alle Tarife
                </Button>
            </ButtonGroup>

        </>
    );

    return (
        <DrawerRightScaffold
            fullWidth={true}
            title={'Tarifübersicht'}
            openDrawerIcon={<IconFilter/>}
            drawerContent={drawerContent}
            onNavigateBack={() => {
                if (compareContractsEnabled) {
                    dispatch(ContractsActions.resetContractIdsToCompare());
                    dispatch(ConsultationActions.toggleCompareProvidersEnabled());
                }
            }}
            withBackNavigation={true}
            actions={actions}
        >
            <div
                style={{ maxWidth: '10                                                                                                       0%' }}>
                <MaterialTable<InsuranceContractModel>
                    page={currentPageNumber}
                    onChangePage={(newPageNumber) => {
                        setCurrentPageNumber(newPageNumber);
                    }}
                    data={contractsData}
                    title={`${contractsData.length} Tarife (${contractsFilter.new && contractsFilter.old ? 'Alle Tarife' : contractsFilter.old ? 'Alttarife' : 'Aktuelle Tarife'})`}
                    localization={{
                        toolbar: {
                            searchTooltip: 'Suchen',
                            searchPlaceholder: 'Suchen',
                            nRowsSelected: '{0}/2 Tarif(e) ausgewählt'
                        },
                        pagination: {
                            labelRowsSelect: 'Tarife',
                            labelRowsPerPage: 'Tarife pro Seite'
                        }
                    }}
                    onSelectionChange={(rows) => {
                        if (rows.length - contractsToCompareIds.length > 1) {
                            return;
                        }
                        setContractsToCompareIds(rows);
                    }}
                    options={{
                        draggable: false,
                        sorting: false,
                        selection: compareContractsEnabled,
                        padding: 'dense',
                        pageSize: 10, //editableContracts.length,
                        paginationType: 'stepped',
                        maxBodyHeight: height - 300,
                        headerStyle: {
                            background: 'white',
                            borderBottom: '1 px solid gray',
                            position: 'sticky',
                            top: 0,
                            fontSize: 11,
                            zIndex: 85
                        },
                        actionsCellStyle: {
                            backgroundColor: '#dbdbdb',
                            color: 'black'
                        },
                        selectionProps: (rowData: any) => ({
                            disabled: contractsToCompareIds.length >= 2 && !rowData.tableData.checked,
                            color: 'primary'
                        })
                        //grouping: true
                    }}
                    components={{
                        Container: (props) => <div
                            style={{ width: width - 48, height: height - 300 }}>{props.children}</div>
                    }}
                    columns={[
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[0].title, TableHeadersWithoutNewline[0].comment),
                            cellStyle: {
                                background: 'white',
                                borderRight: '1 px solid gray',
                                position: 'sticky',
                                left: 0,
                                zIndex: 80
                            },
                            headerStyle: {
                                background: 'white',
                                borderRight: '1 px solid gray',
                                position: 'sticky',
                                left: 0,
                                zIndex: 90,
                                fontSize: 11
                            },
                            field: 'name',
                            render: (contract) => {
                                return (
                                    <LongPressListener
                                        callback={() => {
                                            history.push(Routes.Contracts.DetailView + '/' + contract.id);
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingLeft: 8,
                                                marginLeft: -12,
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {renderCellWithTooltip(
                                                contract.name,
                                                contract.nameCom,
                                                {
                                                    color: hasGreenName(contract) ? 'green' : 'black',
                                                    width: '150pt'
                                                } as React.CSSProperties,
                                                'left'
                                            )}
                                        </div>
                                    </LongPressListener>
                                );
                            }
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[1].title, TableHeadersWithoutNewline[1].comment),
                            field: 'vertragsart',
                            cellStyle: defaultCellStyle as React.CSSProperties,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.vertragsart, contract.vertragsartCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[2].title, TableHeadersWithoutNewline[2].comment),
                            field: 'garantierteRente',
                            cellStyle: getGarantierteRenteCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.garantierteRente, contract.garantierteRenteCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[3].title, TableHeadersWithoutNewline[3].comment),
                            field: 'garantierterRentenfaktor',
                            cellStyle: getGarantierterRentenfaktorCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.garantierterRentenfaktor, contract.garantierterRentenfaktorCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[4].title, TableHeadersWithoutNewline[4].comment),
                            field: 'besserungsoption',
                            cellStyle: getBesserungsoptionCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.besserungsoption, contract.besserungsoptionCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[5].title, TableHeadersWithoutNewline[5].comment),
                            field: 'beitragspause',
                            cellStyle: getBeitragspauseCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.beitragspause, contract.beitragspauseCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[6].title, TableHeadersWithoutNewline[6].comment),
                            field: 'verschiebungRentenbeginn',
                            cellStyle: getVerschiebungRentenbeginnCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.verschiebungRentenbeginn, contract.verschiebungRentenbeginnCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[7].title, TableHeadersWithoutNewline[7].comment),
                            field: 'maxRgz',
                            cellStyle: getMaxRGZCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.maxRgz, contract.maxRgzCom)
                        },

                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[8].title, TableHeadersWithoutNewline[8].comment),
                            field: 'effektivkosten',
                            cellStyle: getEffektivkostenCellStyle,
                            headerStyle: defaultHeaderStyle,
                            hidden: !showEffektivkosten,
                            render: (contract) =>
                                renderCellWithTooltip(
                                    contract.effektivkosten.toString() === '?' ? '?' : contract.effektivkosten + '%',
                                    contract.effektivkostenCom
                                )
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[9].title, TableHeadersWithoutNewline[9].comment),
                            field: 'vkRente',
                            cellStyle: getVKRenteCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) =>
                                renderCellWithTooltip(contract.vkRente.toString() === 'unbek.' ? 'unbek.' : contract.vkRente + '%', contract.vkRenteCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[10].title, TableHeadersWithoutNewline[10].comment),
                            field: 'mindestbeitrag',
                            cellStyle: getMindestBeitragCellStyle,
                            headerStyle: defaultHeaderStyle,

                            render: (contract) =>
                                renderCellWithTooltip(
                                    contract.mindestbeitrag.toString() === '?' ? '?' : contract.mindestbeitrag + ' €',
                                    contract.mindestbeitragCom
                                )
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[11].title, TableHeadersWithoutNewline[11].comment),
                            field: 'mindestlaufzeit',
                            cellStyle: getMindestLaufzeitCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.mindestlaufzeit, contract.mindestlaufzeitCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[12].title, TableHeadersWithoutNewline[12].comment),
                            field: 'bu',
                            cellStyle: getBUCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.bu, contract.buCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[13].title, TableHeadersWithoutNewline[13].comment),
                            field: 'schieberegler',
                            cellStyle: getSchiebeReglerCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.schieberegler, contract.schiebereglerCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[14].title, TableHeadersWithoutNewline[14].comment),
                            field: 'solvency2',
                            cellStyle: getSolvencyCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.solvency2, contract.solvency2Com)
                        },

                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[15].title, TableHeadersWithoutNewline[15].comment),
                            cellStyle: defaultCellStyle,
                            headerStyle: defaultHeaderStyle,
                            hidden: !showValidity,
                            render: (contract: InsuranceContractModel) => <div>{getValidityString(contract)}</div>
                        }
                    ]}
                />

                <Box display={compareContractsEnabled ? 'flex' : 'none'} style={{ width: '100%', marginTop: 40 }}>
                    <Grid container direction={'row'} spacing={4}>
                        <Grid item md={6}>
                            <OpaqueButton
                                disabled={contractsToCompareIds.length !== 2}
                                onClick={() => history.push(Routes.Contracts.Compare)}
                                startIcon={<Icon>compare_arrows</Icon>}
                                size={'large'}
                                fullWidth
                                variant={'contained'}
                                color={'primary'}
                            >
                                Vergleichen
                            </OpaqueButton>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </DrawerRightScaffold>
    );
};

const renderCellWithTooltip = (
    content: string | number,
    comment: string | number,
    contentStyle?: React.CSSProperties | undefined,
    align?: 'center' | 'left'
) => {
    content = content.toString();
    comment = comment.toString();

    contentStyle = {
        ...contentStyle,
        height: '28pt',
        display: 'flex',
        alignItems: 'center',
        justifyContent: align ?? 'center'
    };
    return comment !== '' ? (
        <TableTooltip tooltipValue={comment}>
            <div style={contentStyle}>{content}</div>
        </TableTooltip>
    ) : (
        <div style={contentStyle}>{content}</div>
    );
};

const renderHeaderWithTooltip = (content: string | number, comment: string | number, align?: 'center' | 'left') => {
    content = content.toString();
    comment = comment.toString();

    const contentStyle = { flexGrow: 1, height: '100%' };
    return comment !== '' ? (
        <TableTooltip tooltipValue={comment}>
            <div style={contentStyle}>{content}</div>
        </TableTooltip>
    ) : (
        <div style={contentStyle}>{content}</div>
    );
};

export default ContractsOverviewPage;
