import { Button, CardActionArea, createStyles, Paper, SvgIcon, withStyles } from '@material-ui/core';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.grey
        },

        padding: theme.spacing(4),
        alignSelf: 'stretch',
        width: '100%',
        height: '200px'
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))(Button);

const RedColorButton = withStyles((theme) => ({
    root: {
        color: 'red',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.grey
        },

        padding: theme.spacing(4),
        alignSelf: 'stretch',
        width: '100%',
        height: '200px'
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))(Button);

interface Props {
    icon: any;
    text: String;
    onItemClicked: () => void
    red?: boolean;
    disabled?: boolean;
    subtitle?: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionArea: {
            flex: 1,
            '&.Mui-disabled': {
                opacity: 0.6,
            },
        },
    }),
);

const HomePageButtonRed: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <Paper onClick={props.onItemClicked}>
                <CardActionArea className={classes.actionArea} disabled={props.disabled}>
                    {props.red ? <RedColorButton>
                            <SvgIcon component={props.icon} style={{ fontSize: 40 }}/>
                            <h2>{props.text}</h2>
                        </RedColorButton> :
                        <ColorButton>
                            <SvgIcon component={props.icon} style={{ fontSize: 40}}/>
                            <h2 style={{marginBottom: 4, marginTop: 6}}>{props.text}</h2>
                            {props.disabled && props.subtitle != null && <p style={{ position:'absolute', bottom:24 }}></p> }
                            {props.subtitle && <p style={{ fontSize: 16, position:'absolute', bottom:24, color: 'red' }}>{props.subtitle}</p>}
                        </ColorButton>}
                </CardActionArea>
        </Paper>
    );
};

export default HomePageButtonRed;