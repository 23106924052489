import Routes from 'app/routes/Routes';
import { isAuthenticated } from 'features/app_user/appUserSlice';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
const AppUserRoute = ({ ...rest }) => {
    // console.log(rest)
    var auth = useSelector(isAuthenticated);
    if(rest && rest.path && rest.path.includes('ios/')){
        auth = true;
    }
    
    if (auth) {
        return <Route {...rest} />;
    } else {
        return <Redirect to={Routes.Auth.Login} />;
    }
};

export default AppUserRoute;
