import { AppUserModel, AppUserData } from 'features/app_user/data/AppUserModel';
import MalePlaceholder from 'assets/avatar_man.png';
import FemalePlaceholder from 'assets/avatar_woman.png';

export type Gender = 'm' | 'w';
export enum GenderType {
    male = 'm',
    female = 'w'
}

export default class AppUser implements AppUserModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: Gender;
    street: string;
    streetNumber: string;
    zip: string;
    city: string;
    vbNummer: string;
    direktionsNummer: string;
    validUntil: number;
    hasWebLicense: boolean;
    hasIOSLicense?: boolean | undefined;
    hasTestLicense?: boolean | undefined;
    appData: AppUserData[];

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        gender: Gender,
        street: string,
        streetNumber: string,
        postalCode: string,
        city: string,
        vbNummer: string,
        direktionsNummer: string,
        validUntil: number,

        hasWebLicense: boolean,
        hasIOSLicense: boolean | undefined,
        hasTestLicense: boolean | undefined,
        appData: AppUserData[]
    ) {
        this.id = id
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.gender = gender;
        this.street = street;
        this.streetNumber = streetNumber;
        this.zip = postalCode;
        this.city = city;
        this.vbNummer = vbNummer;
        this.direktionsNummer = direktionsNummer;
        this.validUntil = validUntil;
        this.hasIOSLicense = hasIOSLicense;
        this.hasWebLicense = hasWebLicense;
        this.hasTestLicense = hasTestLicense;
        this.appData = appData;
    }

    getImgSrc(): any {
        return localStorage.getItem('profilePicture') === null ? (this.isMale() ? MalePlaceholder : FemalePlaceholder) : localStorage.getItem('profilePicture');
    }

    getTitle(): string {
        return this.gender === GenderType.male ? 'Vermögensberater' : 'Vermögensberaterin';
    }

    getPostalCodeAndCity(): string {
        return this.zip + ' ' + this.city;
    }

    getStreetAndNumber(): string {
        return this.street + ' ' + this.streetNumber;
    }

    isAdmin(): boolean {
        return this.email === 'n.rossberg@smmd.team'; // 'deberl@gmx.de';
    }

    isMale(): boolean {
        return this.gender === GenderType.male;
    }

    getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }
}

export const AppUserFromModel = (model: AppUserModel | undefined) => {
    if (model) {
        return new AppUser(
            model.id,
            model.firstName,
            model.lastName,
            model.email,
            model.gender,
            model.street,
            model.streetNumber,
            model.zip,
            model.city,
            model.vbNummer,
            model.direktionsNummer,
            model.validUntil,
            model.hasWebLicense,
            model.hasIOSLicense,
            model.hasTestLicense,
            model.appData
        );
    } else {
        throw Error('AppUserModel was undefined when creating an AppUser instance');
    }
};
