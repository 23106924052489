import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import InsuranceContractModel from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractModel';
import { Center, PDFStyles, Row, Section } from 'core/pdf/CustomPdfComponents';

import { getPropsAsArray } from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { grey } from '@material-ui/core/colors';

export const PdfCompareContracts = (props: { contracts: Array<InsuranceContractModel> }) => {
    const contract1 = props.contracts[0];
    const contract2 = props.contracts[1];

    const contract1Props = getPropsAsArray(contract1, true, true);
    const contract2Props = getPropsAsArray(contract2, true, true);

    return (
        <Document>
            <Page size={'A4'} orientation={'landscape'} style={{ ...PDFStyles.page, paddingRight: 40, paddingTop: 0 }}>
                <Center>
                    <Text style={{fontSize: 24, color:  grey[200]}}>Nur zur internen Verwendung</Text>
                </Center>
                <Section>

                    {contract1Props.map((prop, index) => {
                        if (index === 0) {
                            return (
                                <View>
                                    <Row>
                                        <View style={{ width: '20%', padding: 6, justifyContent: 'center' }}>
                                            <Text style={{ fontWeight: 'bold' }}>{prop.header.title.replace(/-\n/g, '')}</Text>
                                        </View>
                                        <View style={{ width: '20%', padding: 6, justifyContent: 'center' }}>
                                            <Text>Kommentar</Text>
                                        </View>
                                        <View style={{ width: '15%', textAlign: 'center', padding: 6, justifyContent: 'center' }}>
                                            <Text style={{ fontWeight: 'bold' }}>{contract1Props[index].prop}</Text>
                                        </View>
                                        <View style={{ width: '15%', textAlign: 'center', padding: 6, justifyContent: 'center' }}>
                                            <Text style={{ fontWeight: 'bold' }}>{contract2Props[index].prop}</Text>
                                        </View>
                                        <View style={{ width: '30%', padding: 6, justifyContent: 'center' }}>
                                            <Text>Kommentar</Text>
                                        </View>
                                    </Row>
                                </View>
                            );
                        } else {
                            return (
                                <MyTableRow
                                    title={prop.header.title}
                                    com1={contract1Props[index].comment}
                                    val1={contract1Props[index].prop}
                                    val2={contract2Props[index].prop}
                                    com2={contract2Props[index].comment}
                                />
                            );
                        }
                    })}
                </Section>
            </Page>
        </Document>
    );
};

const MyTableRow = (props: { title: string; com1: string; val1: string; val2: string; com2: string }) => {
    return (
        <View
            minPresenceAhead={2}
            style={{
                borderStyle: 'solid',
                borderColor: '#bfbfbf',
                borderWidth: 0.5,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderTopWidth: 0.5,
                borderRightWidth: 0
            }}
        >
            <Row>
                <View style={{ width: '20%', padding: 8, justifyContent: 'center', fontWeight: 'bold' }}>
                    <Text>{props.title}</Text>
                </View>
                <View style={{ width: '20%', padding: 8, justifyContent: 'center' }}>
                    <Text>{props.com1}</Text>
                </View>
                <View style={{ width: '15%', padding: 8, textAlign: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                    <Text>{props.val1}</Text>
                </View>
                <View style={{ width: '15%', padding: 8, textAlign: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                    <Text>{props.val2}</Text>
                </View>
                <View style={{ width: '30%', padding: 8, justifyContent: 'center' }}>
                    <Text>{props.com2}</Text>
                </View>
            </Row>
        </View>
    );
};
