import * as React from 'react';
import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import SearchBar from 'features/riesterProfiCheck/customers/view/overview/components/list/SearchBar';
import { RootState, useAppDispatch } from 'app/store';
import { CustomerActions, getCustomers } from 'features/riesterProfiCheck/customers/customerSlice';
import Customer, { CustomerFromModel } from 'features/riesterProfiCheck/customers/domain/Customer';
import { createSelector } from '@reduxjs/toolkit';
import { searchCustomerIds } from 'features/riesterProfiCheck/customers/domain/CustomerSearch';
import { useSelector } from 'react-redux';

interface Props {
    protectCustomers: boolean;
}

const CustomersListView: React.FC<Props> = ({protectCustomers}) => {


    const filteredCustomers = useSelector(createSelector(
        getCustomers,
        (state: RootState) => state.customerStore.searchValue,
        (customers, searchValue) =>
            searchCustomerIds(customers, searchValue, protectCustomers).map((id) => CustomerFromModel(customers.entities[id]))
    )) as Array<Customer>;

    const dispatch = useAppDispatch();

    return (
        <Grid container direction={'column'} style={{ height: '85vh' }} item md={3} xs={12}>
            <SearchBar />
            <Paper style={{ overflow: 'auto', marginTop: '12px', flexGrow: 1 }}>
                <List>
                    {filteredCustomers.map((customer: Customer) => {
                        return (
                            <ListItem button key={customer.id} onClick={() => dispatch(CustomerActions.setCurrentCustomerId(customer.id))}>
                                <ListItemText style={{ padding: '4px' }} primary={customer.lastName + ', ' + customer.firstName} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        </Grid>
    );
};

export default CustomersListView;
