import * as React from 'react';
import { Typography } from '@material-ui/core';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';

const InformationsPage: React.FC = () => {

    return (
        <NavigateBackScaffold title={'Informationen'} actions={<></>}>
            <>
                <Typography variant={'h3'}>Vorsorge-Profi-Check</Typography>
                <Typography variant={'h4'}>
                    Der „Vorsorge-Profi-Check“ ist ein Analyse- und Beratungstool für
                    die Altersvorsorgeberatung.
                </Typography>
                <Typography variant={'body1'}>
                    <br />
                    Der „Vorsorge-Profi-Check“ beinhaltet u. a. den „Riester-Profi-Check“ mit allen aktuell im Markt
                    angebotenen Riester-Tarifen aus dem Bank-, Investment-, Bauspar- und Versicherungsbereich.
                    <br />
                    <br />
                    Der „Vorsorge-Profi-Check“ wird bereits in wenigen Wochen einen weiteren „Profi-Check“ für 
                    die Basis- bzw. Rürup-Rente erhalten.
                    <br />
                    <br />
                    Der „Vorsorge-Profi-Check“ macht es dem Verbraucher leicht, mit den „10 Goldenen RiesterFragen“ - und bald schon mit den „Goldenen Rürup-Fragen“ - ausgehend von seinen
                    persönlichen Auswahlkriterien den Tarif zu finden, der seinen Wünschen und Anforderungen am
                    besten entspricht.
                    <br />
                    <br />
                    Der Berater kann das Beratungsergebnis personalisiert abspeichern, drucken oder per Email
                    versenden. Zu jedem Zeitpunkt kann das gespeicherte Ergebnis wieder aufgerufen und bearbeitet
                    werden.
                    <br />
                    <br />
                    Die „Profi-Checks“ orientieren sich ausschließlich an den Interessen des Verbrauchers. Sie sind in
                    Ihrer Art einzigartig und zählen aktuell sicherlich zu den besten Analyse- und Beratungstools für
                    Vorsorgeberater von Riester-Tarifen und bald auch schon von Rürup-Tarifen.
                    <br />
                    <br />
                    Der „Vorsorge-Profi-Check“, der „Riester-Profi-Check“ und die „10 Goldenen Riester-Fragen“ sind
                    ein Markenzeichen der „DFIAV - Deutsche Förderinitiative Altersvorsorge UG“ und unterliegen
                    dem Schutz des Urheberrechtes.
                    <br />
                    <br />
                    Der „Vorsorge-Profi-Check“ bietet zur Beratungsunterstützung weitere „Beratungstools“ wie zum
                    Beispiel die „Goldene Riester-Pyramide“ und besitzt eine Videothek mit vielen Erklärvideos rund
                    um das Thema Altersvorsorge.
                    <br />
                    <br />
                    Als weiteres Herzstück ist eine „Wissensthek“ als umfangreiches Nachschlagewerk zu den
                    wichtigsten Themen der Altersvorsorgeberatung in Planung.
                </Typography>
                <br />
                <br />
                <Typography variant={'h5'}>Tarifvergleich</Typography>
                <br />
                <Typography variant={'body1'}>
                    Die Tarifvergleiche ermöglichen einen guten Überblick über die wichtigsten Leistungsmerkmale der ausgewählten Tarife. <br />
                    <br />
                    Bei den Leistungsmerkmalen dieser Vergleichstarife wird zunächst überprüft, ob die „10 Goldenen Riester-Fragen“ ganz oder teilweise erfüllt
                    werden. <br />
                    <br />
                    Zudem können folgende Informationen gewonnen werden: <br />
                </Typography>
                <ul className={'body1'}>
                    <li>Um welche Tarif- bzw. Vertragsart handelt es sich? </li>
                    <li>Höhe der Garantierente laut Produktinformationsblatt (PIB)</li>
                    <li>Höhe des garantierten Rentenfaktors laut PIB</li>
                    <li>Effektivkosten in der Ansparphase laut PIB</li>
                    <li>Verwaltungskosten in der Verrentungsphase laut PIB</li>
                    <li>Mindestbeitrag</li>
                    <li>Mindestlaufzeit</li>
                    <li>Informationen zu den Solvency II Quoten</li>
                    <li>sonstige wichtige Besonderheiten zu einzelnen Tarifen</li>
                </ul>
                <Typography variant={'body1'}>
                    <br />
                    Es wird ausdrücklich darauf hingewiesen, dass die Informationen nicht umfassend sind. Die genauen Vertrags- und Tarifinhalte ergeben sich
                    aus dem Antrag, dem Versicherungsschein sowie den Versicherungsbedingungen und ggf. besonderen Bedingungen. <br />
                    <br />
                    Der „Vorsorge-Profi-Check“ wird laufend aktualisiert und steht den Nutzern durch regelmäßige
                    Updates immer relativ aktuell zur Verfügung. Bei jedem Start des „Vorsorge-Profi-Checks“ wird
                    automatisch überprüft ob inzwischen eine aktuellere Version vorliegt und es erfolgt ein Hinweis zu
                    einem Update.
                    <br />
                    <br />
                    Trotz aller Sorgfalt können sich Tarifdaten inzwischen auch verändert haben.
                </Typography>
                <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                    Eine Haftung für Aktualität, Richtigkeit und Vollständigkeit kann daher nicht übernommen werden.
                </Typography>
                <br />
                <br />
                <Typography variant={'h5'}>Quellen</Typography>

                <ul className={'body1'}>
                    <li>Versicherungsbedingungen</li>
                    <li>Ergänzende Vertragsbedingungen</li>
                    <li>Muster-Produktinformationsblätter</li>
                    <li>Bundeszentralamt für Steuern</li>
                    <li>Homepages der Versicherer</li>
                </ul>

                <Typography variant={'h5'}>Nur zur internen Verwendung</Typography>
                <br />
                <br />
                <Typography variant={'body1'}>
                    Der Einsatz des „Vorsorge-Profi-Checks“ ist ausschließlich zur internen Verwendung bestimmt.
                    <br />
                    <br />
                    Die Weitergabe der Informationen und Auswertungsergebnisse an den Kunden ist nicht erlaubt.
                </Typography>
                <br />
                <br />
                <Typography variant={'h5'}>Inhalte von Drittanbietern</Typography>
                <Typography variant={'body1'}>
                    Die Platzhalter für die Profilbilder wurden von Freepik (männlicher und weiblicher Platzhalter) auf{' '}
                    <a href={'www.flaticon.com'} target="_blank" rel='noopener noreferrer'>www.flaticon.com</a> zur Verfügung gestellt.
                </Typography>
                <br />
                <br />
                <Typography variant={'body1'}>
                    Weiblicher Platzhalter: <br />{' '}
                    <a href={'https://www.flaticon.com/free-icon/woman_417776'} target="_blank" rel='noopener noreferrer'>https://www.flaticon.com/free-icon/woman_417776</a>{' '}
                </Typography>
                <br />
                <br />
                <Typography variant={'body1'}>
                    Männlicher Platzhalter: <br />
                    <a href={'https://www.flaticon.com/free-icon/man_417777'} target="_blank" rel='noopener noreferrer'>https://www.flaticon.com/free-icon/man_417777</a>{' '}
                </Typography>
                <br />
                <br />
            </>

        </NavigateBackScaffold >
    );
};

export default InformationsPage;
