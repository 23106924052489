import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SnackbarState {
    show: boolean;
    text: string;
}

const initialState = {
    show: false,
    text: 'Hello'
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        show: (state: SnackbarState, action: PayloadAction<string>) => {
            state.text = action.payload;
            state.show = true;
        },
        hide: (state: SnackbarState) => {
            state.show = false;
        }
    }
});

export const SnackbarActions = snackbarSlice.actions;
export default snackbarSlice.reducer;
