import TableHeader from 'features/riesterProfiCheck/consultation/models/TableHeader';

const TableHeaders: Array<TableHeader> = [
    {
        title: 'Anbieter (Tarif)\n',
        comment: '- Anbieter-Name\n- ggfs Tarifbezeichnung\n- Zertifizierungsnummer (sofern bereits hinterlegt)'
    },
    {
        title: 'Vertragsart\n',
        comment: 'FP: Fondspolice\nKP: Kapitalpolice\nKP (Üb. In F.): Kapitalpolice, Überschüsse gehen in Fonds\nIP: Indexpolice\nIP/FP: Mix aus Index- u. Fondspolice\nBSP: Banksparplan\nFSP: Fondssparplan\nBSV: Bausparvertrag'
    },
    {
        title: 'Garantierte\nRente',
        comment: 'Die garantierte Rente bezieht sich nur auf das Garantiekapital, also ausschließlich auf die gezahlten Beiträge und Zulagen'
    },
    {
        title: 'Garantierter\nRentenfaktor',
        comment: 'Der garantierte Rentenfaktor bezieht sich in der Regel auf das gesamte Verrentungskapital und zwar im Verhältnis je 10.000 €.\n\nIm Einzelfall bezieht sich der RF bei wenigen Anbietern lediglich auf das Garantiekapital.\nDer Hinweis "nur für GK\" bedeutet somit: \nDieser Rentenfaktor gilt hier nur für das Garantiekapital (Beiträge und Zulagen)'
    },
    {
        title: 'Besserungs\noption',
        comment: 'Besserungsption bedeutet: \nSollte sich zu Rentenbeginn entweder wegen besserer Sterbetafeln (als zu Vertragsbeginn kalkulíert) und/oder aufgrund besserer Marktverzinsung ein höherer Rentenfaktor (RF) und somit eine höhere Rente ergeben,\nwerden die besseren Rechnungsgrundlagen angewendet und der VN wird somit besser gestellt als anfangs garantiert.'
    },
    {
        title: 'Beitragspause\nmit gleichen RG',
        comment: 'Wird der Vertrag nach einer Wiederinkraftsetzung in Folge einer Beitragsreduzierung oder einer Beitragsfreistellung mit den gleichen Garantien und Rechnungsgrundlagen (RG) wie zu Beginn vereinbart fortgesetzt?'
    },
    {
        title: 'Verschiebung\nRentenbeginn',
        comment: 'Eine Verschiebung des Rentenbeginns kann z. B. aus steuerlicher Sicht Sinn machen:\nWenn ein Versicherungsnehmer in die Rente eintritt und über die Riesterrente hinaus jedoch genügend andere Vermögenswerte besitzt (z.B. Auszahlung einer Betriebsrente, Abfindung, Erbschaft etc.) kann er den Rentenbeginn seiner Riesterrente auf eigenen Wunsch verschieben.\nDadurch vermeidet er nicht nur die sofortige Besteuerung seiner Riesterrente, sondern auch dass dadurch seine anderen Einkünfte höher besteuert würden.\n\nDurch das Vorziehen oder das Verschieben des vereinbarten Rentenbeginns kann es bei einigen Anbietern passieren, dass dann neue Rechnungsgrundlagen angewendet werden und somit der garantierte Rentenfaktor seine Gültigkeit verliert! \n\nDiese Tarife entfallen bei der Beantwortung der Fragen 6 und 7.'
    },
    {
        title: 'max.\nRGZ',
        comment: 'RGZ: max. Rentengarantiezeit (ab 67)\n' +
            'RKW: Rückkaufswert\n' +
            'RKA: Restkapitalabfindung (entspricht dem Verrentungskapital abzüglich bereits gezahlter Renten)\n' +
            'RKA21: Restkapitalabfindung von max. 21 Jahresrenten (Summe von 21 Jahresrenten abzüglich bereits gezahlter Renten)\n' +
            '\n' +
            'Es werden alle Tarife ausselektiert, die nur eine Rentengarantiezeit zulassen die gleich oder kleiner ist als die aktuelle durchschnittliche Rentenbezugsdauer von 21,5 Jahren (Stand Ende 2020) von gesetzlichen Altersrenten. Somit finden nur die Tarife Berücksichtigung, die mindestens 22 Jahre RGZ haben oder länger.'
    },
    {
        title: 'Effektivkosten in %',
        comment: 'Effektivkosten: \nErrechnet aus den max. möglichen Kosten die während der Ansparphase entstehen können.\n\nWichtig: \nDie Effektivkosten der Rentenversicherer beinhalten bereits die biometrischen Risiken des Langlebigkeitsrisikos!\nDies ist ein ganz entscheidender Punkt, weshalb der direkte Kostenvergleich z. B. zwischen einem Riester-Fondssparplan und einer Riester-Rentenversicherung nicht möglich ist! \n\nDie Spalte Effektivkosten ist standardmäßig ausgeblendet, kann jedoch bei Bedarf vom Berater eingeblendet werden. \n\nDer Grund ist, dass wir ganz bewusst die Mehrwerte eines Tarifs durch die Erfüllung der Kundenkriterien in den Vordergrund stellen.'
    },
    {
        title: 'VK in\nRente in %',
        comment: 'VK Rente:\nVerwaltungskosten in der Rentenphase in % von der Jahresrente'
    },
    {
        title: 'Mindest-\nbeitrag in €',
        comment: 'Mindestbeitrag: \nDas ist der Beitrag, den ein Anbieter monatlich mindestens verlangt. \nEs handelt sich in der Regel um den Beitrag für den 37-jährigen Musterkunden mit 30 Jahren Laufzeit. \nBei anderen Laufzeiten können die mtl. Mindestbeiträge teils deutlich abweichen.'
    },
    {
        title: 'Mindest-\nlaufzeit',
        comment: 'Die Mindestlaufzeit gibt an, wie kurz die Laufzeit bei diesem Anbieter sein kann'
    },
    {
        title: 'BU\n',
        comment: 'Beitragsbefreiung bei Berufsunfähigkeit (BU). Somit Übernahme der Beiträge durch den Anbieter. Bedeutet Sparzielgarantie im Falle des vorzeitigen Ausscheidens aus dem Berufsleben durch Krankheit oder Unfall.'
    },
    {
        title: 'Schieberegler',
        comment: 'Schieberegler: \nVerschieben des Guthabens zwischen dem Fondsguthaben und dem Sicherungsvermögen'
    },
    {
        title: 'Solvency II ohne ÜM',
        comment: 'Solvency II:\n\nKommt der Versicherer ohne Anwendung der Übergangsmaßnahmen (ÜM) aus? Ja/Nein:\n\nJa:\nDiese Versicherer wenden die Übergangsmaßnahmen nicht an.\n\nNein: \nDiese Versicherer wenden (z. B. wegen nicht ausreichender Rückstellungen für die garantierten Verpflichtungen gegenüber ihren Versicherten) die genehmigungspflichtigen Übergangsmaßnahmen für versicherungtechnische Rückstellungen nach § 352 VAG entweder freiwillig oder zwangsweise an.\n\nEs gibt im Wesentlichen 3 Solvenz-Quoten.\na) Netto bzw. Basis-Quote\nb) Quote mit Volatilitätsanpassung (VA)\nc) Quote mit Übergangsmaßnahmen (ggf. zus. mit VA)\n\na) Die Netto- bzw. Basis-Quote zeigt die Überdeckung über den gesetzlich notwendigen Kapitalanforderungen unter Solvency II ohne Berücksichtigung einer möglichen Volatilitätsanpassung oder von Übergangsmaßnahmen.\n\nDer Umstieg von Solvency I auf Solvency II sah außerdem dauerhafte Anpassungen oder befristete Übergangsregelungen vor:\n\nb) Die Volatilitätsanpassung ist eine dauerhafte Anpassung. Lebensversicherer, mit langfristigen Garantien, können kurzfristige Wertschwankungen der Kapitalanlagen "aussitzen" und müssen Buchverluste nicht realisieren. Mit der Volatilitätsanpassung (VA) werden Unter- oder Überbewertungen von Zinspapieren ausgeglichen.\n\nc) Die Übergangsmaßnahme für versicherungstechnische Rückstellungen ermöglicht den Versicherern eine schrittweise Anwendung der Solvency-II-Vorschriften über einen festen Zeitraum von 16 Jahren bis max 31.12.2031. \n\nb) + c) Die Übergangsmaßnahmen (ÜM) können auch zusammen mit der Volatilitätsanpassung (VA) genutzt werden. Die meisten Lebensversicherer wenden beide Maßnahmen gleichzeitig an. \n\nDie Lebensversicherer der Generali Deutschland wenden die Übergangsmaßnahmen nicht an. Sie haben sich statt dessen nur für die Volatilitätsanpassung enschieden.'
    },
    {
        title: 'Gültigkeit',
        comment: ''
    }
];
export default TableHeaders;

export const TableHeadersWithoutNewline = JSON.parse(JSON.stringify(TableHeaders)).map((header: TableHeader) => {
    header.title = header.title.replace(/-\n/g, '').replace(/\n/g, ' ');
    return header;
});
