import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const DangerButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700]
        }
    }
}))(Button);

export default DangerButton;
