import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';

export const hasGreenName = (contract: InsuranceContractModel): boolean => {
    if (contract.zweiRentenfaktoren) {
        return contract.zweiRentenfaktoren === 'JA';
    }
    let hasGreenName;
    switch (contract.name) {
        case 'Stuttgarter':
            hasGreenName = true;
            break;
        case 'AachenMünchener (6RG)':
            hasGreenName = true;
            break;
        case 'Generali (6RG)':
            hasGreenName = true;
            break;
        case 'AachenMünchener':
            hasGreenName = true;
            break;
        case 'Hannoversche':
            hasGreenName = true;
            break;
        case 'Debeka':
            hasGreenName = true;
            break;
        default:
            hasGreenName = false;
    }

    if (contract.id === '15' || contract.id === '257' || contract.id === '258' || contract.id === '237' || contract.id === '238' || contract.id === '75' || contract.id === '283' || contract.id === '282') {
        hasGreenName = true;
    }
    return hasGreenName;
};
