import * as React from 'react';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import CustomerForm from 'features/riesterProfiCheck/customers/view/create/CustomerForm';

const EditCustomerPage: React.FC = () => {
    return (
        <NavigateBackScaffold title={'Kunde bearbeiten'} actions={<></>}>
            <CustomerForm edit={true} />
        </NavigateBackScaffold>
    );
};

export default EditCustomerPage;
