import * as React from 'react';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import CustomersListView from 'features/riesterProfiCheck/customers/view/overview/components/list/CustomersListView';
import IconProtectCustomer from '@material-ui/icons/RemoveRedEye';
import CustomerDetailView from 'features/riesterProfiCheck/customers/view/overview/components/detail/CustomerDetailView';
import { green, red } from '@material-ui/core/colors';
import { useAppDispatch } from 'app/store';
import { CustomerActions } from 'features/riesterProfiCheck/customers/customerSlice';
import DrawerScaffold from 'app/components/scaffold/DrawerScaffold';
import IconAdd from '@material-ui/icons/Add';

import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import { useLayoutEffect, useRef, useState } from 'react';
import WhatsNew from 'app/components/whats_new/WhatsNew';

const CustomersOverviewPage: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [protectCustomers, setProtectCustomers] = useState(true);


    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            dispatch(CustomerActions.setCurrentCustomerId(undefined));
            dispatch(CustomerActions.setSearchValue(''));
            return;
        }
    });

    return (
        <DrawerScaffold
            title={'Meine Kunden'}
            drawerContent={<MainDrawerContent/>}
            actions={
                <>
                    <Button startIcon={<IconAdd/>} onClick={() => history.push(Routes.Customer.Create)}>
                        Hinzufügen
                    </Button>
                    <Tooltip title="Datenschutz der Kontakte" style={{zIndex: 99999999999,background: '#f5f5f5'}}>
                        <IconButton onClick={() => {
                            setProtectCustomers(!protectCustomers);
                            dispatch(CustomerActions.toggleProtectCustomers());
                        }}>
                            <IconProtectCustomer
                                style={{
                                    color: protectCustomers ? green[500] : red[500]
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </>
            }
        >
            <Grid container direction={'row'} spacing={2}>
                <CustomersListView protectCustomers={protectCustomers}/>
                <CustomerDetailView/>
            </Grid>
        </DrawerScaffold>
    );
};

export default CustomersOverviewPage;
