import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

import { getContractsToCompare } from 'features/ruerupProfiCheck/insurance_contracts/contractsSlice';
import { PdfCompareContracts } from 'features/ruerupProfiCheck/insurance_contracts/compare/pdf/PdfCompareContracts';
import CreateAndOpenPDFButton from 'core/pdf/PDFButton';
import { useHistory } from 'react-router-dom';

const CompareContractsPDFButton: React.FC = () => {
    const contractsToCompare = useSelector(getContractsToCompare);
    const showEffektivkosten = useSelector((state: RootState) => state.currentConsultation.showEffektivkosten);
    const showAnbieterWechseln = useSelector((state: RootState) => state.currentConsultation.showAnbieterWechsel);

    const history = useHistory();
    const isIOS = (history && history.location && history.location.pathname.includes('ios/'));

    return (<CreateAndOpenPDFButton pdfDocument={<PdfCompareContracts contracts={contractsToCompare} />} isIOS={isIOS} />
    );
};

export default CompareContractsPDFButton;
