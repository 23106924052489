import React from 'react';

import InputBase from '@material-ui/core/InputBase';
import { fade, Theme, makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import { Paper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useAppDispatch } from 'app/store';
import { CustomerActions } from 'features/riesterProfiCheck/customers/customerSlice';

const useStyles = makeStyles((theme: Theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: grey[500]
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%'
    }
}));

const SearchBar: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    return (
        <Paper style={{ padding: '6px' }}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Suchen…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                    }}
                    inputProps={{ 'aria-label': 'Suchen' }}
                    onChange={(event) => {
                        const value: string = event.target.value.toLowerCase();
                        dispatch(CustomerActions.setSearchValue(value));
                    }}
                />
            </div>
        </Paper>
    );
};

export default SearchBar;
