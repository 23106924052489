import { Switch, Route, Redirect } from 'react-router-dom';
import AppUserRoute from 'app/routes/AppUserRoute';
import QuestionsPage from 'features/riesterProfiCheck/consultation/questions/QuestionsPage';
import CustomersOverviewPage from 'features/riesterProfiCheck/customers/view/overview/CustomersOverviewPage';
import HomePage from 'features/home/HomePage';
import React from 'react';
import Routes from './Routes';
import ResultPage from 'features/riesterProfiCheck/consultation/result/ResultPage';
import CreateCustomerPage from 'features/riesterProfiCheck/customers/view/create/CreateCustomerPage';
import EditCustomerPage from 'features/riesterProfiCheck/customers/view/create/EditCustomerPage';
import VideoPage from 'features/videos/VideoPage';
import CompareContractsPage from 'features/riesterProfiCheck/insurance_contracts/compare/CompareContractsPage';
import AddContractsPage from 'features/riesterProfiCheck/consultation/add_contracts/AddContractsPage';
import InsuranceContractDetailViewPage from 'features/riesterProfiCheck/insurance_contracts/detail_view/InsuranceContractDetailViewPage';
import ContractsOverviewPage from 'features/riesterProfiCheck/insurance_contracts/overview/ContractsOverviewPage';
import InformationsPage from 'features/home/info/InformationsPage';
import BackupPage from 'features/home/backup/BackupPage';
import ImprintPage from 'features/home/info/ImprintPage';
import ToolsPage from 'features/tools/ToolsPage';
import ToolsPageRiester from 'features/tools/ToolsPageRiester';
import RiesterProfiCheckPage from 'features/riesterProfiCheck/RiesterProfiCheckPage';

import RuerupProfiCheckPage from 'features/ruerupProfiCheck/RuerupProfiCheckPage';
import RuerupContractsOverviewPage from 'features/ruerupProfiCheck/insurance_contracts/overview/ContractsOverviewPage';
import RuerupCompareContractsPage from 'features/ruerupProfiCheck/insurance_contracts/compare/CompareContractsPage';
import RuerupInsuranceContractDetailViewPage from 'features/ruerupProfiCheck/insurance_contracts/detail_view/InsuranceContractDetailViewPage';

import ProfiChecksPage from 'features/home/ProfiChecksPage';
import FSPPage from 'features/tools/FSPPage';
import HRMCalculatorPage from 'features/tools/HRMCalculatorPage';

import SolvencyIIPage from 'features/home/SolvencyIIPage';
import SolvencyIIClientPage from 'features/solvency/ClientPage';
import SolvencyIIExpertPage from 'features/solvency/ExpertPage';

const AppRouteSwitch: React.FC = () => {
    return (
        <Switch>
            <AppUserRoute path={Routes.Home} component={HomePage} />
            <AppUserRoute path={Routes.Imprint} component={ImprintPage} />
            <AppUserRoute path={Routes.Info} component={InformationsPage} />
            <AppUserRoute path={Routes.Backup} component={BackupPage} />

            <AppUserRoute path={Routes.FSP} component={FSPPage} />
            <AppUserRoute path={Routes.HRM} component={HRMCalculatorPage} />
            <AppUserRoute path={Routes.RiesterProfiCheck} component={RiesterProfiCheckPage} />
            <AppUserRoute path={Routes.RuerupProfiCheck.Overview} component={RuerupProfiCheckPage} />

            <AppUserRoute path={Routes.RuerupProfiCheck.Contracts.Overview} component={RuerupContractsOverviewPage} />
            <AppUserRoute path={Routes.RuerupProfiCheck.Contracts.Compare} component={RuerupCompareContractsPage} />
            <AppUserRoute path={Routes.RuerupProfiCheck.Contracts.DetailView + '/:contractId'} component={RuerupInsuranceContractDetailViewPage} />

            <AppUserRoute path={Routes.ProfiChecksPage} component={ProfiChecksPage} />
            <AppUserRoute path={Routes.VideoPage} component={VideoPage} />
            <AppUserRoute path={Routes.Customer.Master} component={CustomersOverviewPage} />
            <AppUserRoute path={Routes.Customer.Create} component={CreateCustomerPage} />
            <AppUserRoute path={Routes.Customer.Edit} component={EditCustomerPage} />
            <AppUserRoute path={Routes.Consultation.Questions} component={QuestionsPage} />
            <AppUserRoute path={Routes.Consultation.Result} component={ResultPage} />
            <AppUserRoute path={Routes.Consultation.AddContracts} component={AddContractsPage} />
            <AppUserRoute path={Routes.Contracts.Compare} component={CompareContractsPage} />
            <AppUserRoute path={Routes.Contracts.Overview} component={ContractsOverviewPage} />
            <AppUserRoute path={Routes.Contracts.DetailView + '/:contractId'} component={InsuranceContractDetailViewPage} />
            <AppUserRoute path={Routes.ToolsRiester} component={ToolsPageRiester} />
            <AppUserRoute path={Routes.Tools} component={ToolsPage} />

            <AppUserRoute path={Routes.SolvencyII.Overview} component={SolvencyIIPage} />
            <AppUserRoute path={Routes.SolvencyII.Clients} component={SolvencyIIClientPage} />
            <AppUserRoute path={Routes.SolvencyII.Expert} component={SolvencyIIExpertPage} />
            

            <Route path="/app" render={() => <Redirect to={Routes.Home} />} />
            <Route path="/app/*" render={() => <Redirect to={Routes.Home} />} />

            <AppUserRoute path={Routes.RuerupProfiCheckiOS.Contracts.Overview} component={RuerupContractsOverviewPage} />
            <AppUserRoute path={Routes.RuerupProfiCheckiOS.Contracts.Compare} component={RuerupCompareContractsPage} />
            <AppUserRoute path={Routes.RuerupProfiCheckiOS.Contracts.DetailView + '/:contractId'} component={RuerupInsuranceContractDetailViewPage} />
        </Switch>
    );
};

export default AppRouteSwitch;
