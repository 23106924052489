import * as React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
import DrawerScaffold from '../../app/components/scaffold/DrawerScaffold';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import {
    CalendarViewDayOutlined,
    SystemUpdate,
    PeopleAltOutlined,
    ListAltOutlined,
    VideoLibraryOutlined,
    ShoppingCartOutlined, ExploreOutlined, PermDeviceInformation
} from '@material-ui/icons';
import { WebShopUrl } from 'config';
import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import { useAppDispatch } from 'app/store';
import { ContractsActions } from 'features/ruerupProfiCheck/insurance_contracts/contractsSlice';
import HomePageButton from 'features/home/HomePageButton';
import HomePageButtonRed from 'features/home/HomePageButtonRed';
// import CustomTooltip from 'features/home/DisabledHomePageButton';
// import Typography from '@material-ui/core/Typography';
import DisabledHomePageButton from 'features/home/DisabledHomePageButton';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { InfoOutlined } from '@material-ui/icons';

const HomePage: React.FC<WithAppVersionProps> = (props) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    return (
        <DrawerScaffold
            title={'Rürup-Profi-Check'}
            actions={
                <>
                </>
            }
            drawerContent={<MainDrawerContent />}
        >
            <Grid container spacing={3}>
                {!props.isUptoDate &&
                    <Grid item xs={12} md={4}>
                        <HomePageButton
                            red={true} icon={SystemUpdate} text={'Update benötigt!'}
                            onItemClicked={() => {
                                window.open(window.location.pathname, '_self');
                            }} />
                    </Grid>
                }
                <Grid item xs={12} md={4}>
                    <DisabledHomePageButton subtitle='In Vorbereitung' icon={
                        <SvgIcon component={PeopleAltOutlined} style={{ fontSize: 40 }} />} text={'Kunden-Beratung'}
                        tooltipContent={'Ihre Umsatz-Chance 2022!\nAuch für den Rürup-Profi-Check ist die Funktion der Kunden-Beratung in Vorbereitung. Bis dahin nutzen Sie bitte die umfangreiche Tarifdatenbank in der Tarifübersicht.'} />
                    {/* <HomePageButton icon={PeopleAltOutlined} text={'Kunden-Beratung'}
                        onItemClicked={() => history.push(Routes.Customer.Master)} /> */}
                </Grid>
                <Grid item style={{ marginLeft: '-70px', marginTop: '5px' }}>
                    <PopupState variant={'popover'} popupId={'popup'}>
                        {(popupState) => (
                            <div>
                                <IconButton{...bindTrigger(popupState)}>
                                    <SvgIcon component={InfoOutlined} style={{ fontSize: 18, }}
                                        color={'primary'} />
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div style={{ padding: 8, maxWidth: 400 }}>
                                        <p>Ihre Umsatz-Chance 2022!<br />
                                            Auch für den Rürup-Profi-Check ist die Funktion der Kunden-Beratung in Vorbereitung. Bis dahin nutzen Sie bitte die umfangreiche Tarifdatenbank in der Tarifübersicht.</p>
                                    </div>
                                </Popover>
                            </div>
                        )}
                    </PopupState>
                </Grid>
                <Grid item xs={12} md={4}>
                    {/* <DisabledHomePageButton icon={
                        <SvgIcon component={ListAltOutlined} style={{ fontSize: 40 }} />} text={'Tarifübersicht'}
                        tooltipContent={'Ihre Umsatzsatz-Chance 2022!\nMit dem Rürup-Profi-Check werden Sie voraussichtlich noch im März 2022 (in der 1. Version mit der Tarifdatenbank) rechnen können.'} />
 */}                    <HomePageButtonRed icon={ListAltOutlined} text={'Tarifübersicht'} subtitle={'nur zur internen Verwendung'}
                        onItemClicked={() => {
                            dispatch(ContractsActions.setFilter({ new: true, old: false }));
                            history.push(Routes.RuerupProfiCheck.Contracts.Overview);
                        }} />
                </Grid>

                <Grid item xs={12} md={4}>
                    <HomePageButton icon={CalendarViewDayOutlined} text={'Solvency II'}
                        onItemClicked={() => history.push(Routes.SolvencyII.Overview)} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DisabledHomePageButton icon={
                        <SvgIcon component={VideoLibraryOutlined} style={{ fontSize: 40 }} />} text={'Videos'}
                        tooltipContent={'Hier finden Sie demnächst Videos zu diesem Thema.'} />
                    {/* <HomePageButton icon={VideoLibraryOutlined} text={'Videos'}
                                    onItemClicked={() => history.push(Routes.VideoPage)}/> */}
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={ShoppingCartOutlined} text={'Shop'} onItemClicked={() => {
                        var win = window.open(WebShopUrl, '_blank');
                        win?.focus();
                    }} />
                </Grid>
                <Grid item xs={12} md={4} style={{ paddingRight: '15px' }}>
                    <HomePageButton disabled icon={ExploreOutlined} text={'Beratungs-Tools'}
                        subtitle={'Bald verfügbar'}
                        onItemClicked={() => {/* history.push(Routes.Tools) */ }}
                    />
                </Grid>
                {/* 
                <Grid item xs={12} md={4}>
                    <DisabledHomePageButton icon={
                            <SvgIcon component={PermDeviceInformation} style={{ fontSize: 40}}/>} text={'Vorsorge-Wiki'}
                                                tooltipContent={'Hier entsteht ein umfassendes Nachschlagewerk zum Thema Altersvorsorge'}/>
                </Grid>
                */}
                <Grid item xs={12} md={4} style={{ paddingLeft: '5px', paddingRight: '20px' }}>
                    <DisabledHomePageButton icon={
                        <SvgIcon component={ListAltOutlined} style={{ fontSize: 40 }} />} text={'Vorsorge-Wiki'}
                        tooltipContent={'Hier entsteht ein  umfassendes Nachschlagewerk zum Thema Altersvorsorge'} />
                    {/* <HomePageButton disabled icon={PermDeviceInformation} text={'Vorsorge-Wiki'} subtitle={'In Planung'}
                        onItemClicked={() => { }} /> */}
                </Grid>
                <Grid item style={{ marginLeft: '-70px', marginTop: '5px' }}>
                    <PopupState variant={'popover'} popupId={'popup'}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)}>
                                    <SvgIcon component={InfoOutlined} style={{ fontSize: 18, }}
                                        color={'primary'} />
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div style={{ padding: 8, maxWidth: 340 }}>
                                        <p>Hier entsteht ein  umfassendes Nachschlagewerk zum Thema Altersvorsorge</p>
                                    </div>
                                </Popover>
                            </div>
                        )}
                    </PopupState>
                </Grid>
            </Grid>
        </DrawerScaffold>
    );
};

export default withAppVersion(HomePage);
