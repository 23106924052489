import React, { ReactElement, ReactNode, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import IconPDF from '@material-ui/icons/PictureAsPdf';

export interface PDFButtonProps {
    pdfDocument: any;
    beforeCreatePdf?: Function;
    ButtonElement?: ReactNode;
    isIOS?: boolean;
}

const CreateAndOpenPDFButton: React.FC<PDFButtonProps> = ({ pdfDocument, beforeCreatePdf, ButtonElement, isIOS }) => {

    const [isLoading, setIsLoading] = useState(false);

    const onClick = async () => {
        setIsLoading(true);
        if (beforeCreatePdf !== undefined) {
            await beforeCreatePdf();
        }
        pdf(pdfDocument).toBlob().then((blob) => {
            let blobURL = URL.createObjectURL(blob);
            if (isIOS) {
                // window.location.href=blobURL;
                var link = document.createElement("a"); // Or maybe get it from the current document
                link.href = blobURL;
                link.download = "ruerup_profi_check_export.pdf";
                link.innerHTML = "export.pdf";
                link.click();
                // document.body.appendChild(link); // Or append it whereever you want
                // if(document){
                //     document.querySelector('a').click()
                // }
            } else {
                window.open(blobURL);
            }

            setIsLoading(false);
        });

    };

    if (ButtonElement !== undefined) {
        return <div onClick={onClick}>{ButtonElement}</div>
    }

    return (
        <Tooltip title="PDF erzeugen">
            <IconButton onClick={onClick}>
                {isLoading && <CircularProgress style={{
                    position: 'absolute',
                    top: 4, left: 4, right: 0, bottom: 0

                }} />}
                <IconPDF style={{
                    position: 'relative',
                    top: 0, left: 0, right: 0, bottom: 0

                }} />
            </IconButton>
        </Tooltip>
    );
};

export default CreateAndOpenPDFButton;
