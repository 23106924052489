import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AppUserRoute from 'app/routes/AppUserRoute';
import LoginPage from 'features/app_user/auth/login/LoginPage';
import App from '../App';
import Routes from 'app/routes/Routes';
import ResetPasswordPage from 'features/app_user/auth/reset_password/ResetPasswordPage';
import { useAppDispatch } from 'app/store';
import { fetchLatestVersion, getAppVersionState } from 'features/appVersion/appVersionSlice';
import { useSelector } from 'react-redux';
import { watchUserData } from 'features/app_user/appUserSlice';
import { pullProfileChangesDuration } from 'config';

const PublicRouter: React.FC = () => {

    const appVersionState = useSelector(getAppVersionState);

    const dispatch = useAppDispatch();
    const shouldFetchLatestVersion = new Date().getTime() - appVersionState.updatedAt.getTime() > pullProfileChangesDuration;

    if (shouldFetchLatestVersion) {
        dispatch(fetchLatestVersion());
    }

    dispatch(watchUserData());

    return (
        <Router>
            <Switch>
                <Route path={Routes.Auth.Login} component={LoginPage}/>
                <Route path={Routes.Auth.ResetPassword} component={ResetPasswordPage}/>
                { // <Route path={Routes.Auth.Register} component={EmailRegistrationWelcomePage} />
                }
                <AppUserRoute path="/app" component={App}/>
                <Route path="/ios" component={App}/>
                <Route
                    path="*"
                    render={() => {
                        return <Redirect to={Routes.Auth.Login}/>;
                    }}
                />
            </Switch>
        </Router>
    );
};

export default PublicRouter;
