import React from 'react';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { Button, DialogContent, Grid, Icon } from '@material-ui/core';
import MalePlaceholder from 'assets/avatar_man.png';

export interface IPropsSimpleReactCropper {
    aspectRatio: number;
    width?: number;
    height?: number;
    fillColor?: string;
    upload: (file: Blob) => void;
    afterCrop?: () => void;
    containerStyle?: React.CSSProperties;
    handleClose: () => void;
}

class SimpleReactCropper extends React.Component<IPropsSimpleReactCropper, { src: any }> {
    private cropper: any;
    private img: any;

    state = {
        src: localStorage.getItem('profilePicture') ?? MalePlaceholder
    };

    componentDidMount() {
        this.cropper = new Cropper(this.img, {
            viewMode: 1,
            aspectRatio: this.props.aspectRatio,
            zoomable: false
        });
    }

    _uploadToServer = () => {
        this.cropper
            .getCroppedCanvas({
                width: this.props.width || 500,
                height: this.props.height || 500,
                minWidth: 0,
                minHeight: 0,
                maxWidth: 1024,
                maxHeight: 1024,
                fillColor: this.props.fillColor || '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'
            })
            .toBlob((blob: Blob) => {
                this.props.upload(blob);
            });
        if (this.props.afterCrop) this.props.afterCrop();
    };

    _handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const {
            target: { validity, files }
        } = e;

        if (validity.valid && files) {
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({ src: reader.result });
                this.cropper.reset().clear().replace(reader.result);
            };
            reader.readAsDataURL(files[0]);
        }
    };

    render() {
        return (
            <div className="container_simple-react-cropper">
                <div className="container-img_simple-react-cropper" style={this.props.containerStyle}>
                    <img ref={(node) => (this.img = node)} id="avatar_img" src={this.state.src} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: '1rem'
                    }}
                >
                    <Grid style={{ padding: 12 }} container direction={'row'} alignItems={'flex-end'} justify={'flex-end'} spacing={1}>
                        <Grid item>
                            <Button endIcon={<Icon>clear</Icon>} onClick={this.props.handleClose}>
                                Abbrechen
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" component="label" color={'primary'}>
                                Bild importieren
                                <input type="file" id="origin-image" name="origin-image" style={{ display: 'none' }} onChange={this._handleFileChange} />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={'contained'} color={'primary'} onClick={this._uploadToServer}>
                                Speichern
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default SimpleReactCropper;
