import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, Button, Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { RootState, useAppDispatch } from 'app/store';
import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { useSelector } from 'react-redux';
import { SavedConsultationActions } from 'features/riesterProfiCheck/consultation/saved_consultations/savedConsultationsSlice';
import { SnackbarActions } from 'features/snackbar/snackbarSlice';
import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';

interface Props {
    open: boolean;
    onDialogClose: Function;
}

const SaveConsultationDialog: React.FC<Props> = ({ open, onDialogClose }) => {
    const dispatch = useAppDispatch();
    const { handleSubmit, /* errors */ control, formState } = useForm({
        defaultValues: { title: '' },
        mode: 'onChange'
    });

    const consultation: Consultation = useSelector((state: RootState) => state.currentConsultation) as Consultation;

    const onSubmit = handleSubmit(async ({ title }) => {
        dispatch(ConsultationActions.setTitle(title));
        dispatch(SavedConsultationActions.saveConsultation({ consultation, title }));
        onDialogClose();
        dispatch(SnackbarActions.show('Beratung erfolgreich gespeichert!'));
        await new Promise((r) => setTimeout(r, 5000));
        dispatch(SnackbarActions.hide());
    }); // firstName and lastName will have correct type

    return (
        <Dialog
            open={open}
            onClose={() => {
                onDialogClose();
            }}
        >
            <DialogTitle id="save-dialog-title">Beratungsergebnis speichern</DialogTitle>
            <DialogContent>
                <DialogContentText>Wenn Sie Die Beratung ohne speichern beenden, gehen alle Ergebnisse verloren.</DialogContentText>

                <form noValidate onSubmit={onSubmit}>
                    <Controller
                        as={<TextField variant={'outlined'} margin="dense" id="name" label="Bezeichnung" type="text" fullWidth />}
                        name="title"
                        control={control}
                        rules={{ required: true }}
                    />

                    <Grid style={{ margin: '24px 0px' }} container direction={'row'} justify={'space-between'}>
                        <Button disabled={!formState.isValid} type="submit" variant={'contained'} color={'primary'}>
                            Speichern
                        </Button>
                        <Button onClick={() => onDialogClose()}>Nicht Speichern</Button>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default SaveConsultationDialog;
