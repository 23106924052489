import { createMuiTheme } from '@material-ui/core/styles';
import { PrimaryColor } from 'app/style/AppColors';

export default createMuiTheme({
    palette: {
        primary: {
            main: PrimaryColor,
            dark: '#8e7433',
            light: '#b29040'
        },
        secondary: { main: '#A7893D' }
    }
});
