import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Container } from '@material-ui/core';
import { ScaffoldStyles } from './ScaffoldStyles';

export interface ScaffoldProps {
    title: string;
    actions: React.ReactNode;
    fullWidth?: boolean;
}

interface DrawerScaffoldProps extends ScaffoldProps {
    drawerContent: React.ReactNode;
}

const DrawerScaffold: React.FC<DrawerScaffoldProps> = ({ title, actions, drawerContent, children, fullWidth = false }) => {
    const classes = ScaffoldStyles();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />

            <AppBar color={'default'} position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>

                    <div className={classes.grow}>
                        <h3>{title}</h3>
                    </div>
                    {actions}
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                {drawerContent}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.drawerHeader} />
                <Container maxWidth={fullWidth ? 'xl' : 'lg'}>{children}</Container>
            </main>
        </div>
    );
};

export default DrawerScaffold;
