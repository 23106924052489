import * as React from 'react';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import { Button, createStyles, makeStyles, SvgIcon, TextField, withStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import CustomTooltip from 'app/components/CustomTooltip';

import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

enum CalculationMethod {
    brutto,
    netto
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outlineBorder: {
            border: theme.palette.primary.main,
            borderStyle: 'solid',
            color: theme.palette.primary.main,
            fontSize: 18,
            fontWeight: 'bold',
            maxWidth: 500

        }, blackBorder: {
            border: 'black',
            borderStyle: 'solid',
            padding: 12,
            fontSize: 18,
            fontWeight: 'bold'
        },
        input: {
            padding: 7,
            maxWidth: 90,
            MozBoxShadow: 'inset 0 0 7px #848484',
            WebkitBoxShadow: 'inset 0 0 7px #848484',
            boxShadow: 'inset 0 0 7px #848484',
            backgroundColor: '#fcfcfc',
            borderRadius: 8
        },
        hoverCursor: {
            '&:hover': {
                cursor: 'help'
            },
        }
    })
);

interface Props {
    text: string;
    tooltipContent?: any;
}

const OutlineCellWithInfoIcon: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    return <td className={classes.outlineBorder}>
        <PopupState variant={'popover'} popupId={'popup'}>
            {(popupState) => (
                <div style={{ position: 'relative' }}  >

                    <IconButton style={{ top: '0px', right: '0px', position: 'absolute' }}  {...bindTrigger(popupState)}>
                        <SvgIcon component={InfoOutlined} style={{ fontSize: 18, }}
                            color={'primary'} />
                    </IconButton>
                    <div style={{ padding: 16, paddingRight: 48 }}>{props.text}</div>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div style={{ padding: 8, maxWidth: 400 }}>
                            {props.tooltipContent}
                        </div>
                    </Popover>
                </div>
            )}
        </PopupState>
    </td>;
};


const HRMCalculatorPage: React.FC = (props) => {

    const classes = useStyles();

    const [fondsCalculationMethod, setFondsCalculationMethod] = useState(CalculationMethod.netto);

    const [assumedPerformance, setAssumedPerformance] = useState<string>(''); // 6,00
    const [fondsCost, setFondsCost] = useState<string>('2,00'); // 1,50

    const [assumedPerformanceCalc, setAssumedPerformanceCalc] = useState<string>('');

    const onAssumedPerformanceChanged = (e: any) => {
        e.preventDefault();
        if (e.target.value == "") {
            setAssumedPerformance('');
        }
        setAssumedPerformanceCalc(e.target.value);
    };

    const onFondsCostChanged = (e: any) => {
        e.preventDefault();
        setFondsCost(e.target.value);
    };


    const calculateFondsPerformance = () => {
        const assumedPerformanceParsed = assumedPerformance === '' ? 0 : parseFloat(assumedPerformance.replace(',', '.'));
        const fondsCostParsed = fondsCalculationMethod === CalculationMethod.netto ? 0 : fondsCost === '' ? 0 : parseFloat(fondsCost.replace(',', '.'));
        return (assumedPerformanceParsed > 0)
            ? ((fondsCalculationMethod === CalculationMethod.netto
                ? assumedPerformanceParsed
                : assumedPerformanceParsed + fondsCostParsed).toFixed(2).replace('.', ',') + ' %')
            : '%'

    }



    const calculateTotalPerformance = () => {
        const assumedPerformanceParsed = assumedPerformance === '' ? 0 : parseFloat(assumedPerformance.replace(',', '.'));
        return assumedPerformanceParsed > 0 ? (assumedPerformanceParsed * 2 - 1).toFixed(2).replace('.', ',') + ' %' : '%'
    }

    return (
        <NavigateBackScaffold
            title={'Vergleich der Hochrechnungsmethoden'}
            actions={
                <>
                </>
            }
        >


            <PopupState variant={'popover'} popupId={'popup'}>
                {(popupState) => (
                    <div style={{ marginTop: 16 }}>
                        <IconButton {...bindTrigger(popupState)}>
                            <SvgIcon component={InfoOutlined} style={{ fontSize: 40 }}
                                color={'primary'} />
                        </IconButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <div style={{ padding: 8, maxWidth: 400 }}>
                                <p>
                                    Diesem Rechner liegen bestimmte Annahmen zugrunde.
                                    Welche sind dies und warum ist das so?
                                    <br />
                                    <br />
                                    Bei einer Fondspolice ist das Gesamtkapital während der Ansparphase niemals in einem gleich
                                    bleibenden Verhältnis zwischen Garantiekapital und Fondskapital. In den Anfangsjahren wird
                                    aufgrund der zu erfüllenden Beitragsgarantie sich der größte Teil des Kapital im Garantiekapital
                                    befinden und erst gegen Ende der Laufzeit wird das Investmentkapital überwiegen. Dabei müssen
                                    wir zudem die unterschiedlichen Laufzeiten berücksichtigen. Bei kurzen Laufzeiten wird ggf. auf
                                    Dauer das meiste Kapital im Sicherungsvermögen liegen müssen. Bei sehr langen Laufzeiten wird
                                    das Investmentkapital dagegen ggf. deutlich überwiegen.
                                    <br />
                                    <br />
                                    Mit diesem Rechner wollen wir eine durchschnittliche Laufzeit von ca. 25-30 Jahren abbilden und
                                    nehmen deshalb beispielhaft eine durchschnittliche Verteilung des Gesamtkapitals je zur Hälfte im
                                    Garantiekapital und im Fondskapital an.
                                </p>
                            </div>
                        </Popover>
                    </div>
                )}
            </PopupState>



            <table style={{ borderSpacing: '1px 1em' }}>

                <tbody>
                    <tr>
                        <td></td>
                        <td style={{ width: 24 }}></td>
                        <OutlineCellWithInfoIcon text={'Wertentwicklung bezogen auf die Gesamtanlage'} tooltipContent={

                            <p>Wertentwicklung bezogen auf die Gesamtanlage:
                                <br />
                                <br />
                                Aktuell wenden bereits mehr als 50% aller Anbieter die Hochrechnungsmethode an, in welcher die
                                angenommene Wertentwicklung auf die Gesamtanlage angewendet wird.
                                <br />
                                <br />
                                In der Angebotsberechnung schreibt die <b>Allianz</b>:
                                „Die Gesamtleistungen hängen in Wesentlichen von der Wertentwicklung der <b>Gesamtanlage</b> ab.
                                Die Gesamtanlage erfolgt in den von Ihnen gewählten <b>Fonds sowie im Sicherungskapital</b>, über
                                das die Garantieleistungen abgesichert werden.“
                                <br />
                                <br />
                                Die <b>WWK</b> schreibt hierzu:
                                „Um Ihnen einen Eindruck zu vermitteln, wie sich die zukünftigen Gesamtleistungen einschließlich
                                der Überschussbeteiligung entwickeln können, wird in der nachfolgenden unverbindlichen
                                Beispielrechnung eine Wertentwicklung des <b>Gesamtguthabens (Fonds- und konventionelles
                                    Guthaben)</b> von 0%, 3%, 6% und 9% angenommen.</p>

                        } />
                        <td style={{ width: 24 }}></td>
                        <OutlineCellWithInfoIcon text={'Wertentwicklung bezogen auf die Fondsanlage'} tooltipContent={


                            <p>Wertentwicklung bezogen auf die Fondsanlage:
                                <br />
                                <br />
                                Bei dieser Hochrechnungsmethode wird die im Angebot angenommene Wertentwicklung nur für
                                das Fondsvermögen angewendet.
                                <br />
                                <br />
                                Im Antrag Seite 2 schreibt die <b>Generali</b>:
                                „Falls die Beiträge entsprechend des in der Anlage angegebenen Verlaufs gezahlt werden,
                                ergeben sich bei einer konstant jährlichen Wertsteigerung der Fondsanteile folgende modellhaft
                                dargestellte, nicht garantierte, Leistungen: Angenommene jährl. Wertsteigerung der <b>Fondsanteile</b>
                                von 1%, 4%, 6% und 8%.“</p>
                        } />
                    </tr>

                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <Grid container justify={'center'} alignItems={'center'}>
                                <Button variant={'contained'} color={'primary'}>
                                    Brutto
                                </Button>
                            </Grid>
                        </td>
                        <td></td>
                        <td>
                            <Grid container justify={'space-around'} alignItems={'center'}>
                                <Button
                                    variant={fondsCalculationMethod === CalculationMethod.brutto ? 'contained' : 'outlined'}
                                    color={'primary'} style={{ marginRight: 48 }}
                                    onClick={() => setFondsCalculationMethod(CalculationMethod.brutto)}>
                                    Brutto
                                </Button>
                                <Button
                                    variant={fondsCalculationMethod === CalculationMethod.netto ? 'contained' : 'outlined'}
                                    color={'primary'} onClick={() => setFondsCalculationMethod(CalculationMethod.netto)}>
                                    Netto
                                </Button>


                                <PopupState variant={'popover'} popupId={'popup'}>
                                    {(popupState) => (
                                        <div>
                                            <IconButton{...bindTrigger(popupState)}>
                                                <SvgIcon component={InfoOutlined} style={{ fontSize: 18, }}
                                                    color={'primary'} />
                                            </IconButton>
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <div style={{ padding: 8, maxWidth: 400 }}>
                                                    <p>Bei der Anwendung der <b>Bruttomethode</b> werden zusätzlich zu den internen Kosten der Police
                                                        auch die externen Kosten der Fondsgesellschaft in Abzug gebracht.
                                                        <br />
                                                        <br />
                                                        Bei der Anwendung der <b>Nettomethode</b> bleiben die Fondskosten in der Angebotsberechnung
                                                        unberücksichtigt.
                                                        <br />
                                                        <br />
                                                        Bei der Hochrechnungsmethode <b>„Wertentwicklung bezogen auf die Gesamtanlage“</b> findet
                                                        bisher ausschließlich die <b>Bruttomethode</b> Anwendung.
                                                        <br />
                                                        <br />
                                                        Bei der Hochrechnungsmethode <b>„Wertentwicklung bezogen auf die Fondsanlage“</b> fand lange
                                                        Zeit nur die <b>Nettomethode</b> Anwendung. Seit einigen Jahren wird diese Hochrechnung auch
                                                        zusätzlich oder wahlweise mit der <b>Bruttomethode</b> dargestellt.
                                                        <br />
                                                        <br />
                                                        Die <b>Generali</b> wendet die Nettomethode mit getrennter Hochrechnung des Garantiekapitals und
                                                        des Fondskapitals an. </p>
                                                </div>
                                            </Popover>
                                        </div>
                                    )}
                                </PopupState>
                            </Grid>
                        </td>
                    </tr>

                    <tr>
                        <OutlineCellWithInfoIcon
                            text={'Angenommener, notwendiger Ertrag des Garantiekapitals für die Beitragsgarantie:'}

                            tooltipContent={<p>
                                Angenommener, notwendiger Ertrag des Garantiekapitals für die Beitragsgarantie:
                                <br />
                                <br />
                                Die Anbieter benötigen durchschnittlich je nach Kostenstruktur und Vertragslaufzeit in etwa den
                                aktuellen Garantiezins von 0,9 % um die Garantie der Beiträge und Zulagen zu Rentenbeginn
                                garantieren zu können. Der Rest, der nicht für die Beitragsgarantie benötigt wird, geht somit in die
                                Fondsanlage.
                                <br />
                                <br />
                                So kann ganz grob gesagt werden, dass ein Anbieter der ca. 2 % Gesamtertrag erwirtschaftet
                                davon ca. 1% in die bessere Fondsanlage investieren kann.
                            </p>}


                        />
                        <td></td>
                        <td colSpan={3} className={classes.blackBorder}>
                            <Grid container style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}
                                alignItems={'center'} justify={'center'}>
                                <Grid item>1,00 %</Grid>
                            </Grid>
                        </td>
                    </tr>

                    <tr>
                        <OutlineCellWithInfoIcon text={'Im Angebot angenommene Wertentwicklung:'} tooltipContent={
                            <p>Im Angebot angenommene Wertentwicklung:
                                <br />
                                <br />
                                Hier wird die angenommene prozentuale Wertentwicklung aus den Angeboten eingetragen.</p>
                        } />
                        <td></td>
                        <td colSpan={3} className={classes.blackBorder} style={{ marginRight: 24 }}>
                            <Grid container style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}
                                alignItems={'center'} justify={'center'}>
                                <div style={{ width: '160px' }} />
                                <Grid container direction={'row'} alignItems={'center'}
                                    justify={'center'} className={classes.input}>
                                    <Grid item>
                                        <input type={'text'}
                                            value={assumedPerformanceCalc}
                                            onClick={() => { setAssumedPerformanceCalc(''); setAssumedPerformance('') }}
                                            onKeyPress={(e) => {if(e.key === 'Enter'){setAssumedPerformance(assumedPerformanceCalc)}}}
                                            onChange={onAssumedPerformanceChanged}
                                            style={{
                                                border: 'none',
                                                borderBottom: 'none',
                                                maxWidth: 42,
                                                textAlign: 'right',
                                                height: 36,
                                                marginRight: 12,
                                                backgroundColor: '#fcfcfc', // f2f2f2
                                                outline: 'none',
                                                fontSize: 18,
                                                color: 'green',
                                                fontWeight: 'bold'
                                            }} maxLength={4} />

                                    </Grid>
                                    <Grid item style={{ fontWeight: 'bold' }}>%</Grid>

                                </Grid>
                                {
                                    <Button
                                        variant={'contained'}
                                        color={'primary'} style={{
                                            transition: 'opacity .3s ease-in-out',
                                            marginLeft: 48, width: '130px', opacity: (assumedPerformance == '')
                                                ? 1 : 0
                                        }}
                                        onClick={() => setAssumedPerformance(assumedPerformanceCalc)}>
                                        Berechnen
                                    </Button>
                                }

                            </Grid>
                        </td>
                    </tr>


                    {(fondsCalculationMethod === CalculationMethod.brutto) &&
                        (<tr>
                            <OutlineCellWithInfoIcon text={'Fondskosten:'} tooltipContent={<p>
                                Fondskosten:
                                <br />
                                <br />
                                Hier werden die tatsächlich laufenden Fondskosten des ausgewählten Fonds eingetragen.
                            </p>} />
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className={classes.blackBorder}>
                                <Grid container style={{ color: 'red', fontWeight: 'bold', fontSize: 18 }}
                                    alignItems={'center'} justify={'center'}>
                                    <Grid container direction={'row'} alignItems={'center'}
                                        justify={'center'} className={classes.input}>
                                        <Grid item>
                                            <input type={'text'}
                                                value={fondsCost}
                                                onChange={onFondsCostChanged}
                                                onClick={() => setFondsCost('')}
                                                style={{
                                                    border: 'none',
                                                    borderBottom: 'none',
                                                    maxWidth: 42,
                                                    textAlign: 'center',
                                                    height: 36,
                                                    marginRight: 12,
                                                    backgroundColor: '#f2f2f2',
                                                    outline: 'none',
                                                    fontSize: 18,
                                                    color: 'red',
                                                    fontWeight: 'bold'
                                                }} maxLength={4} />
                                        </Grid>
                                        <Grid item style={{ fontWeight: 'bold' }}>%</Grid>
                                    </Grid>
                                </Grid>
                            </td>
                        </tr>)

                    }

                    <tr>
                        <OutlineCellWithInfoIcon text={'Notwendige Entwicklung der Fondsanlage:'}
                            tooltipContent={<p>
                                Notwendige Entwicklung der Fondsanlage:
                                <br />
                                <br />
                                Die hier errechnete Fondsentwicklung wäre notwendig, damit bei einem Anbieter, welcher die
                                <b>„Wertentwicklung bezogen auf die Gesamtanlage“</b> anwendet, dessen hochgerechneten
                                Ablaufleistungen im Angebot stimmen.
                                <br />
                                <br />
                                Beispiel:
                                <br />
                                <br />
                                Ein Anbieter, der für die Hochrechnung die <b>„Wertentwicklung bezogen auf die Gesamtanlage“</b>
                                nach <b>Bruttomethode</b> anwendet, dessen Fonds muss (bei hälftiger Verteilung des Guthabens im
                                Garantiekapital und im Fondskapital) statt der angenommenen 6 % tatsächlich sogar ca. 11 %
                                <b>Bruttowertentwicklung</b> erreichen, damit das hochgerechnete Ergebnis stimmt.
                                <br />
                                <br />
                                Dem gegenüber stimmen die 6 % in der Angebotsberechnung der <b>Generali</b>, da diese nur das
                                Fondskapital mit 6 % <b>Wertentwicklung</b> hochrechnet. Weil die Generali jedoch die <b>Nettomethode</b>
                                (also ohne Berücksichtigung der Fondskosten) anwendet, kann fairerweise auf die Bruttomethode
                                umgestellt werden. Nun gibt man die aktuell ca. 2 % Fondskosten unseres Fonds ein und das
                                Ergebnis zeigt an, dass unser Fonds eine <b>Bruttowertentwicklung</b> von 8 % bringen müsste damit
                                das hochgerechnete Ergebnis mit 6 % stimmt.
                            </p>} />
                        <td></td>
                        <td className={classes.blackBorder} >
                            <Grid container style={{ color: 'red', fontWeight: 'bold', fontSize: 18 }}
                                alignItems={'center'} justify={'center'}>
                                <Grid container direction={'row'} alignItems={'center'}
                                    justify={'center'}>
                                    {(assumedPerformance != '') ?
                                        <div style={{
                                            textAlign: 'center',

                                            fontSize: 18,
                                            color: 'red',
                                            fontWeight: 'bold'
                                        }}>{calculateTotalPerformance()}
                                        </div> : ''}
                                </Grid>
                            </Grid>
                        </td>
                        <td ></td>
                        <td className={classes.blackBorder}>
                            <Grid container style={{ color: 'red', fontWeight: 'bold', fontSize: 18 }}
                                alignItems={'center'} justify={'center'}>
                                <Grid container direction={'row'} alignItems={'center'}
                                    justify={'center'}>
                                    {(assumedPerformance != '') ?
                                        <div style={{
                                            textAlign: 'center',

                                            fontSize: 18,
                                            color: 'blue',
                                            fontWeight: 'bold'
                                        }}>{

                                                calculateFondsPerformance()
                                            }
                                        </div> : ''}
                                </Grid>
                            </Grid>
                        </td>
                    </tr>


                </tbody>
            </table>


        </NavigateBackScaffold>
    );
};

export default HRMCalculatorPage;
