import * as React from 'react';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import HomePageButton from 'features/home/HomePageButton';

import IconTutorials from '@material-ui/icons/PermDeviceInformation';

import { Grid } from '@material-ui/core';

const VideoPage: React.FC = () => {
    return (
        <NavigateBackScaffold title={'Videos'} actions={<></>}>
            <Grid container direction={'row'} spacing={3}>
                <Grid item xs={12} md={4}>
                    <a style={{ textDecoration: 'none' }} href={'https://www.youtube.com/playlist?list=PLeKxmeZIDRcVa1a8iyeyyPq3f9v7-r-nF'} target="_blank">
                        <HomePageButton icon={IconTutorials} text={'Erklärvideos'} onItemClicked={() => {}} />
                    </a>
                </Grid>
                <Grid item xs={12} md={4}>
                    <a style={{ textDecoration: 'none' }} href={'https://www.youtube.com/playlist?list=PLeKxmeZIDRcX2-d_G6fgDVuWfxEiAw5Fz'} target="_blank">
                        <HomePageButton icon={IconTutorials} text={'Riester Profi-Seminar mit Walter Riester'} onItemClicked={() => {}} />
                    </a>
                </Grid>
            </Grid>
        </NavigateBackScaffold>
    );
};

export default VideoPage;
