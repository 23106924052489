import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import InsuranceContractModel from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractModel';
import { Center, PDFStyles, Row, Section } from 'core/pdf/CustomPdfComponents';

import { getPropsAsArray } from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { grey } from '@material-ui/core/colors';

const PdfContractDetailView = (props: { contract: InsuranceContractModel; showEffektivkosten: boolean; showAnbieterWechsel: boolean }) => {
    const contractProps = getPropsAsArray(props.contract, props.showEffektivkosten, props.showAnbieterWechsel);
    return (
        <Document>
            <Page size={'A4'} orientation={'landscape'} style={{ ...PDFStyles.page, paddingRight: 40, }}>
                <View style={{padding: 10}}>
                    <Center>
                        <Text style={{fontSize: 24, color:  grey[200]}}>Nur zur internen Verwendung</Text>
                    </Center>
                </View>
                <View>
                    {contractProps.map((prop, index) => {
                        if (index === 0) {
                            return (
                                <View>
                                    <Row>
                                        <View style={{ width: '20%', padding: 6, justifyContent: 'center' }}>
                                            <Text style={{ fontWeight: 'bold' }}>{prop.header.title}</Text>
                                        </View>
                                        <View style={{ width: '20%', textAlign: 'center', padding: 6, justifyContent: 'center' }}>
                                            <Text style={{ fontWeight: 'bold' }}>{prop.prop}</Text>
                                        </View>
                                        <View style={{ width: '60%', padding: 6, justifyContent: 'center' }}>
                                            <Text>Kommentar</Text>
                                        </View>
                                    </Row>
                                </View>
                            );
                        } else {
                            return (
                                <View
                                    minPresenceAhead={2}
                                    style={{
                                        borderStyle: 'solid',
                                        borderColor: '#bfbfbf',
                                        borderWidth: 0.5,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        borderTopWidth: 0.5,
                                        borderRightWidth: 0
                                    }}
                                >
                                    <Row>
                                        <View style={{ width: '20%', padding: 8, justifyContent: 'center', fontWeight: 'bold' }}>
                                            <Text>{prop.header.title}</Text>
                                        </View>
                                        <View style={{ width: '20%', padding: 8, textAlign: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                            <Text>{prop.prop}</Text>
                                        </View>
                                        <View style={{ width: '60%', padding: 8, justifyContent: 'center' }}>
                                            <Text>{prop.comment}</Text>
                                        </View>
                                    </Row>
                                </View>
                            );
                        }
                    })}
                </View>
            </Page>
        </Document>
    );
};

export default PdfContractDetailView;
