import React from 'react';

import AppRouteSwitch from './routes/AppRouteSwitch';
import DefaultSnackbar from 'features/snackbar/DefaultSnackbar';

export default function App() {
    return (
        <>
            <DefaultSnackbar />
            <AppRouteSwitch />
        </>
    );
}
