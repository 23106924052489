import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { Center, Column, PDFStyles, Row, Section } from 'core/pdf/CustomPdfComponents';
import { PrimaryColor } from 'app/style/AppColors';
import Questions from 'features/riesterProfiCheck/consultation/questions/domain/Questions';

interface Props {
    questionValues: boolean[];
}

const PdfQuestions = (props: Props) => (
    <Page size="A4" style={PDFStyles.page}>
        <Section>
            <Center>
                <Text style={{ fontSize: 18, color: PrimaryColor }}>10 Goldene Riester Fragen</Text>
            </Center>

            {Questions.map((question, index) => (
                <View style={{ marginBottom: '8px', paddingRight: '80px' }}>
                    <Row>
                        <Center>
                            <View
                                style={{
                                    width: 36,
                                    height: 36,
                                    backgroundColor: props.questionValues[index] ? PrimaryColor : '#d6d6d6',
                                    color: props.questionValues[index] ? 'white' : 'black',
                                    borderRadius: 50,
                                    marginRight: '12px'
                                }}
                            >
                                <Center>
                                    <Text>{index + 1}</Text>
                                </Center>
                            </View>
                        </Center>
                        <Column>
                            <Text style={{ fontSize: 14, fontWeight: 'bold', marginBottom: '4px' }}>{question.title}</Text>
                            <Text style={{ fontSize: 11 }}>
                                {question.content} {question.question}
                            </Text>
                        </Column>
                    </Row>
                </View>
            ))}
        </Section>
    </Page>
);

export default PdfQuestions;
