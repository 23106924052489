import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IconAccount from '@material-ui/icons/AccountBox';
import IconEdit from '@material-ui/icons/Create';
import NoCustomerSelectedView from 'features/riesterProfiCheck/customers/view/overview/components/detail/NoCustomerSelectedView';
import CustomerDataView from 'features/riesterProfiCheck/customers/view/overview/components/detail/CustomerDataView';
import CustomerTabs from 'features/riesterProfiCheck/customers/view/overview/components/detail/CustomerTabs';
import CustomerActionsView from 'features/riesterProfiCheck/customers/view/overview/components/detail/CustomerActionsView';
import { getCurrentCustomer } from 'features/riesterProfiCheck/customers/customerSlice';

import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import CustomerEditMoreMenu from 'features/riesterProfiCheck/customers/view/overview/components/detail/CustomerEditMoreMenu';

const useStyles = makeStyles((theme: Theme) => ({
    accountIcon: {
        fontSize: 40,
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2)
    },
    grow: {
        flexGrow: 1,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const CustomerDetailView: React.FC = () => {
    const classes = useStyles();

    const history = useHistory();
    const currentCustomer = useSelector(getCurrentCustomer);

    if (currentCustomer === undefined) {
        return (
            <Grid container direction={'column'} justify={'center'} alignItems={'center'} md={9} xs={12} style={{ height: '85vh' }}>
                <NoCustomerSelectedView />
            </Grid>
        );
    }

    const tab1 = {
        content: <CustomerActionsView />,
        label: 'Aktionen'
    };

    const tab2 = {
        content: <CustomerDataView customer={currentCustomer} />,
        label: 'Kontaktdaten'
    };

    return (
        <Grid item md={9} xs={12} style={{ height: '85vh' }}>
            <>
                <Paper elevation={0}>
                    <Grid container direction={'row'} alignContent={'center'} justify={'center'} style={{ padding: '24px' }}>
                        <IconAccount className={classes.accountIcon} />
                        <Typography variant={'h4'} color={'primary'}>
                            {currentCustomer.fullName}
                        </Typography>
                        <div className={classes.grow}></div>
                        <Button
                            onClick={() => {
                                history.push(Routes.Customer.Edit);
                            }}
                            color="primary"
                            startIcon={<IconEdit />}
                        >
                            Bearbeiten
                        </Button>
                        <CustomerEditMoreMenu customer={currentCustomer} />
                    </Grid>
                </Paper>
                <CustomerTabs page1={tab1} page2={tab2} />
            </>
        </Grid>
    );
};

export default CustomerDetailView;
