import * as React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
import HomePageButton from './HomePageButton';
import DrawerScaffold from '../../app/components/scaffold/DrawerScaffold';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import { PermDataSettingOutlined, ShoppingCartOutlined, SystemUpdate, WorkOutlined, WorkOutlineTwoTone } from '@material-ui/icons';


import { WebShopUrl } from 'config';
import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import DisabledHomePageButton from 'features/home/DisabledHomePageButton';

import { isBasisversion } from 'utils/appUserUtils';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';

const ProfiChecksPage: React.FC<WithAppVersionProps> = (props) => {
    const history = useHistory();
    const appUser = useSelector((state: RootState) => state.appUser.appUser!);

    return (
        <DrawerScaffold
            title={'Profi-Checks'}
            actions={
                <>
                </>
            }
            drawerContent={<MainDrawerContent />}
        >
            <Grid container spacing={3}>
                {!props.isUptoDate &&
                    <Grid item xs={12} md={4}>
                        <HomePageButton
                            red={true} icon={SystemUpdate} text={'Update benötigt!'}
                            onItemClicked={() => {
                                window.open(window.location.pathname, '_self');
                            }} />
                    </Grid>
                }
                <Grid item xs={12} md={4}>
                    {
                        (!isBasisversion(appUser))
                            ? <HomePageButton
                                icon={WorkOutlined}
                                text={'Riester-Profi-Check'}
                                onItemClicked={() => history.push(Routes.RiesterProfiCheck)} />
                            : <DisabledHomePageButton icon={
                                <SvgIcon component={WorkOutlined} style={{ fontSize: 40 }} />} text={'Riester-Profi-Check'}
                                tooltipContent={<React.Fragment>
                                    <p>In der Basisversion nicht verfügbar.</p>
                                </React.Fragment>}
                                isBasis={true}
                            />
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    {
                        (!isBasisversion(appUser))
                            ? <HomePageButton
                                icon={WorkOutlineTwoTone}
                                text={'Rürup-Profi-Check'}
                                onItemClicked={() => history.push(Routes.RuerupProfiCheck.Overview)} />
                            : <DisabledHomePageButton icon={
                                <SvgIcon component={WorkOutlineTwoTone} style={{ fontSize: 40 }} />} text={'Rürup-Profi-Check'}
                                tooltipContent={<React.Fragment>
                                    <p>In der Basisversion nicht verfügbar.</p>
                                </React.Fragment>}
                                isBasis={true}
                            />
                    }


                    {/* <DisabledHomePageButton icon={
                        <SvgIcon component={WorkOutlined} style={{ fontSize: 40}}/>} text={'Rürup-Profi-Check'}
                                            tooltipContent={<React.Fragment>
                                                <p>Nach dem Vorbild des "Riester-Profi-Check" soll noch im Laufe des Jahres 2021 ein neuer "Profi-Check" entstehen.<br/><br/>Der „Rürup-Profi-Check“ wird ein Analyse- und Beratungstool für die Basis-Rente.<br/><br/>Auch der "Rürup-Profi-Check" erhebt den Anspruch das beste Analyse- und Beratungstool im Markt für Basisrenten zu werden.</p>
                                            </React.Fragment>}/> */}
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton disabled icon={PermDataSettingOutlined} text={'Weitere in Planung'} subtitle={''}
                        onItemClicked={() => { }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={ShoppingCartOutlined} text={'Shop'} onItemClicked={() => {
                        var win = window.open(WebShopUrl, '_blank');
                        win?.focus();
                    }} />
                </Grid>
            </Grid>
        </DrawerScaffold>
    );
};

export default withAppVersion(ProfiChecksPage);
