import React from 'react';
import ReactDOM from 'react-dom';

import { persistor, store } from 'app/store';
import { Provider } from 'react-redux';
//import * as serviceWorker from './serviceWorker';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'; // <- needed if using firestore

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './app/style/theme';
// import { firebaseConfig } from 'config';
import PublicRouter from 'app/routes/PublicRouter';
import { PersistGate } from 'redux-persist/integration/react';
import { Font } from '@react-pdf/renderer';
import RobotoRegular from 'assets/roboto/Roboto-Regular.ttf';
import RobotoBold from 'assets/roboto/Roboto-Bold.ttf';

/**
 * Fonts for PDF
 */
Font.register({
    family: 'Roboto',
    fonts: [
        { src: RobotoRegular, fontWeight: 'normal' },
        { src: RobotoBold, fontWeight: 'bold' }
    ]
});

//persistor.purge();

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <PublicRouter />
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
