import * as React from 'react';
import { Button, Grid, List } from '@material-ui/core';
import Questions from 'features/riesterProfiCheck/consultation/questions/domain/Questions';
import QuestionView from 'features/riesterProfiCheck/consultation/questions/components/QuestionView';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { useState } from 'react';

const QuestionStepper: React.FC = () => {
    const questionValues: Record<number, boolean> = useSelector((state: RootState) => state.currentConsultation.questionValues);

    const [currentIndex, setCurrentIndex] = useState(0);


    const question = Questions[currentIndex];
    return (
        <Grid container direction={'column'} alignItems={'stretch'}  item xs={8}>
            <QuestionView bigView={true} key={question.title} questionValues={questionValues} question={question} index={currentIndex} />

            <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={{padding: '24pt'}}>

                {currentIndex !== 0 && <Button color={'primary'} style={{flexGrow: 1}} onClick={() => setCurrentIndex(currentIndex - 1)}>Zurück</Button>}

                {currentIndex !== 9 && <Button color={'primary'} style={{flexGrow: 1}} onClick={() => setCurrentIndex(currentIndex + 1)}>Weiter</Button>}

            </Grid>
        </Grid>
    );
};

export default QuestionStepper;
