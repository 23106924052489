import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCurrentCustomer, NormalizedObjects } from 'features/riesterProfiCheck/customers/customerSlice';
import { filter } from 'lodash';
import { RootState } from 'app/store';
import { v4 as uuidv4 } from 'uuid';
import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';

export interface SavedConsultationsState extends NormalizedObjects<Consultation> {}

const initialState: SavedConsultationsState = {
    entities: {},
    ids: []
};

export const savedConsultationsSlice = createSlice({
    name: 'savedConsultations',
    initialState: initialState,
    reducers: {
        saveConsultation: (state: SavedConsultationsState, action: PayloadAction<{ consultation: Consultation; title: string }>) => {
            const savedConsultationState: Consultation = {
                ...action.payload.consultation,
                date: new Date().getTime(),
                id: uuidv4(),
                title: action.payload.title
            };

            state.ids.push(savedConsultationState.id);
            state.entities[savedConsultationState.id] = savedConsultationState;
        },
        deleteConsultation: (state: SavedConsultationsState, action: PayloadAction<Consultation>) => {
            const consultation = action.payload;
            delete state.entities[consultation.id];
            const index = state.ids.indexOf(consultation.id);
            state.ids.splice(index, 1);
        },
        overwriteConsultations: (state: SavedConsultationsState, action: PayloadAction<SavedConsultationsState>) => {
            state.entities = action.payload.entities
            state.ids = action.payload.ids
        },
    }
});

export const getConsultations = (state: RootState) => state.savedConsultations;

export const getSavedConsultationsForCurrentUser = createSelector(
    getConsultations,
    getCurrentCustomer,
    (savedConsultations, currentCustomer) =>
        filter(savedConsultations.entities, (consultation: Consultation) => consultation.customerId === currentCustomer?.id) as Array<Consultation>
);

export const SavedConsultationActions = savedConsultationsSlice.actions;

export default savedConsultationsSlice.reducer;
