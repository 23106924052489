import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box, createStyles, Grid, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getAppUser } from 'features/app_user/appUserSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3)
        },
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            border: theme.palette.primary.main
        },
        userName: {
            paddingTop: theme.spacing(2)
        },
        suffix: {
            paddingBottom: theme.spacing(2)
        }
    })
);

const DrawerAvatar: React.FC = () => {
    const classes = useStyles();
    const appUser = useSelector(getAppUser);

    return (
        <Grid container direction={'column'} alignContent={'center'} alignItems={'center'}>
            <Box border={3} borderColor="primary.main" borderRadius="50%">
                <Avatar className={classes.large} alt="Profilbild" src={appUser.getImgSrc()} />
            </Box>
            <Typography variant={'h6'} color={'primary'} className={classes.userName}>
                {appUser.getFullName()}
            </Typography>
            <Typography variant={'body2'} className={classes.suffix}>
                {appUser.getTitle()}
            </Typography>
        </Grid>
    );
};

export default DrawerAvatar;
