import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { withRouter } from 'react-router-dom';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import { getPropsAsArray } from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { /* IconButton,  */Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
// import IconPDF from '@material-ui/icons/PictureAsPdf';
// import CompareContractsPDFButton from 'features/ruerupProfiCheck/insurance_contracts/compare/pdf/CompareContractsPDFButton';
import ContractDetailViewPDFButton from 'features/ruerupProfiCheck/insurance_contracts/detail_view/pdf/ContractDetailViewPDFButton';
// import shadows from '@material-ui/core/styles/shadows';

type Params = { contractId: string };

const InsuranceContractDetailViewPage: React.FC<RouteComponentProps<Params>> = ({ match }: RouteComponentProps<Params>) => {
    const contract = useSelector((state: RootState) => state.contractsRuerup.entities[match.params.contractId]);
    const showEffektivkosten = useSelector((state: RootState) => state.currentConsultation.showEffektivkosten);
    const showAnbieterWechsel = useSelector((state: RootState) => state.currentConsultation.showAnbieterWechsel);
    
    const history = useHistory();
    const isIOS = (history && history.location && history.location.pathname.includes('ios/'));

    const contractProps = getPropsAsArray(contract, true, true);

    return (
        <NavigateBackScaffold
            title={contract.name}
            actions={
                <>
                    <ContractDetailViewPDFButton 
                    contract={contract} 
                    showEffektivkosten={showEffektivkosten} 
                    showAnbieterWechsel={showAnbieterWechsel ?? false}
                    isIOS={isIOS}
                    />
                </>
            }
        >
            <TableContainer component={Paper} style={{ marginBottom: '24px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Anbieter (Tarif)</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">
                                {contract.name}
                            </TableCell>
                            <TableCell align="left">Kommentar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractProps.map((prop, index) => {
                            if (index === 0) {
                                return <></>;
                            } else {
                                return (
                                    <TableRow key={prop.header.title}>
                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold', width: '20%' }}>
                                            {prop.header.title}
                                        </TableCell>
                                        <TableCell style={{ width: '30%' }} align="center">
                                            {prop.prop}
                                        </TableCell>
                                        <TableCell align="left">{prop.comment}</TableCell>
                                    </TableRow>
                                );
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </NavigateBackScaffold>
    );
};

export default withRouter(InsuranceContractDetailViewPage);
