import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware, /* PayloadAction */ } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import consultationSessionReducer from 'features/riesterProfiCheck/consultation/consultationSlice';
import appUserReducer, { AppUserState } from 'features/app_user/appUserSlice';
import snackbarReducer, { SnackbarState } from 'features/snackbar/snackbarSlice';
import customerReducer, { CustomerState } from '../features/riesterProfiCheck/customers/customerSlice';
import savedConsultationsReducer, { SavedConsultationsState } from 'features/riesterProfiCheck/consultation/saved_consultations/savedConsultationsSlice';
import contractsReducer, { ContractsState } from '../features/riesterProfiCheck/insurance_contracts/contractsSlice';

import contractsReducerRuerup, { ContractsStateRuerup } from '../features/ruerupProfiCheck/insurance_contracts/contractsSlice';

import authReducer, { AuthState } from 'features/app_user/auth/authSlice';

import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';
import { PersistConfig } from 'redux-persist/es/types';
import appVersionReducer, { AppVersionState } from 'features/appVersion/appVersionSlice';

const reducers = combineReducers({
    snackbarState: snackbarReducer,
    contracts: contractsReducer,
    contractsRuerup: contractsReducerRuerup,
    currentConsultation: consultationSessionReducer,
    savedConsultations: savedConsultationsReducer,
    appUser: appUserReducer,
    auth: authReducer,
    customerStore: customerReducer,
    appVersionState: appVersionReducer
});

const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage,
    blacklist: ['contracts', 'contractsRuerup', 'appVersionState']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store: any = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
});

export const persistor = persistStore(store);

export type RootState = {
    snackbarState: SnackbarState;
    contracts: ContractsState;
    contractsRuerup: ContractsStateRuerup;
    currentConsultation: Consultation;
    savedConsultations: SavedConsultationsState;
    appUser: AppUserState;
    auth: AuthState;
    customerStore: CustomerState;
    appVersionState: AppVersionState;
};
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
