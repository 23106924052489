import * as React from 'react';
import { useSelector } from 'react-redux';
import { CustomerActions, getCurrentCustomer } from 'features/riesterProfiCheck/customers/customerSlice';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CustomerModel from 'features/riesterProfiCheck/customers/data/CustomerModel';
import { useAppDispatch } from 'app/store';
import { ExpansionPanel, ExpansionPanelSummary, Grid, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowDown';

import { useHistory } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import Routes from 'app/routes/Routes';
import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { RefObject } from 'react';

interface Props {
    edit: boolean;
}

const CustomExpansionPanel = withStyles({
    root: {
        width: '100%',
        border: 'none',
        '&::before': {
            backgroundColor: 'transparent'
        },
        backgroundColor: 'transparent'
    }
})(ExpansionPanel);

const CustomerForm: React.FC<Props> = ({ edit }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const currentCustomer = useSelector(getCurrentCustomer);

    const customerToEdit = edit ? currentCustomer : undefined;

    if (!customerToEdit && edit) {
        throw Error('Current Customer not set! Illegal State');
    }

    const customerId = customerToEdit ? customerToEdit.id : uuidv4();

    const { handleSubmit, errors, register } = useForm({ defaultValues: customerToEdit });

    const submitAndGoBack = handleSubmit( (data) => {

        const customer = { id: customerId, ...data } as CustomerModel;

        if (edit) {
            dispatch(CustomerActions.updateCustomer(customer));
        } else {
            dispatch(CustomerActions.addCustomer(customer));
        }
        history.goBack();
    });

    const submitAndStartConsultation = handleSubmit( (data) => {

        const customer = { id: customerId, ...data } as CustomerModel;

        if (edit) {
            dispatch(CustomerActions.updateCustomer(customer));
        } else {
            dispatch(CustomerActions.addCustomer(customer));
        }
        dispatch(CustomerActions.setCurrentCustomerId(customerId));
        dispatch(ConsultationActions.startConsultation(customerId))
        history.push(Routes.Consultation.Questions);
    });

    const formRef: RefObject<any> = React.createRef();

    return (
        <form noValidate ref={formRef}>
            <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                <Grid item md={8} container direction={'row'} spacing={2}>
                    <Grid item md={6}>
                        <TextField
                            label="Vorname"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            name="firstName"
                            error={!!errors.firstName}
                            inputRef={register({ required: true })}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            label="Nachname"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            name="lastName"
                            error={!!errors.lastName}
                            inputRef={register({ required: true })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomExpansionPanel variant={'outlined'} square>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                Optionale Daten eintragen
                            </ExpansionPanelSummary>
                            <Grid item md={12}>
                                <TextField
                                    label="E-Mail"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    name="email"
                                    error={!!errors.email}
                                    inputRef={register()}
                                />
                            </Grid>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item md={8}>
                                    <TextField
                                        label="Straße + Nr."
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        name="address.streetAndNumber"
                                        inputRef={register()}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField label="Telefon" fullWidth variant="outlined" margin="normal" name="address.phone" inputRef={register()} />
                                </Grid>

                                <Grid item md={4}>
                                    <TextField label="PLZ" fullWidth variant="outlined" margin="normal" name="address.postalCode" inputRef={register()} />
                                </Grid>
                                <Grid item md={8}>
                                    <TextField label="Stadt" fullWidth variant="outlined" margin="normal" name="address.city" inputRef={register()} />
                                </Grid>
                            </Grid>
                        </CustomExpansionPanel>
                    </Grid>
                    <Grid container direction={'row'}
                          justify="center"
                          alignItems="center" item md={12} >
                        <Grid item lg={1}>
                            <Checkbox
                                name="hasDataAgreement"
                                inputRef={register({ required: true })}
                            />
                        </Grid>
                        <Grid item lg={11}><p>Ich bestätige, dass eine unterschriebene Datenschutzerklärung des Kunden für die Verwendung der personenbezogenen Daten vorliegt.</p></Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Button type={'button'} onClick={submitAndGoBack} fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }}>
                            {edit ? 'Speichern' : 'Kunden hinzufügen'}
                        </Button>
                        <Button type={'button'} onClick={submitAndStartConsultation} fullWidth variant="outlined" color="primary" style={{ marginTop: '24px' }}>
                            {'Beratung starten'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default CustomerForm;
