import * as React from 'react';
import { Typography } from '@material-ui/core';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';

import { RootState, useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';
import { ChangeEvent, useState } from 'react';
import { SavedConsultationActions, SavedConsultationsState } from 'features/riesterProfiCheck/consultation/saved_consultations/savedConsultationsSlice';
import { CustomerActions, CustomerState } from 'features/riesterProfiCheck/customers/customerSlice';
import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';
// import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import Firebase from 'core/firebase/Firebase';

const saveFile = async () => {
    var today = new Date();

    const obj = localStorage;
    const blob = new Blob([JSON.stringify(obj, null, 2)], { type: 'application/json' });
    const a = document.createElement('a');
    a.download = 'vorsorge-profi-check-' + today.toISOString().substring(0, 10) + '.db';
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};


function capitalizeFirstLetter(string: string) {
    return string.replace(/^./, string[0].toUpperCase());
}

const BackupPage: React.FC = () => {

    const dispatch = useAppDispatch();
    const appUser = useSelector((state: RootState) => state.appUser.appUser!);

    const [uploadData, setUploadData] = useState({})
    const [isImportFinish, setImportFinish] = useState(false)
    const [importStatus, setImportStatus] = useState({ error: false, text: '' })
    const [isOnlineData, setOnlineData] = useState(false)

    var backupLog: any[] = [];
    for (var browser in appUser.appData) {
        // console.log('...', browser, appUser.appData[browser])
        backupLog.push({
            'date': new Date(appUser.appData[browser].backupDate ?? 0).toLocaleDateString("de-DE") + ' ' + new Date(appUser.appData[browser].backupDate ?? 0).toLocaleTimeString("de-DE"),
            'browser': capitalizeFirstLetter(browser),
            'id': browser
        })
    }

    const selectBackup = async (e: ChangeEvent<HTMLSelectElement>, backupLog: any) => {
        const selectedBckp = backupLog[e.target.value]
        console.log('selectBackup', e.target.value, selectedBckp)

        if (!selectedBckp || e.target.value === '') {
            setOnlineData(false)
            setUploadData({})
            setImportStatus({
                error: false,
                text: ''
            })
            return
        }

        // const databaseBackupsRef = Firebase.database().ref('backups/' + appUser.id + '/appData/').child(selectedBckp.id);

        try {
            const backupValue = await Firebase.database().ref('backups/' + appUser.id + '/appData/').child(selectedBckp.id).once('value');
            const onlineData = backupValue.val()
            console.log(onlineData)
            if (onlineData.backupDate) {
                setOnlineData(true)
                const customer = JSON.parse(onlineData['customerStore'])
                setImportStatus({
                    error: false,
                    text: `Backup mit ${(customer['customers']['ids']) ? customer['customers']['ids'].length : 0} Kunden gefunden.`
                })
                setUploadData(onlineData)
            } else {
                setImportStatus({
                    error: true,
                    text: 'Fehler beim Import.'
                })
            }
        } catch (err) {
            console.error(err);
            setOnlineData(false)
            setUploadData({})
            setImportStatus({
                error: true,
                text: 'Fehler beim Import. ' + err
            })
        }
    }

    const selectFile = async (e: ChangeEvent<HTMLInputElement>) => {
        // console.log(e)
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e: ProgressEvent<FileReader>) => {
            const text = (e && e.target) ? (e.target.result) : ''

            try {
                const json = JSON.parse((text as string) ?? '{}')
                if (json['persist:root']) {
                    const data = JSON.parse(json['persist:root'])

                    if (data['customerStore']) {
                        const customer = JSON.parse(data['customerStore'])
                        if (customer['customers']) {
                            setImportStatus({
                                error: false,
                                text: `Backup mit ${(customer['customers']['ids']) ? customer['customers']['ids'].length : 0} Kunden gefunden.`
                            })
                        }
                        setUploadData(data)
                        // console.log(customer, data, json)
                        return
                    }
                    setImportStatus({
                        error: true,
                        text: 'Fehler, keine Kunden gefunden.'
                    })
                    return
                } else {
                    setImportStatus({
                        error: true,
                        text: 'Fehler, falsches Dateiformat.'
                    })
                    return
                }

            } catch (error) {
                setImportStatus({
                    error: true,
                    text: 'Fehler beim Import.'
                })
                return
            }

            // alert(text) persist:root
        };
        // reader.readAsText(file); 
        if (e.target && e.target.files && e.target.files?.length > 0) {
            if (e.target.files[0].name && (e.target.files[0].name.slice(-3) ?? '') === '.db') {
                reader.readAsText(e.target.files[0] ?? '')
            } else {
                setImportStatus({
                    error: true,
                    text: 'Fehler, falsches Dateiformat.'
                })
            }

        }

    }

    const importBackup = async () => {
        const curStorage = localStorage.getItem('persist:root')
        const curStorageJson = JSON.parse(curStorage ?? '')

        // console.log(curStorageJson, uploadData)

        curStorageJson['currentConsultation'] = (uploadData as any)['currentConsultation']
        curStorageJson['customerStore'] = (uploadData as any)['customerStore']
        curStorageJson['savedConsultations'] = (uploadData as any)['savedConsultations']



        // console.log(JSON.parse((uploadData as any)['customerStore']) as CustomerState)
        try {

            // localStorage.setItem('persist:root', '');

            // store.subscribe(() => {
            //     localStorage.setItem('persist:root', JSON.stringify(curStorageJson))
            // })

            // localStorage.setItem('persist:root', JSON.stringify(curStorageJson))
            // localStorage.setItem('test', JSON.stringify(curStorageJson))


            dispatch(CustomerActions.overwriteCustomers(JSON.parse((uploadData as any)['customerStore']) as CustomerState));
            dispatch(SavedConsultationActions.overwriteConsultations(JSON.parse((uploadData as any)['savedConsultations']) as SavedConsultationsState));
            dispatch(ConsultationActions.overwriteConsultations(JSON.parse((uploadData as any)['currentConsultation']) as Consultation));

            setImportStatus({
                error: false,
                text: 'Daten erfolgreich importiert.'
            })
            setImportFinish(true)
        } catch (error) {
            setImportStatus({
                error: true,
                text: `Fehler, Importierung fehlerhaft. ${error}`
            })
        }
    }



    // console.log(appUser.appData)

    return (
        <NavigateBackScaffold title={'Datensicherung'} actions={<></>}>
            <>
                <Typography variant={'h5'}>Aktuelle Sicherungen</Typography>
                {
                    (appUser && appUser.appData)
                        ? <>
                            <ul className={'body1'}>
                                {
                                    backupLog.map((data, nr) => {

                                        return <li key={data.browser}>
                                            {data.browser} ({data.date})
                                        </li>
                                    })
                                }
                            </ul></>
                        : <Typography variant={'h5'}>kein Backup vorhanden</Typography>
                }
                <button onClick={() => {
                    saveFile()
                }}>
                    Aktuelles Datenbank Backup (.db) herunterladen
                </button>
                {/* <div style={{ textDecoration: 'underline', color: '-webkit-link', cursor: 'pointer' }} onClick={() => {
                    saveFile()
                }}>Aktuelles Datenbank Backup (.db) herunterladen</div> */}
                <br />
                <br />
                <br />
                <Typography variant={'h5'}>Backup wiederherstellen</Typography>
                <Typography variant={'body1'}>
                    <br />
                    Wählen Sie ein vorhandenes Browser-Backup zur Wiederherstellung aus.
                    <br />
                    <select
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => selectBackup(e, backupLog)}>
                        <option value="">keine Auswahl</option>
                        {
                            backupLog.map((data, nr) => {
                                return (data.browser.toLocaleLowerCase().substring(0, 3) !== 'ios')
                                    ? <option key={data.browser} value={nr}>
                                        {data.browser} ({data.date})
                                    </option>
                                    : ''
                            })
                        }
                    </select>
                    <br />
                    <br />
                    {
                        (!isOnlineData)
                            ? <>
                                Wählen Sie die .db Backupdatei zum Import aus.
                                <br />
                                <input type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => selectFile(e)} />
                                <br /><br />
                            </>
                            : ''
                    }
                    {importStatus.text}
                    <br /><br />
                    {
                        (importStatus.text !== '' && importStatus.error === false && !isImportFinish)
                            ? <button onClick={() => importBackup()}>Importieren und aktuelle Daten überschreiben</button>
                            : <br />
                    }

                </Typography>
                <br />
                <br />
            </>

        </NavigateBackScaffold >
    );
};

export default BackupPage;
