import * as React from 'react';
import { SvgIcon, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import IconMail from '@material-ui/icons/Mail';
import IconPhone from '@material-ui/icons/Phone';
import IconSmartphone from '@material-ui/icons/Smartphone';
import IconStreet from '@material-ui/icons/PinDrop';
import IconCity from '@material-ui/icons/Domain';
import Customer from 'features/riesterProfiCheck/customers/domain/Customer';

interface InfoRowProps {
    icon: React.ElementType;
    title: string;
    value?: string;
}

const InfoRow: React.FC<InfoRowProps> = ({ icon, title, value }) => {
    return (
        <TableRow>
            <TableCell style={{ borderBottom: 'none', width: '10%' }}>
                <SvgIcon style={{ color: grey[600] }} component={icon} />
            </TableCell>
            <TableCell style={{ borderBottom: 'none', width: '20%' }}>
                <Typography variant={'body1'}>{title}</Typography>
            </TableCell>
            <TableCell style={{ borderBottom: 'none' }}>
                <Typography variant={'body1'}>{value}</Typography>
            </TableCell>
        </TableRow>
    );
};
interface CustomerDataViewProps {
    customer: Customer;
}

const CustomerDataView: React.FC<CustomerDataViewProps> = ({ customer }) => {
    return (
        <Table style={{ marginTop: '12px' }}>
            <TableBody>
                <InfoRow icon={IconMail} title={'E-Mail Addresse'} value={customer.email} />
                <InfoRow icon={IconPhone} title={'Telefon'} value={customer.address?.phone} />
                <InfoRow icon={IconStreet} title={'Straße'} value={customer.address?.streetAndNumber} />
                <InfoRow icon={IconCity} title={'PLZ Stadt'} value={customer.cityWithPostalCode} />
            </TableBody>
        </Table>
    );
};

export default CustomerDataView;
