import { useSelector } from 'react-redux';
import { getAppUser } from 'features/app_user/appUserSlice';
import { getCurrentCustomer } from 'features/riesterProfiCheck/customers/customerSlice';
import { getAdditionalContracts, getSelectedContracts } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { RootState } from 'app/store';
import { Document } from '@react-pdf/renderer';
import PdfDeckblatt from 'features/riesterProfiCheck/consultation/result/pdf/PdfDeckblatt';
import PdfQuestions from 'features/riesterProfiCheck/consultation/result/pdf/PdfQuestions';
import PdfTable from 'core/pdf/PdfTable';
import PdfLegend from 'features/riesterProfiCheck/consultation/result/pdf/PdfLegend';
import React, { /* ReactElement, */ ReactNode } from 'react';
import CreateAndOpenPDFButton from 'core/pdf/PDFButton';

export interface ResultPDFButtonProps {
    beforeCreatePdf?: Function;
    ButtonElement?: ReactNode;
}

const ResultPDFButton: React.FC<ResultPDFButtonProps> = ({beforeCreatePdf, ButtonElement}) => {
    const appUser = useSelector(getAppUser);
    const customer = useSelector(getCurrentCustomer);
    const selectedContracts = useSelector(getSelectedContracts);
    const additionalContracts = useSelector(getAdditionalContracts);
    const questionValues = useSelector((state: RootState) => state.currentConsultation.questionValues);
    const showEffektivkosten = useSelector((state: RootState) => state.currentConsultation.showEffektivkosten);

    if (customer === undefined) {
        return <div>An Error Occured ... </div>;
    }

    const pdf = <Document>
        <PdfDeckblatt appUser={appUser} customer={customer}/>
        <PdfQuestions questionValues={questionValues}/>
        <PdfTable selectedContracts={selectedContracts} additionalContracts={additionalContracts}
                  showEffektivkosten={showEffektivkosten}/>
        <PdfLegend/>
    </Document>;

    return <CreateAndOpenPDFButton pdfDocument={pdf} beforeCreatePdf={beforeCreatePdf} ButtonElement={ButtonElement}/>;
};


export default ResultPDFButton;
