import * as React from 'react';
import { Grid } from '@material-ui/core';
import HomePageButton from 'features/home/HomePageButton';
import DrawerScaffold from 'app/components/scaffold/DrawerScaffold';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import { ShoppingCartOutlined, SystemUpdate } from '@material-ui/icons';


import { WebShopUrl } from 'config';
import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import { useAppDispatch } from 'app/store';
import CustomHomePageButton from 'features/home/CustomHomePageButton';

const HomePage: React.FC<WithAppVersionProps> = (props) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    return (
        <DrawerScaffold
            title={'Beratungs-Tools'}
            actions={
                <>
                </>
            }
            drawerContent={<MainDrawerContent/>}
        >
            <Grid container spacing={3}>
                {!props.isUptoDate &&
                <Grid item xs={12} md={4}>
                    <HomePageButton
                        red={true} icon={SystemUpdate} text={'Update benötigt!'}
                        onItemClicked={() => {
                            window.open(window.location.pathname, '_self');
                        }}/>
                </Grid>
                }
                <Grid item xs={12} md={4}>
                    <CustomHomePageButton icon={<div style={{fontSize: 32, fontWeight: "bold", lineHeight: 1}}>HRM</div>} text={'Hochrechnung-Methoden'} onItemClicked={() => {history.push(Routes.HRM)}}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomHomePageButton icon={<div style={{fontSize: 32, fontWeight: "bold", lineHeight: 1}}>FSP</div>} text={'Riester-Fondssparplan'} onItemClicked={() => {history.push(Routes.FSP)}}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomHomePageButton disabled icon={<div style={{fontSize: 32, fontWeight: "bold", lineHeight: 1}}></div>} text={'Kosten- und Rendite-Rechner'} onItemClicked={() => {}}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomHomePageButton disabled icon={<div style={{fontSize: 32, fontWeight: "bold", lineHeight: 1}}>GGR</div>} text={'Vorteilsrechner'} onItemClicked={() => {}}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomHomePageButton disabled icon={<div style={{fontSize: 32, fontWeight: "bold", lineHeight: 1}}>WFK</div>} text={'Nachteilsrechner'} onItemClicked={() => {}}/>
                </Grid>

                <Grid item xs={12} md={4}>
                    <HomePageButton icon={ShoppingCartOutlined} text={'Shop'} onItemClicked={() => {
                        var win = window.open(WebShopUrl, '_blank');
                        win?.focus();
                    }}/>
                </Grid>
            </Grid>
        </DrawerScaffold>
    );
};

export default withAppVersion(HomePage);
