import * as React from 'react';
import { useSelector } from 'react-redux';
import { ConsultationActions, getAdditionalContracts, getSelectedContracts } from 'features/riesterProfiCheck/consultation/consultationSlice';
import MaterialTable from 'material-table';
import { RootState, useAppDispatch } from 'app/store';
import {
    Box,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Icon,
    IconButton, List,
    Switch,
    Tooltip,
    Typography
} from '@material-ui/core';
import IconFilter from '@material-ui/icons/SettingsOutlined';
import DrawerRightScaffold from 'app/components/scaffold/DrawerRightScaffold';
import IconCompare from '@material-ui/icons/CompareArrows';
import { hasGreenName } from 'features/riesterProfiCheck/consultation/questions/domain/GreenNameColor';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';

import {
    defaultCellStyle,
    defaultHeaderStyle,
    getBeitragspauseCellStyle,
    getBesserungsoptionCellStyle,
    getGarantierteRenteCellStyle,
    getGarantierterRentenfaktorCellStyle,
    getEffektivkostenCellStyle,
    getVerschiebungRentenbeginnCellStyle,
    getMaxRGZCellStyle,
    getVKRenteCellStyle,
    getMindestBeitragCellStyle,
    getMindestLaufzeitCellStyle,
    getBUCellStyle,
    getSchiebeReglerCellStyle,
    getSolvencyCellStyle
} from 'features/riesterProfiCheck/consultation/result/domain/CellColors';
import TableTooltip from 'features/riesterProfiCheck/consultation/result/components/TableTooltip';
import SaveConsultationComponent from 'features/riesterProfiCheck/consultation/result/components/SaveConsultationComponent';

import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import { getValidityString } from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { ContractsActions, getContractIdsToCompare } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import { TableHeadersWithoutNewline } from 'features/riesterProfiCheck/consultation/models/TableHeaders';
import LongPressListener from 'features/riesterProfiCheck/consultation/result/components/ContractNameCell';
import OpaqueButton from 'app/components/buttons/OpaqueButton';
import ResultPDFButton from 'features/riesterProfiCheck/consultation/result/pdf/ResultPDFButton';
import useWindowDimensions from 'utils/useWindowDimensions';


const ResultPage: React.FC = () => {

    const { height, width } = useWindowDimensions();

    const dispatch = useAppDispatch();
    const history = useHistory();

    const selectedContracts = useSelector(getSelectedContracts);
    const additionalContracts = useSelector(getAdditionalContracts);
    const additionalContractIds = additionalContracts.map((c) => c.id);
    const questionValues = useSelector((state: RootState) => state.currentConsultation.questionValues);
    const showEffektivkosten = useSelector((state: RootState) => state.currentConsultation.showEffektivkosten);
    const showValidity = useSelector((state: RootState) => state.currentConsultation.showValidity);

    const contractsToCompareIds = useSelector(getContractIdsToCompare);
    const compareContractsEnabled = useSelector((state: RootState) => state.currentConsultation.compareProvidersEnabled);

    const onCompareIconPressed = () => {
        dispatch(ConsultationActions.toggleCompareProvidersEnabled());
        resetContractToCompareIds();
    };

    const setContractsToCompareIds = (ids: InsuranceContractModel[]) => {
        dispatch(ContractsActions.setContractIdsToCompare(ids));
    };
    const resetContractToCompareIds = () => {
        dispatch(ContractsActions.resetContractIdsToCompare());
    };

    const tableData = selectedContracts.concat(additionalContracts).map((o) => {
        if (contractsToCompareIds.includes(o.id)) {
            return { ...o, tableData: { checked: true } };
        }
        return { ...o };
    });

    const onShowEffektivkostenChange = () => dispatch(ConsultationActions.toggleShowEffektivkosten());
    const onShowValidityChanged = () => {
        dispatch(ConsultationActions.toggleShowValidity());
    };


    const onAddContractPressed = () => {
        history.push(Routes.Consultation.AddContracts);
    };

    const drawerContent = (
        <div style={{ padding: '8px 16px' }}>
            <Typography color={'primary'}>Anzeige</Typography>
            <Divider style={{ marginBottom: '8px' }} />
            <FormGroup>
                <FormControlLabel
                    control={<Switch color={'primary'} checked={showEffektivkosten}
                        onChange={onShowEffektivkostenChange} />}
                    label="Effektivkosten"
                />
                <FormControlLabel
                    control={<Switch color={'primary'} checked={showValidity} onChange={onShowValidityChanged} />}
                    label="Gültigkeit" />
            </FormGroup>
        </div>
    );

    const actions = (
        <>
            <Tooltip title="Zwei Tarife vergleichen">
                <IconButton onClick={onCompareIconPressed}>
                    <IconCompare color={compareContractsEnabled ? 'primary' : 'action'} />
                </IconButton>
            </Tooltip>
            <SaveConsultationComponent />
            <ResultPDFButton />
            <Tooltip title="Weitere Tarife hinzufügen">
                <IconButton onClick={onAddContractPressed}>
                    <Icon>add</Icon>
                </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
        </>
    );

    // console.log(tableData.length);


    return (
        <DrawerRightScaffold
            fullWidth
            title={`${tableData.length} Tarife`}
            openDrawerIcon={<IconFilter />}
            drawerContent={drawerContent}
            withBackNavigation
            onNavigateBack={() => {
                resetContractToCompareIds();
                if (compareContractsEnabled) {
                    dispatch(ConsultationActions.toggleCompareProvidersEnabled());
                }
            }}
            actions={actions}
        >

            <MaterialTable<InsuranceContractModel>
                data={tableData}
                localization={{
                    toolbar: {
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchen',
                        nRowsSelected: '{0}/2 Tarif(e) ausgewählt'
                    },
                    pagination: {
                        labelRowsSelect: 'Tarife',
                        labelRowsPerPage: 'Tarife pro Seite'
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Tarife vorhanden'
                    },
                }}
                onSelectionChange={(rows) => {
                    if (rows.length - contractsToCompareIds.length > 1) {
                        return;
                    }
                    setContractsToCompareIds(rows);
                }}
                options={{
                    draggable: false,
                    sorting: false,
                    showTitle: false,
                    selection: compareContractsEnabled,
                    padding: 'dense',
                    pageSize: 10, //tableData.length,
                    paginationType: 'stepped',
                    maxBodyHeight: height - 300,

                    search: true,
                    headerStyle: {
                        background: 'white',
                        borderBottom: '1 px solid gray',
                        position: 'sticky',
                        top: 0,
                        fontSize: 11,
                        zIndex: 85
                    },
                    actionsCellStyle: {
                        backgroundColor: '#dbdbdb',
                        color: 'black'
                    },
                    selectionProps: (rowData: any) => ({
                        disabled: contractsToCompareIds.length >= 2 && !rowData.tableData.checked,
                        color: 'primary'
                    })
                    // grouping: true
                }}
                components={{
                    Container: (props) => <div
                        style={{ width: width - 48, height: height - 300 }}>{props.children}</div>
                }}
                columns={[
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[0].title, TableHeadersWithoutNewline[0].comment),
                        cellStyle: {
                            background: 'white',
                            borderRight: '1 px solid gray',
                            position: 'sticky',
                            left: 0,
                            zIndex: 80
                        },
                        headerStyle: {
                            background: 'white',
                            borderRight: '1 px solid gray',
                            position: 'sticky',
                            left: 0,
                            zIndex: 90,
                            fontSize: 11
                        },
                        field: 'name',
                        render: (contract) => {
                            const indicatorColor = additionalContractIds.includes(contract.id) ? 'orange' : 'blue';
                            return (
                                <LongPressListener
                                    callback={() => {
                                        history.push(Routes.Contracts.DetailView + '/' + contract.id);
                                    }}
                                >
                                    <div
                                        style={{
                                            borderLeft: '6px solid ' + indicatorColor,
                                            paddingLeft: 8,
                                            marginLeft: -12,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {renderCellWithTooltip(
                                            contract.name,
                                            contract.nameCom,
                                            {
                                                color: questionValues[2] && hasGreenName(contract) ? 'green' : 'black',
                                                width: '150pt'
                                            } as React.CSSProperties,
                                            'left'
                                        )}
                                    </div>
                                </LongPressListener>
                            );
                        }
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[1].title, TableHeadersWithoutNewline[1].comment),
                        field: 'vertragsart',
                        cellStyle: defaultCellStyle as React.CSSProperties,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.vertragsart, contract.vertragsartCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[2].title, TableHeadersWithoutNewline[2].comment),
                        field: 'garantierteRente',
                        cellStyle: getGarantierteRenteCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.garantierteRente, contract.garantierteRenteCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[3].title, TableHeadersWithoutNewline[3].comment),
                        field: 'garantierterRentenfaktor',
                        cellStyle: getGarantierterRentenfaktorCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.garantierterRentenfaktor, contract.garantierterRentenfaktorCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[4].title, TableHeadersWithoutNewline[4].comment),
                        field: 'besserungsoption',
                        cellStyle: getBesserungsoptionCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.besserungsoption, contract.besserungsoptionCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[5].title, TableHeadersWithoutNewline[5].comment),
                        field: 'beitragspause',
                        cellStyle: getBeitragspauseCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.beitragspause, contract.beitragspauseCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[6].title, TableHeadersWithoutNewline[6].comment),
                        field: 'verschiebungRentenbeginn',
                        cellStyle: getVerschiebungRentenbeginnCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.verschiebungRentenbeginn, contract.verschiebungRentenbeginnCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[7].title, TableHeadersWithoutNewline[7].comment),
                        field: 'maxRgz',
                        cellStyle: getMaxRGZCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.maxRgz, contract.maxRgzCom)
                    },

                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[8].title, TableHeadersWithoutNewline[8].comment),
                        field: 'effektivkosten',
                        cellStyle: getEffektivkostenCellStyle,
                        headerStyle: defaultHeaderStyle,
                        hidden: !showEffektivkosten,
                        render: (contract) =>
                            renderCellWithTooltip(contract.effektivkosten.toString() === '?' ? '?' : contract.effektivkosten + '%', contract.effektivkostenCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[9].title, TableHeadersWithoutNewline[9].comment),
                        field: 'vkRente',
                        cellStyle: getVKRenteCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) =>
                            renderCellWithTooltip(contract.vkRente.toString() === 'unbek.' ? 'unbek.' : contract.vkRente + '%', contract.vkRenteCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[10].title, TableHeadersWithoutNewline[10].comment),
                        field: 'mindestbeitrag',
                        cellStyle: getMindestBeitragCellStyle,
                        headerStyle: defaultHeaderStyle,

                        render: (contract) =>
                            renderCellWithTooltip(contract.mindestbeitrag.toString() === '?' ? '?' : contract.mindestbeitrag + ' €', contract.mindestbeitragCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[11].title, TableHeadersWithoutNewline[11].comment),
                        field: 'mindestlaufzeit',
                        cellStyle: getMindestLaufzeitCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.mindestlaufzeit, contract.mindestlaufzeitCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[12].title, TableHeadersWithoutNewline[12].comment),
                        field: 'bu',
                        cellStyle: getBUCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.bu, contract.buCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[13].title, TableHeadersWithoutNewline[13].comment),
                        field: 'schieberegler',
                        cellStyle: getSchiebeReglerCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.schieberegler, contract.schiebereglerCom)
                    },
                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[14].title, TableHeadersWithoutNewline[14].comment),
                        field: 'solvency2',
                        cellStyle: getSolvencyCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract) => renderCellWithTooltip(contract.solvency2, contract.solvency2Com)
                    },

                    {
                        title: renderHeaderWithTooltip(TableHeadersWithoutNewline[15].title, TableHeadersWithoutNewline[15].comment),
                        cellStyle: defaultCellStyle,
                        headerStyle: defaultHeaderStyle,
                        hidden: !showValidity,
                        render: (contract: InsuranceContractModel) => <div>{getValidityString(contract)}</div>
                    }
                ]}
            />


            <Box display={compareContractsEnabled && contractsToCompareIds.length === 2 ? 'block' : 'none'}
                style={{ width: '100%', marginTop: 40 }}>
                <Grid container direction={'row'} spacing={4}>
                    <Grid item md={6}>
                        <OpaqueButton
                            onClick={() => history.push(Routes.Contracts.Compare)}
                            startIcon={<Icon>compare_arrows</Icon>}
                            size={'large'}
                            fullWidth
                            variant={'contained'}
                            color={'primary'}
                        >
                            Vergleichen
                        </OpaqueButton>
                    </Grid>
                </Grid>
            </Box>
        </DrawerRightScaffold>
    );
};

const renderCellWithTooltip = (
    content: string | number,
    comment: string | number,
    contentStyle?: React.CSSProperties | undefined,
    align?: 'center' | 'left'
) => {
    content = content.toString();
    comment = comment.toString();

    contentStyle = {
        ...contentStyle,
        height: '28pt',
        display: 'flex',
        alignItems: 'center',
        justifyContent: align ?? 'center'
    };
    return comment !== '' ? (
        <TableTooltip tooltipValue={comment}>
            <div style={contentStyle}>{content}</div>
        </TableTooltip>
    ) : (
        <div style={contentStyle}>{content}</div>
    );
};

const renderHeaderWithTooltip = (content: string | number, comment: string | number, align?: 'center' | 'left') => {
    content = content.toString();
    comment = comment.toString();

    const contentStyle = { flexGrow: 1, height: '100%' };
    return comment !== '' ? (
        <TableTooltip tooltipValue={comment}>
            <div style={contentStyle}>{content}</div>
        </TableTooltip>
    ) : (

        <List style={{ maxHeight: '100%', overflow: 'auto' }} >
            <div style={contentStyle}>{content}</div>
        </List>

    );
};

export default ResultPage;
