import * as React from 'react';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import MaterialTable from 'material-table';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';
import { TableHeadersWithoutNewline } from 'features/riesterProfiCheck/consultation/models/TableHeaders';
import { defaultCellStyle, defaultHeaderStyle } from 'features/riesterProfiCheck/consultation/result/domain/CellColors';
import { getValidityString } from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { useSelector } from 'react-redux';
import { Contracts } from 'features/riesterProfiCheck/insurance_contracts/contractsSlice';
import { RootState, useAppDispatch } from 'app/store';
import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';
import useWindowDimensions from 'utils/useWindowDimensions';

const AddContractsPage: React.FC = () => {

    const {height, width} = useWindowDimensions();
    const dispatch = useAppDispatch();

    const contracts = useSelector((state: RootState) => state.contracts) as Contracts;

    const selectedContractIds = useSelector((state: RootState) => state.currentConsultation.selectedContractIds);
    const additionalContractIds = useSelector((state: RootState) => state.currentConsultation.additionalContractIds);

    const editableContracts = contracts.ids.map((contractId) => {
        if (selectedContractIds.includes(contractId)) {
            return { ...contracts.entities[contractId], tableData: { checked: true, disabled: true } };
        } else if (additionalContractIds.includes(contractId)) {
            return { ...contracts.entities[contractId], tableData: { checked: true, disabled: false } };
        }
        return { ...contracts.entities[contractId] };
    });

    return (
        <NavigateBackScaffold title={'Tarife hinzufügen'} actions={<></>} fullWidth={true}>
            <MaterialTable<InsuranceContractModel>
                //style={{ overflow: 'auto', maxHeight: '60vh' }}
                title={contracts.ids.length + ' Tarife'}
                data={editableContracts}
                localization={{
                    toolbar: {
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchen'
                    },
                    pagination: {
                        labelRowsSelect: 'Tarife',
                        labelRowsPerPage: 'Tarife pro Seite'
                    }
                }}
                onSelectionChange={(rows) => {
                    const addtionalRows = rows.filter((row) => !selectedContractIds.includes(row.id));

                    dispatch(ConsultationActions.setAdditionalContracts(addtionalRows));
                }}
                options={{
                    draggable: false,
                    sorting: false,
                    selection: true,
                    padding: 'dense',
                    pageSize: 10, //editableContracts.length,
                    paginationType: 'stepped',
                    maxBodyHeight: height - 300,
                    headerStyle: {
                        background: 'white',
                        borderBottom: '1 px solid gray',
                        position: 'sticky',
                        top: 0,
                        fontSize: 11
                    },
                    actionsCellStyle: {
                        backgroundColor: '#dbdbdb',
                        color: 'black'
                    },
                    selectionProps: (rowData: InsuranceContractModel) => ({
                        disabled: selectedContractIds.includes(rowData.id),
                        color: selectedContractIds.includes(rowData.id) ? 'default' : 'primary'
                    })
                    //grouping: true
                }}
                components={{
                    Container: (props) => <div style={{ height: height - 300, width: width - 48 }}>{props.children}</div>
                }}
                columns={[
                    {
                        title: TableHeadersWithoutNewline[0].title,
                        field: 'name',
                        cellStyle: {
                            background: 'white',
                            borderRight: '1 px solid gray',
                            position: 'sticky',
                            left: 0
                        },
                        headerStyle: {
                            background: 'white',
                            borderRight: '1 px solid gray',
                            position: 'sticky',
                            left: 0,
                            zIndex: 11,
                            fontSize: 11
                        }
                    },
                    {
                        title: TableHeadersWithoutNewline[1].title,
                        field: 'vertragsart',
                        cellStyle: defaultCellStyle as React.CSSProperties,
                        headerStyle: defaultHeaderStyle
                    },
                    {
                        title: TableHeadersWithoutNewline[15].title,
                        cellStyle: defaultCellStyle,
                        headerStyle: defaultHeaderStyle,
                        render: (contract: InsuranceContractModel) => <div>{getValidityString(contract)}</div>
                    }
                ]}
            />
        </NavigateBackScaffold>
    );
};

export default AddContractsPage;
