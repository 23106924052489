import CustomerModel from 'features/riesterProfiCheck/customers/data/CustomerModel';
import { NormalizedObjects } from 'features/riesterProfiCheck/customers/customerSlice';

export const searchCustomerIds = (customers: NormalizedObjects<CustomerModel>, searchValue: string, protectedSearch: boolean): Array<string> => {
    return customers.ids.map((id) => customers.entities[id]).filter((customer: CustomerModel) => {
        if (protectedSearch) {
            return trimValue(customer.lastName + customer.firstName) === trimValue(searchValue) || trimValue(customer.firstName + customer.lastName) === trimValue(searchValue);
        }
        // concat first + last + first so it doesn't matter if firstName or lastName is entered first
        return trimValue(customer.firstName + customer.lastName + customer.firstName).includes(trimValue(searchValue));
    }).sort((a, b) => {
        if(trimValue(a.lastName) < trimValue(b.lastName)) { return -1; }
        if(trimValue(a.lastName) > trimValue(b.lastName)) { return 1; }
        return 0;
    }).map((customer) => customer.id);
};

const trimValue = (value: string) => value.toLowerCase().trim().replace('.', '').replace(',', '').replace(/\s/g, '');
