import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarActions } from './snackbarSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'app/store';

const DefaultSnackbar: React.FC = () => {
    const dispatch = useAppDispatch();
    const snackbarState = useSelector((state: RootState) => state.snackbarState);

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(SnackbarActions.hide());
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={snackbarState.show}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbarState.text}
            action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick={handleClose}>
                        OK
                    </Button>
                </React.Fragment>
            }
        />
    );
};

export default DefaultSnackbar;
