import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { Document } from '@react-pdf/renderer';
import PdfTable from './../../../pdf/PdfTable';
import CreateAndOpenPDFButton from 'core/pdf/PDFButton';
import InsuranceContractModel from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractModel';

interface Props {
    contracts: Array<InsuranceContractModel>
    isIOS?: boolean;
}

const ContractsOverviewPDFButton: React.FC<Props> = ({ contracts, isIOS }) => {

    const showEffektivkosten = useSelector((state: RootState) => state.currentConsultation.showEffektivkosten);
    const showAnbieterWechsel = useSelector((state: RootState) => state.currentConsultation.showAnbieterWechsel);

    const DownloadDocument = (
        <Document>
            <PdfTable
                selectedContracts={contracts}
                showEffektivkosten={showEffektivkosten}
                showExklusivAnlage={true}
                showPhysischesGold={true}
                showAnbieterWechsel={showAnbieterWechsel ?? false}
                showFondsrente={true}
            />
        </Document>
    );

    return <CreateAndOpenPDFButton pdfDocument={DownloadDocument} isIOS={isIOS} />
};

export default ContractsOverviewPDFButton;
