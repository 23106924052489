import * as React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
import HomePageButton from './HomePageButton';
import DrawerScaffold from '../../app/components/scaffold/DrawerScaffold';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import {
    PermDataSettingOutlined,
    ShoppingCartOutlined,
    SystemUpdate,
    WorkOutlined,
    WorkOutlineTwoTone,
    SupervisedUserCircleOutlined,
    VerifiedUserOutlined,
    EmojiPeopleOutlined
} from '@material-ui/icons';


import { WebShopUrl } from 'config';
import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import DisabledHomePageButton from 'features/home/DisabledHomePageButton';

import { isBasisversion } from 'utils/appUserUtils';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';

const SolvencyIIPage: React.FC<WithAppVersionProps> = (props) => {
    const history = useHistory();
    const appUser = useSelector((state: RootState) => state.appUser.appUser!);

    return (
        <DrawerScaffold
            title={'Solvency II'}
            actions={
                <>
                </>
            }
            drawerContent={<MainDrawerContent />}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    {
                        (!isBasisversion(appUser))
                            ? <HomePageButton
                                icon={EmojiPeopleOutlined}
                                text={'Kundenberatung'}
                                onItemClicked={() => { history.push(Routes.SolvencyII.Clients) }}
                            />
                            : <DisabledHomePageButton icon={
                                <SvgIcon component={EmojiPeopleOutlined} style={{ fontSize: 40 }} />} text={'Kundenberatung'}
                                tooltipContent={<React.Fragment>
                                    <p>In der Basisversion nicht verfügbar.</p>
                                </React.Fragment>}
                                isBasis={true}
                            />
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    {
                        (!isBasisversion(appUser))
                            ? <HomePageButton
                                icon={SupervisedUserCircleOutlined}
                                text={'Profianwendung'}
                                onItemClicked={() => history.push(Routes.SolvencyII.Expert)} />
                            : <DisabledHomePageButton icon={
                                <SvgIcon component={SupervisedUserCircleOutlined} style={{ fontSize: 40 }} />} text={'Profianwendung'}
                                tooltipContent={<React.Fragment>
                                    <p>In der Basisversion nicht verfügbar.</p>
                                </React.Fragment>}
                                isBasis={true}
                            />
                    }
                </Grid>
            </Grid>
        </DrawerScaffold>
    );
};

export default withAppVersion(SolvencyIIPage);
