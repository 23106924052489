import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import PdfContractDetailView from 'features/ruerupProfiCheck/insurance_contracts/detail_view/pdf/PdfContractDetailView';
import CreateAndOpenPDFButton from 'core/pdf/PDFButton';
import InsuranceContractModel from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractModel';

interface Props {
    contract: InsuranceContractModel;
    showEffektivkosten: boolean;
    showAnbieterWechsel: boolean;
    isIOS?: boolean;
}

const ContractDetailViewPDFButton: React.FC<Props> = ({ contract, showEffektivkosten, showAnbieterWechsel, isIOS }) => {

    return (
        <CreateAndOpenPDFButton pdfDocument={<PdfContractDetailView
            contract={contract}
            showEffektivkosten={showEffektivkosten}
            showAnbieterWechsel={showAnbieterWechsel}
        />} isIOS={isIOS} />
    );
};

export default ContractDetailViewPDFButton;
