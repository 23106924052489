import CustomerModel from 'features/riesterProfiCheck/customers/data/CustomerModel';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { searchCustomerIds } from 'features/riesterProfiCheck/customers/domain/CustomerSearch';
import isProduction from 'utils/isProduction';
import { RootState } from 'app/store';
import { CustomerFromModel } from 'features/riesterProfiCheck/customers/domain/Customer';

export interface NormalizedObjects<T> {
    entities: { [id: string]: T };
    ids: string[];
}

export interface CustomerState {
    customers: NormalizedObjects<CustomerModel>;
    currentCustomerId: string | undefined;
    searchValue: string;
}

const mockCustomers: NormalizedObjects<CustomerModel> = {
    entities: {},
    ids: []
};
const initialState: CustomerState = {
    customers: isProduction() ? { ids: [], entities: {} } : mockCustomers,
    currentCustomerId: isProduction() ? undefined : '1',
    searchValue: ''
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState: initialState,
    reducers: {
        addCustomer: (state: CustomerState, action: PayloadAction<CustomerModel>) => {
            state.customers.entities[action.payload.id] = action.payload;
            state.customers.ids.push(action.payload.id);
        },
        updateCustomer: (state: CustomerState, action: PayloadAction<CustomerModel>) => {
            state.customers.entities[action.payload.id] = action.payload;
        },
        deleteCustomer: (state: CustomerState, action: PayloadAction<CustomerModel>) => {
            delete state.customers.entities[action.payload.id];
            state.customers.ids.splice(state.customers.ids.indexOf(action.payload.id), 1);
            state.currentCustomerId = undefined;
        },
        setCurrentCustomerId: (state: CustomerState, action: PayloadAction<string | undefined>) => {
            state.currentCustomerId = action.payload;
        },
        toggleProtectCustomers: (state: CustomerState) => {
            state.currentCustomerId = undefined;
        },
        
        setSearchValue: (state: CustomerState, action: PayloadAction<string>) => {
            state.searchValue = action.payload;
        },
        overwriteCustomers: (state: CustomerState, action: PayloadAction<CustomerState>) => {
            // console.log('UPDATE ', state, action.payload)
            state.currentCustomerId = action.payload.currentCustomerId;
            state.customers = action.payload.customers;
            state.searchValue = action.payload.searchValue;
        },
    }
});

export const getCustomers = (state: RootState) => state.customerStore.customers;
export const getCurrentCustomer = createSelector(
    getCustomers,
    (state: RootState) => state.customerStore.currentCustomerId,
    (customers, currentCustomerId) => {
        if (currentCustomerId === undefined || !customers.entities[currentCustomerId]) {
            return undefined;
        } else {
            return CustomerFromModel(customers.entities[currentCustomerId]);
        }
    }
);


export const CustomerActions = customerSlice.actions;

export default customerSlice.reducer;
