export default {
    Contracts: {
        Overview: '/app/contracts/overview',
        Compare: '/app/contracts/compare',
        DetailView: '/app/contracts/detail_view',
    },
    VideoPage: '/app/videos',
    Home: '/app/home',
    Tools: '/app/tools',
    ToolsRiester: '/app/tools/riester_profi_check',
    HRM: '/app/tools/hrm',
    FSP: '/app/tools/fsp',
    RiesterProfiCheck: '/app/riester_profi_check',
    // RuerupProfiCheck: '/app/ruerup_profi_check',
    RuerupProfiCheck: {
        Overview: '/app/ruerup_profi_check/uebersicht',
        Contracts: {
            Overview: '/app/ruerup_profi_check/tarife/uebersicht',
            Compare: '/app/ruerup_profi_check/tarife/vergleich',
            DetailView: '/app/ruerup_profi_check/tarife/details',
        }
    },
    RuerupProfiCheckiOS: {
        Overview: '/ios/ruerup_profi_check/uebersicht',
        Contracts: {
            Overview: '/ios/ruerup_profi_check/tarife/uebersicht',
            Compare: '/ios/ruerup_profi_check/tarife/vergleich',
            DetailView: '/ios/ruerup_profi_check/tarife/details',
        }
    },
    SolvencyII: {
        Overview: '/app/solvency/uebersicht',
        Clients: '/app/solvency/kunden',
        Expert: '/app/solvency/profi',
    },
    ProfiChecksPage: '/app/profi_checks',
    Info: '/app/info',
    Backup: '/app/datensicherung',
    Imprint: '/app/impressum',
    Customer: {
        Master: '/app/customer/master',
        Create: '/app/customer/create',
        Edit: '/app/customer/edit'
    },
    Consultation: {
        Questions: '/app/consultation/questions',
        AddContracts: '/app/consultation/add_contracts',
        Result: '/app/consultation/result',
    },
    Auth: {
        Login: '/auth/login',
        ResetPassword: '/app/reset_password',
        Register: '/auth/register',
    },

};
