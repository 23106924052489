import * as React from 'react';
import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from '@material-ui/core';
import { useState } from 'react';
import { useAppDispatch } from 'app/store';
import { SavedConsultationActions } from 'features/riesterProfiCheck/consultation/saved_consultations/savedConsultationsSlice';
import DangerButton from 'app/components/buttons/DangerButton';
import { SnackbarActions } from 'features/snackbar/snackbarSlice';

interface Props {
    savedConsultation: Consultation;
}

const DeleteConsultationButton: React.FC<Props> = ({ savedConsultation }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useAppDispatch();

    const handleConfirmDeletionPressed = () => {
        dispatch(SavedConsultationActions.deleteConsultation(savedConsultation));
        dispatch(SnackbarActions.show(`Beratung ${savedConsultation.title} erfolgreich gelöscht!`));
    };

    const handleOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Button variant={'outlined'} color={'primary'} fullWidth startIcon={<Icon>delete</Icon>} onClick={handleOpen}>
                Löschen
            </Button>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle>{`Möchten Sie die Beratung ${savedConsultation.title} wirklich löschen?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Die gespeicherte Beratungen kann nicht wiederhergestellt werden!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DangerButton onClick={handleConfirmDeletionPressed} color="primary" autoFocus>
                        Unwiderruflich Löschen
                    </DangerButton>
                    <Button onClick={handleClose} color="primary">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteConsultationButton;
