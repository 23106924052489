import * as React from 'react';
import { CircularProgress, Container, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';

import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import { useState } from 'react';
import Firebase from 'core/firebase/Firebase';

const ResetPasswordPage: React.FC = () => {
    const { handleSubmit, register, errors } = useForm({
        defaultValues: { email: '' }
    });

    const [linkSentSuccessfully, setLinkSentSuccessfully] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = handleSubmit(async ({ email }) => {
        setLoading(true);

        await Firebase.auth().sendPasswordResetEmail(email.trim().toLowerCase());
        await setTimeout(() => {
            setLinkSentSuccessfully(true);
            setLoading(false);
        }, 100);
    });

    const linkSentView = (
        <>
            <DialogTitle>Ein Link zum Zurücksetzen Ihres Passworts wurde an Ihre E-Mail Addresse gesendet.</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Die Zustellung kann bis zu 5 Minuten in Anspruch nehmen. Falls Sie keine E-Mail erhalten haben, sehen Sie bitte in Ihrem Spam / Junk Ordner
                    nach oder versuchen Sie es erneut.
                </DialogContentText>
                <Button type="submit" fullWidth variant="contained" color="primary" onClick={() => setLinkSentSuccessfully(false)}>
                    Erneut anfordern
                </Button>
            </DialogContent>
        </>
    );

    const passwordResetForm = (
        <form style={{ flexGrow: 1 }} noValidate onSubmit={onSubmit}>
            <DialogTitle> Setzen Sie Ihr Passwort zurück</DialogTitle>
            <TextField
                label="E-Mail Adresse"
                fullWidth
                variant="outlined"
                margin="normal"
                autoComplete="email"
                name="email"
                error={!!errors.email}
                inputRef={register({ required: true })}
            />
            <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary">
                {loading ? <CircularProgress size={24} /> : '  Passwort zurücksetzen'}
            </Button>
        </form>
    );

    return (
        <NavigateBackScaffold actions={<></>} title={'Passwort zurücksetzen'}>
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '50vh' }}>
                <Grid item md={6}>
                    {linkSentSuccessfully ? linkSentView : passwordResetForm}
                </Grid>
            </Grid>
        </NavigateBackScaffold>
    );
};

export default ResetPasswordPage;
