import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUserModel } from 'features/app_user/data/AppUserModel';
import { AppThunk, RootState } from 'app/store';
import { AppUserFromModel } from 'features/app_user/domain/AppUser';
import Firebase from 'core/firebase/Firebase';
import { logout } from 'features/app_user/auth/authSlice';
import { isLicenseValid } from 'utils/appUserUtils';
import { logger } from 'codelyzer/util/logger';

export interface AppUserState {
    appUser: AppUserModel | undefined;
}

const initialState: AppUserState = {
    appUser: undefined
};

export const appUserSlice = createSlice({
    name: 'appUser',
    initialState,
    reducers: {
        onLogout: () => { return { appUser: undefined}},
        onLoginSuccess: (state: AppUserState, action: PayloadAction<AppUserModel>) => {
            return { ...state, appUser: action.payload}
        }
    }
});


export const watchUserData = (): AppThunk => async (dispatch) => {

    const currentUserId = Firebase.auth().currentUser?.uid;

    if (!currentUserId) {
        // console.log('watchUserData !currentUserId logout');
        // smmd App Logout bei Anpassungen verhindern
        // dispatch(logout());
        return;
    }
    try {
        const snapshot = await Firebase.database().ref('users').child(currentUserId).once('value');

        const appUser: AppUserModel = snapshot.val() as AppUserModel;

        if (!isLicenseValid(appUser)) {
            // console.log('watchUserData isLicenseValid logout');
            dispatch(logout());
            return;
        }

        dispatch(AppUserActions.onLoginSuccess(appUser));
        setTimeout(() => {
            dispatch(watchUserData());
        })
    } catch (err) {
        console.error(err);
    }
};



export const getAppUser = createSelector(
    (state: RootState) => state.appUser.appUser,
    (appUserModel) => AppUserFromModel(appUserModel)
);
export const isAuthenticated = createSelector(
    (state: RootState) => state.appUser.appUser,
    (appUserModel) => appUserModel !== undefined
);

export const AppUserActions = appUserSlice.actions;
export default appUserSlice.reducer;
