interface Question {
    title: string;
    content: string;
    question: string;
}

const Questions: Array<Question> = [
    {
        title: 'Garantierente',
        content:
            'Für Riesterverträge gilt eine Beitragsgarantie aller gezahlten Beiträge und Zulagen zu Rentenbeginn. Die meisten Anbieter berechnen daraus eine garantierte Rente.',
        question: 'Ist es für Sie wichtig, sich bereits ab Vertragsbeginn auf eine solche garantierte Mindestrente sicher verlassen zu können?'
    },
    {
        title: 'Garantierter Rentenfaktor für das gesamte Verrentungskapital',
        content: 'Es gibt leider einige Anbieter ohne Rentenfaktor und Anbieter, die den Rentenfaktor nur auf das eingezahlte Kapital garantieren.',
        question:
            'Möchten Sie sich lieber auf einen Rentenfaktor verlassen, der Ihnen sowohl für die Beitragsgarantie als auch für das Überschussguthaben garantiert wird?'
    },
    {
        title: 'Garantierente + garantierter Rentenfaktor',
        content:
            'Sie können beides haben, denn nur sehr wenige Anbieter wenden den in der Regel ' +
            'mit Sicherheitsabschlägen vorsichtig kalkulierten Rentenfaktor nur für ' +
            'das Überschussguthaben an und addieren diese Überschussrente mit der im Verhältnis ' +
            'höheren Garantierente.',
        question: 'Möchten Sie von einer solchen höheren Rente profitieren?'
    },
    {
        title: 'Besserungsoption',
        content:
            'Es ist möglich, dass sich zu Rentenbeginn aufgrund verbesserter Rechnungsgrundlagen ' +
            '(abhängig von Marktzins und Lebenserwartung) ein höherer Rentenfaktor und somit ' +
            'eine höhere Rente ergibt als zu Anfang garantiert. ',
        question: 'Soll Ihr Vertrag eine solche Besserungsoption beinhalten?'
    },
    {
        title: 'Beitragspause',
        content:
            'Es kann notwendig sein, dass Sie wegen Krankheit, Arbeitslosigkeit, Kindererziehung oder Beruf Ihren Beitrag reduzieren oder aussetzen müssen.',
        question:
            'Soll Ihr Vertrag nach der Wiederinkraftsetzung mit den gleichen Garantien und Rechnungsgrundlagen, wie zu Beginn vereinbart, fortgesetzt werden?'
    },
    {
        title: 'Vorverlegen des Rentenbeginns',
        content:
            'Vielleicht möchten Sie bereits etwas früher als erst mit Erreichen des ' +
            'Regelrentenalters in Rente gehen. Der Gesetzgeber sieht dafür ein Vorverlegen des Rentenbeginns um bis zu 5 Jahre vor.',
        question: 'Soll Ihr Riesteranbieter in diesem Fall die garantierten Rechnungsgrundlagen, sofern vorhanden, dennoch einhalten müssen?'
    },
    {
        title: 'Verschieben des Rentenbeginns',
        content:
            'Falls das Regelrentenalter weiter erhöht wird, oder wenn Sie zum Renteneintritt ' +
            'genügend sonstige Einkünfte oder Vermögen besitzen, macht es Sinn, die Rentenzahlung ' +
            'aus der Riesterrente lange aufschieben zu können.',
        question: 'Soll diese Möglichkeit bestehen und soll dies bei gleichen, garantierten Rechnungsgrundlagen möglich sein?'
    },
    {
        title: 'Rentengarantiezeit',
        content:
            'Sie erhalten eine lebenslange Rente. Sollten Sie innerhalb der Rentengarantiezeit sterben, ' +
            'zahlen wir Ihren Hinterbliebenen die ausstehenden Renten bis zum Ende der Garantiezeit weiter, ' +
            'oder wahlweise eine einmalige Kapitalabfindung. Die durchschnittliche Bezugsdauer von Altersrenten ' +
            'beträgt aktuell 21,5 Jahre.',
        question: 'Soll Ihre Hinterbliebenenversorgung mindestens so lange oder länger gesichert sein?'
    },
    {
        title: 'Schutz vor Berufsunfähigkeit',
        content:
            'Sollten Sie wegen Krankheit oder Unfall vorzeitig berufsunfähig werden, können Sie mit ' +
            'großer Wahrscheinlichkeit die Beiträge für Ihre Riesterrente nicht mehr zahlen und ' +
            'Ihr Sparziel ist gefährdet.',
        question: 'Soll die Möglichkeit bestehen, dass Ihre Beiträge in dieser Zeit vom Riesteranbieter bezahlt werden?'
    },
    {
        title: 'Sicherheit und Finanzkraft',
        content:
            'Die Garantien müssen von den Anbietern über Jahrzehnte garantiert erfüllt werden. ' +
            'Dies wird unter Solvency II durch die BaFin streng überwacht. Zu Zeiten von ' +
            'Niedrigzinsen wird dies für viele Anbieter immer schwieriger und viele haben ' +
            'sich bereits vom Markt verabschieden müssen.',
        question:
            'Legen Sie besonderen Wert darauf, dass Ihr Anbieter unter Solvency II Kriterien zu den finanzstärksten zählt?',
    }
];

export default Questions;
