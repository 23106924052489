import * as React from 'react';
import { Grid, Icon } from '@material-ui/core';
import DrawerScaffold from '../../app/components/scaffold/DrawerScaffold';
import MainDrawerContent from 'app/components/drawer/MainDrawerContent';
import {
    SystemUpdate, CloudDownload
} from '@material-ui/icons';


import withAppVersion, { WithAppVersionProps } from 'features/appVersion/withAppVersion';
import HomePageButton from 'features/home/HomePageButton';


const keynoteUrl =
    'https://www.dropbox.com/sh/kqwfpcrdtyfy7vb/AADerT_JTtfy50TtEdQBy5YRa?dl=0&preview=Riester-Fondssparplan.key';
const powerpointUrl =
    'https://www.dropbox.com/sh/kqwfpcrdtyfy7vb/AADerT_JTtfy50TtEdQBy5YRa?dl=0&preview=Riester-Fondssparplan+(1).pptx';

const HomePage: React.FC<WithAppVersionProps> = (props) => {

    return (
        <DrawerScaffold
            title={'Riester-fondssparplan'}
            actions={
                <>
                </>
            }
            drawerContent={<MainDrawerContent/>}
        >
            <Grid container spacing={3}>
                {!props.isUptoDate &&
                <Grid item xs={12} md={4}>
                    <HomePageButton
                        red={true} icon={SystemUpdate} text={'Update benötigt!'}
                        onItemClicked={() => {
                            window.open(window.location.pathname, '_self')
                        }}/>
                </Grid>
                }
                <Grid item xs={12} md={4}>
                    <HomePageButton icon={CloudDownload} text={'Powerpoint'} onItemClicked={
                        () => window.open(powerpointUrl, '_blank')}/>
                </Grid>

                <Grid item xs={12} md={4}>
                    <HomePageButton icon={CloudDownload} text={'Keynote'} onItemClicked={
                        () => window.open(keynoteUrl, '_blank')}/>
                </Grid>
            </Grid>
        </DrawerScaffold>
    );
};

export default withAppVersion(HomePage);
