import React, { useState } from 'react';
import { Button, Grid, IconButton, ListItem, Tooltip, Typography } from '@material-ui/core';
import InsuranceContractList from 'features/riesterProfiCheck/consultation/questions/components/InsuranceContractList';
import QuestionsList from 'features/riesterProfiCheck/consultation/questions/components/QuestionsList';
import DrawerScaffold from 'app/components/scaffold/DrawerScaffold';

import IconCheck from '@material-ui/icons/CheckBox';
import IconUnCheck from '@material-ui/icons/CheckBoxOutlineBlank';
import IconToggleQuestionSize from '@material-ui/icons/Launch';
import { ConsultationActions, questionFilteredContracts } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { useAppDispatch } from 'app/store';

import DrawerAvatar from 'app/components/drawer/DrawerAvatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import SaveConsultationDialog from 'features/riesterProfiCheck/consultation/saved_consultations/components/SaveConsultationDialog';
import { getCurrentCustomer } from 'features/riesterProfiCheck/customers/customerSlice';
import QuestionStepper from 'features/riesterProfiCheck/consultation/questions/components/QuestionStepper';
import { PrimaryColor } from 'app/style/AppColors';

export default function QuestionsPage() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const onEndConstulationClick = () => {
        setOpenSaveDialog(true);
    };

    const [questionsAsList, setQuestionsAsList] = useState(true);

    const filteredContracts = useSelector(questionFilteredContracts);
    const currentCustomer = useSelector(getCurrentCustomer);
    if (currentCustomer === undefined) {
        return <div>Ein unerwarteter Fehler ist aufgetreten</div>;
    }

    const onToggleQuestionSizeClick = () => {
        setQuestionsAsList(!questionsAsList);
    }

    return (
        <DrawerScaffold
            drawerContent={
                <>
                    <DrawerAvatar />
                    <Divider />
                    <List>
                        <ListItem>
                            <Typography variant={'body2'} style={{ width: '100%', textAlign: 'center' }}>
                                Analyse für
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant={'body1'} style={{ width: '100%', textAlign: 'center' }}>
                                {currentCustomer?.fullName}
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Button fullWidth variant={'outlined'} color={'primary'} onClick={onEndConstulationClick}>
                                Beratung beenden
                            </Button>
                            <SaveConsultationDialog open={openSaveDialog} onDialogClose={() => history.push(Routes.Customer.Master)} />
                        </ListItem>
                    </List>
                </>
            }
            title={'10 Goldene Riester Fragen'}
            actions={
                <>
                    <Tooltip title="Fragen schrittweise anzeigen">
                        <IconButton onClick={onToggleQuestionSizeClick}>
                            <IconToggleQuestionSize />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Alle Tarife selektieren">
                        <IconButton onClick={() => dispatch(ConsultationActions.setSelectedContracts(filteredContracts))}>
                            <IconCheck />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Selektion zurücksetzen">
                        <IconButton onClick={() => dispatch(ConsultationActions.resetQuestionFilteredContracts())}>
                            <IconUnCheck />
                        </IconButton>
                    </Tooltip>
                </>
            }
        >
            <Grid container direction={'row'} spacing={2}>
                {questionsAsList ? <QuestionsList /> : <QuestionStepper />}
                <InsuranceContractList />
            </Grid>
        </DrawerScaffold>
    );
}
