import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Container, Typography } from '@material-ui/core';
import { ScaffoldStyles } from './ScaffoldStyles';
import { useHistory } from 'react-router-dom';

export interface ScaffoldProps {
    title: string;
    actions: React.ReactNode;
    fullWidth?: boolean;
}

interface DrawerRightScaffoldProps extends ScaffoldProps {
    drawerContent: React.ReactNode;
    openDrawerIcon: React.ReactNode;
    withBackNavigation?: boolean;
    onNavigateBack?: Function;
}

const DrawerRightScaffold: React.FC<DrawerRightScaffoldProps> = ({
    title,
    actions,
    drawerContent,
    children,
    openDrawerIcon,
    withBackNavigation = true,
    onNavigateBack= () => {},
    fullWidth = false
}) => {
    const history = useHistory();

    const classes = ScaffoldStyles();
    const navigateBack = () => {
        onNavigateBack();
        history.goBack();
    };
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar color={'default'} position="fixed" className={classes.appBar}>
                <Toolbar>
                    {withBackNavigation
                        ? [
                              <Button startIcon={<ChevronLeftIcon />} color="inherit" onClick={navigateBack} className={classes.menuButton}>
                                  <Typography variant={'subtitle1'} style={{ textTransform: 'none' }}>
                                      Zurück
                                  </Typography>
                              </Button>
                          ]
                        : []}
                    <div className={classes.grow}>
                        <h3>{title}</h3>
                    </div>
                    {actions}
                    <div style={{ padding: '12px' }}></div>
                    <IconButton onClick={handleDrawerOpen}>{openDrawerIcon}</IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                anchor="right"
                open={open}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeaderRight}>
                    <div style={{ flexGrow: 1, textAlign: 'center' }}>
                        <Typography variant={'subtitle1'}>Einstellungen</Typography>
                    </div>

                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {drawerContent}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.drawerHeader} />
                <Container style={{ minWidth: '100vw'}}>{children}</Container>
            </main>
        </div>
    );
};

export default DrawerRightScaffold;
