import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import Firebase from 'core/firebase/Firebase';
import { appVersion } from 'config';
import { SnackbarActions } from 'features/snackbar/snackbarSlice';

export interface AppVersionState {
    isAppUpToDate: boolean;
    latestVersion: string;
    updatedAt: Date;
}

const initialState: AppVersionState = {
    isAppUpToDate: true,
    latestVersion: appVersion,
    updatedAt: new Date(1)
};

export const appVersionSlice = createSlice({
    name: 'appVersion',
    initialState: initialState,
    reducers: {
        setLatestVersion: (state: AppVersionState, action: PayloadAction<AppVersionState>) => {
            return action.payload;
        }
    }
});

export const getAppVersionState = (state: RootState) => state.appVersionState;


const isCurrentVersionUpToDate = (params: {current: Array<string>, latest: Array<string>}) => {
    const {current, latest} = params;
    for (let i = 0; i < current.length; i++) {
        if (current[i] != latest[i]) {
            return current[i] > latest[i];
        }
    }
    return true;
}

export const fetchLatestVersion = (): AppThunk => async (dispatch) => {

    try {
        const snapshot = await Firebase.database().ref('currentAppVersionWeb').once('value');

        const latestVersion: string = snapshot.val();

        const latestSplitted: Array<string>= latestVersion.split('.');
        const currentAppVersionSplitted: Array<string> = appVersion.split('.');

        const isAppUpToDate = isCurrentVersionUpToDate({current: currentAppVersionSplitted, latest: latestSplitted});

        if (!isAppUpToDate) {
            dispatch(SnackbarActions.show('Eine neue Version der App ist verfügbar. Bitte laden Sie die Seite neu!'));
        }
        dispatch(AppVersionActions.setLatestVersion({
            isAppUpToDate: isAppUpToDate,
            latestVersion: latestVersion,
            updatedAt: new Date()
        }));
    } catch (err) {
        console.error(err);
    }
};


export const AppVersionActions = appVersionSlice.actions;
export default appVersionSlice.reducer;
