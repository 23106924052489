import * as React from 'react';
import { useSelector } from 'react-redux';
import { fetchLatestVersion, getAppVersionState } from 'features/appVersion/appVersionSlice';
import { useAppDispatch } from 'app/store';
import { pullProfileChangesDuration } from 'config';

export interface WithAppVersionProps {
    isUptoDate: boolean;
}

export default function withAppVersion(WrappedComponent: React.ComponentType<WithAppVersionProps>) {
    return () => {
        const appVersionState = useSelector(getAppVersionState);

        const dispatch = useAppDispatch();
        const shouldFetchLatestVersion = new Date().getTime() - appVersionState.updatedAt.getTime() > pullProfileChangesDuration;

        if (shouldFetchLatestVersion) {
            dispatch(fetchLatestVersion());
        }

        return <WrappedComponent isUptoDate={appVersionState.isAppUpToDate} />;
    };
}
