import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { useForm, Controller } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'app/store';

import { isAuthenticated as AuthSelector } from 'features/app_user/appUserSlice';

import RPCIcon from 'assets/icon.png';
import { attemptLogin } from 'features/app_user/auth/authSlice';
import { CircularProgress } from '@material-ui/core';
import LoginFailedDialog from 'features/app_user/auth/login/LoginFailedDialog';

import Routes from 'app/routes/Routes';



function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.dfiav.de/">
            DFIAV Deutsche Förderinitiative Altersvorsorge
            </Link>
            {' ' + new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    image: {
        backgroundImage: 'assets/icon.png',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const LoginPage: React.FC = () => {
    const classes = useStyles();
    const isAuthenticated = useSelector(AuthSelector);
    const authState = useSelector((state: RootState) => state.auth);
    const dispatch = useAppDispatch();

    const { handleSubmit, /* errors */ control } = useForm({
        defaultValues: { email: '', password: '' }
    });

    const onSubmit = handleSubmit(async ({ email, password }) => {
        dispatch(attemptLogin({ email: email.toLowerCase().trim(), password: password.trim() }));
    });

    if (isAuthenticated) {
        return <Redirect to="/app/home" />;
    }

    const isLoading = authState?.loading // false // 

    return (
        <>  
            <LoginFailedDialog />
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item container direction={'column'} alignItems={'center'} xs={false} sm={4} md={7} className={classes.image}>
                    <img alt="Icon" src={RPCIcon} style={{ height: '20%', margin: '24px', marginTop: 100 }} />
                    <Typography variant={'h3'} color={'primary'}>
                        Vorsorge-Profi-Check
                    </Typography>
                    <Typography variant={'body2'} color={'primary'}>
                        Das vermutlich beste Tool für Vorsorgeberater!
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Anmelden
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={onSubmit}>
                            <Controller
                                as={<TextField variant="outlined" margin="normal" fullWidth label="E-Mail Adresse" autoComplete="email" />}
                                name="email"
                                control={control}
                            />

                            <Controller
                                as={<TextField variant="outlined" margin="normal" fullWidth label="Passwort" type="password" autoComplete="current-password" />}
                                name="password"
                                control={control}
                            />

                            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Angemeldet bleiben" />
                            <Button disabled={isLoading} type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                {isLoading ? <CircularProgress size={24} /> : 'Anmelden'}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href={Routes.Auth.ResetPassword} variant="body2">
                                        Probleme beim Anmelden?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default LoginPage;
