import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button, Container, Typography } from '@material-ui/core';
import { ScaffoldStyles } from './ScaffoldStyles';
import { ScaffoldProps } from './DrawerScaffold';
import { useHistory } from 'react-router-dom';

const NavigateBackScaffold: React.FC<ScaffoldProps> = ({ title, actions, children, fullWidth = false }) => {
    const history = useHistory();

    const classes = ScaffoldStyles();
    const navigateBack = () => {
        history.goBack();
    };
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar color={'default'} position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Button startIcon={<ChevronLeftIcon />} color="inherit" onClick={navigateBack} className={classes.menuButton}>
                        <Typography variant={'subtitle1'} style={{ textTransform: 'none' }}>
                            Zurück
                        </Typography>
                    </Button>

                    <div className={classes.grow}>
                        <h3>{title}</h3>
                    </div>
                    {actions}
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.drawerHeader} />
                <Container style={fullWidth ? { minWidth: '100vw'} : {}}>{children}</Container>
            </main>
        </div>
    );
};

export default NavigateBackScaffold;
