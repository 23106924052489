import * as React from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Theme
} from '@material-ui/core';
import Customer from 'features/riesterProfiCheck/customers/domain/Customer';
import { useAppDispatch } from 'app/store';
import { useState } from 'react';
import { CustomerActions } from 'features/riesterProfiCheck/customers/customerSlice';
import { Alert, AlertTitle } from '@material-ui/lab';
import { red } from '@material-ui/core/colors';
import DangerButton from 'app/components/buttons/DangerButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2)
            }
        }
    })
);

interface CustomerEditMoreMenuProps {
    customer: Customer;
}
const CustomerEditMoreMenu: React.FC<CustomerEditMoreMenuProps> = ({ customer }) => {
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const onDeleteItemClick = () => {
        setDialogOpen(true);
    };

    const handleConfirmDeletionPressed = () => {
        dispatch(CustomerActions.deleteCustomer(customer));
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <IconButton color="primary" onClick={handleClick}>
                <Icon>more_vert</Icon>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={menuOpen} onClose={handleClose}>
                <MenuItem onClick={onDeleteItemClick}>
                    <ListItemIcon>
                        <Icon>delete</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Löschen" />
                </MenuItem>
            </Menu>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{'Möchten Sie den Kontakt wirklich löschen?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Gespeicherte Beratungen können dadurch nicht wiederhergestellt werden!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DangerButton onClick={handleConfirmDeletionPressed} color="primary" autoFocus>
                        Unwiderruflich Löschen
                    </DangerButton>
                    <Button onClick={handleDialogClose} color="primary">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CustomerEditMoreMenu;
