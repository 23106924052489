import * as React from 'react';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';
import { Typography } from '@material-ui/core';
import { AppSupportUrl, DatenschutzUrl, ImpressumUrl, AppVerantwortungUrl, AppLicenseAgreementUrl } from 'config';

const ImprintPage: React.FC = () => {
    return (
        <NavigateBackScaffold title={'Datenschutz & Impressum'} actions={<></>}>
            <Typography variant={'body1'}>
                Datenschutzerklärung: <br />
                <a href={DatenschutzUrl} target="_blank">
                    {DatenschutzUrl}
                </a>
            </Typography>
            <br />
            <br />
            <Typography variant={'body1'}>
                Impressum: <br />
                <a href={ImpressumUrl} target="_blank">
                    {ImpressumUrl}
                </a>
            </Typography>
            <br />
            <br />
            <Typography variant={'body1'}>
                Lizenzbedingungen: <br />
                <a href={AppLicenseAgreementUrl} target="_blank">
                    {AppLicenseAgreementUrl}
                </a>
            </Typography>
            <br />
            <br />
            <Typography variant={'body1'}>
                App Support: <br />
                <a href={AppSupportUrl} target="_blank">
                    {AppSupportUrl}
                </a>
            </Typography>
            <br />
            <br />
            <Typography variant={'body1'}>
                App Verantwortung: <br />
                <a href={AppVerantwortungUrl} target="_blank">
                    {AppVerantwortungUrl}
                </a>
            </Typography>
            <br />
            <br />
        </NavigateBackScaffold>
    );
};

export default ImprintPage;
