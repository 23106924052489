import * as React from 'react';
import { Button, ExpansionPanel, ExpansionPanelSummary, Grid, Theme, Typography, withStyles } from '@material-ui/core';
import IconStart from '@material-ui/icons/Send';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowDown';
import SavedConsultationItem from 'features/riesterProfiCheck/customers/view/overview/components/detail/SavedConsultationItem';
import { useAppDispatch } from 'app/store';
import { getSavedConsultationsForCurrentUser } from 'features/riesterProfiCheck/consultation/saved_consultations/savedConsultationsSlice';
import { ConsultationActions } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { getCurrentCustomer } from 'features/riesterProfiCheck/customers/customerSlice';
import { Consultation } from 'features/riesterProfiCheck/consultation/models/Consultation';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const CustomExpansionPanel = withStyles({
    root: {
        width: '100%',
        border: 'none',
        '&::before': {
            backgroundColor: 'white'
        }
    }
})(ExpansionPanel);

const CustomerActionsView: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useAppDispatch();

    const savedConsultations = useSelector(getSavedConsultationsForCurrentUser) as Array<Consultation>;
    const currentCustomer = useSelector(getCurrentCustomer);

    return (
        <Grid container direction={'column'} alignItems={'flex-start'}>
            <Button
                onClick={() => {
                    dispatch(ConsultationActions.startConsultation(currentCustomer?.id));
                    history.push(Routes.Consultation.Questions);
                }}
                variant={'outlined'}
                color={'primary'}
                fullWidth
                startIcon={<IconStart />}
                style={{ marginBottom: '12px' }}
            >
                Beratung starten
            </Button>
            {savedConsultations !== undefined && savedConsultations?.length !== 0 ? (
                <CustomExpansionPanel variant={'outlined'} square>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className={classes.heading}>Gespeicherte Beratungen</Typography>
                    </ExpansionPanelSummary>

                    {savedConsultations?.map((consultation: Consultation) => {
                        return <SavedConsultationItem savedConsultation={consultation} key={consultation.id} />;
                    })}
                </CustomExpansionPanel>
            ) : (
                <div></div>
            )}
        </Grid>
    );
};

export default CustomerActionsView;
