import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { SvgIcon } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

const DrawerMenuItem: React.FC<{
    title: string;
    icon: any;
    link: string;
    action?: Function;
}> = ({ title, icon, link, action }) => {
    const content = (
        <ListItem button>
            <ListItemIcon>
                <SvgIcon component={icon} />
            </ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    );

    if (action) {
        return <div onClick={() => action && action()}>{content}</div>;
    } else {
        return (
            <Link to={link} style={{ textDecoration: 'none', color: 'black' }}>
                {content}
            </Link>
        );
    }
};

export default DrawerMenuItem;
