import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'app/store';
// import { AuthActions } from 'features/app_user/auth/authSlice';
// import { useHistory } from 'react-router-dom';
// import Routes from 'app/routes/Routes';
import { WebShopUrl } from 'config';
import { asGermanDateDMY } from 'utils/DateFormatter';

const LoginBasisversionDialog: React.FC = () => {
    const loginError = useSelector<RootState>((state) => state.auth.error);
    // const dispatch = useAppDispatch();
    // const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle>Ihre Testphase ist abgelaufen.</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Sofern Sie den Vorsorge-Profi-Check weiter nutzen möchten kaufen Sie diesen bitte entweder als iOS-App oder als Web-Applikation oder als Bundle.
                </DialogContentText>
                <DialogActions>
                    <Button variant={'contained'} color={'primary'} onClick={() => {
                        var win = window.open(WebShopUrl, '_blank');
                        win?.focus();
                    }}>
                    Jetzt App kaufen
                    </Button>
                    <Button variant={'contained'} color={'primary'} onClick={() => handleClose()}>
                        Zur Basisversion
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default LoginBasisversionDialog;
