import * as React from 'react';
import { Grid, List } from '@material-ui/core';
import Questions from 'features/riesterProfiCheck/consultation/questions/domain/Questions';
import QuestionView from 'features/riesterProfiCheck/consultation/questions/components/QuestionView';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

const QuestionsList: React.FC = () => {
    const questionValues: Record<number, boolean> = useSelector((state: RootState) => state.currentConsultation.questionValues);

    return (
        <Grid item xs={8}>
            <List style={{ padding: '0px', maxHeight: '75vh', overflow: 'auto' }}>
                {Questions.map((question, index) => (
                    <QuestionView bigView={false} key={question.title} questionValues={questionValues} question={question} index={index} />
                ))}
            </List>
        </Grid>
    );
};

export default QuestionsList;
