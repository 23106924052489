import InsuranceContractModel from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractModel';
import React from 'react';
import { View, Text, Page, Font } from '@react-pdf/renderer';
import TableHeaders from 'features/ruerupProfiCheck/consultation/models/TableHeaders';
import { Center, PDFStyles, Row } from 'core/pdf/CustomPdfComponents';
import { getValidityString } from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { grey } from '@material-ui/core/colors';
import TableHeader from 'features/ruerupProfiCheck/consultation/models/TableHeader';

const columnsLength = TableHeaders.length;

const INDEX_NAME = 0;
const INDEX_VERTRAGSART = 1;
const INDEX_GARANTIERTE_RENTE = 2;
const INDEX_GARANTIERTER_RENTENFAKTOR = 3;
const INDEX_BESSERUNGSOPTION = 4;
const INDEX_BEITRAGSPAUSE = 5;
const INDEX_VERSCHIEBUNG_RENTENBEGINN = 6;
const INDEX_MAX_RGZ = 7;
const INDEX_EFFEKTIVKOSTEN = 8;
const INDEX_ANBIETERWECHSEL = 19;
const INDEX_VK_RENTE = 9;
const INDEX_MINDESTBEITRAG = 10;
const INDEX_MINDESTLAUFZEIT = 11;
const INDEX_BU = 12;
const INDEX_SCHIEBEREGLER = 13;
const INDEX_SOLVENCY = 14;
const INDEX_GÜLTIGKEIT = 15;

const getCellWidthFactor: (index: number, showEffektivkosten: boolean, showAnbieterWechsel: boolean) => number = (index, showEffektivkosten, showAnbieterWechsel) => {
    if (!showEffektivkosten) {
        if (index >= INDEX_EFFEKTIVKOSTEN) {
            ++index;
        }
    }
    if (!showAnbieterWechsel) {
        if (index >= INDEX_ANBIETERWECHSEL) {
            ++index;
        }
    }
    if (index === 0) {
        return 2;
    } else if (index === INDEX_MINDESTLAUFZEIT) {
        return 0.8;
    } else if (index === INDEX_MINDESTBEITRAG) {
        return 0.7;
    } else if (index === INDEX_MAX_RGZ) {
        return 0.7;
    } else if (index === INDEX_BU) {
        return 0.5;
    } else if (index === INDEX_VERSCHIEBUNG_RENTENBEGINN) {
        return 1.3;
    } else if (index === INDEX_SCHIEBEREGLER) {
        return 1;
    } else if (index === INDEX_EFFEKTIVKOSTEN) {
        return 0.8;
    } else if (index === INDEX_ANBIETERWECHSEL) {
        return 0.8;
    } else if (index === INDEX_BEITRAGSPAUSE) {
        return 1.3;
    } else {
        return 1;
    }
};

const getTableHeaders: (showEffektivkosten: boolean, showAnbieterWechsel: boolean) => Array<TableHeader> = (showEffektivkosten: boolean, showAnbieterWechsel: boolean) =>
    JSON.parse(JSON.stringify(TableHeaders))
        .filter((header: TableHeader, index: number) => index !== INDEX_EFFEKTIVKOSTEN || showEffektivkosten)
        .filter((header: TableHeader, index: number) => index !== INDEX_ANBIETERWECHSEL || showAnbieterWechsel);

const getColumns: (contract: InsuranceContractModel, showEffektivkosten: boolean, showAnbieterWechsel: boolean) => string[] = (
    contract: InsuranceContractModel,
    showEffektivkosten: boolean,
    showAnbieterWechsel: boolean
) => {
    return [
        contract.name,
        contract.vertragsart,
        contract.garantierteRente,
        contract.garantierterRentenfaktor,
        contract.besserungsoption,
        contract.beitragspause,
        contract.verschiebungRentenbeginn,
        contract.hinterbliebenenSchutz,

        ...(showEffektivkosten ? [contract.effektivkosten] : []),
        contract.vkRente,
        contract.mindestbeitrag,
        contract.mindestlaufzeit,
        contract.bu,
        contract.schieberegler4fach,

        contract.exklusivAnlage,
        contract.physischesGold,

        contract.fondsrente,

        contract.solvency2,

        contract.bemerkung,

        ...(showAnbieterWechsel ? [contract.anbieterwechsel] : []),

        getValidityString(contract)
    ] as string[];
};

const PdfTable = (
    props: {
        selectedContracts: InsuranceContractModel[];
        additionalContracts?: InsuranceContractModel[];
        showEffektivkosten: boolean,
        showExklusivAnlage: boolean,
        showPhysischesGold: boolean,
        showAnbieterWechsel: boolean,
        showFondsrente: boolean,
    }
) => {
    const contracts = typeof props.additionalContracts === 'undefined' ? props.selectedContracts : props.selectedContracts.concat(props.additionalContracts);

    return (
        <Page size="A4" orientation={'landscape'} style={{ ...PDFStyles.page, paddingRight: 40 }}>
            <View fixed style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Center>
                    <Text style={{ fontSize: 24, color: grey[200] }}>Nur zur internen Verwendung</Text>
                </Center>
            </View>
            <View fixed style={{ ...PDFStyles.row, marginBottom: 8 }}>
                {getTableHeaders(props.showEffektivkosten, props.showAnbieterWechsel).map((header, index) => {
                    return (
                        <Text
                            style={{
                                textAlign: index === 0 ? 'left' : 'center',
                                color: 'black',
                                padding: 1,
                                fontSize: 8,
                                paddingLeft: index === 0 ? 4 : 0,
                                width: (100 / columnsLength) * getCellWidthFactor(index, props.showEffektivkosten, props.showAnbieterWechsel) + '%'
                            }}
                        >
                            {header.title}
                        </Text>
                    );
                })}
            </View>

            {contracts.map((contract) => (
                <View break={false} minPresenceAhead={2}>
                    <Row>
                        {getColumns(contract, props.showEffektivkosten, props.showAnbieterWechsel).map((value: string, index: number) => {
                            return (
                                <View
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: 0.5,
                                        borderLeftColor:
                                            typeof props.additionalContracts === 'undefined'
                                                ? 'white'
                                                : index === 0
                                                    ? props.additionalContracts.map((c) => c.id).includes(contract.id)
                                                        ? 'orange'
                                                        : 'blue'
                                                    : '#bfbfbf',
                                        borderLeftWidth: typeof props.additionalContracts !== 'undefined' && index === 0 ? 4 : 0,
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        padding: 1,
                                        paddingTop: 4,
                                        paddingBottom: 4,
                                        paddingLeft: index === 0 ? 4 : 0,
                                        justifyContent: 'center',
                                        width: (100 / columnsLength) * getCellWidthFactor(index, props.showEffektivkosten, props.showAnbieterWechsel) + '%'
                                    }}
                                >
                                    <Text
                                        style={{
                                            textAlign: index === 0 ? 'left' : 'center',
                                            color: '#383838',
                                            fontSize: 8
                                        }}
                                    >
                                        {value}
                                    </Text>
                                </View>
                            );
                        })}
                    </Row>
                </View>
            ))}
        </Page>
    );
};

export default PdfTable;
