import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, CardActionArea, createStyles, Paper, withStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.grey
        },

        padding: theme.spacing(4),
        alignSelf: 'stretch',
        width: '100%',
        height: '200px'
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))(Button);

interface Props {
    icon: any;
    text: String;
    subtitle?: string;
    tooltipContent: any;
    isBasis?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionArea: {
            flex: 1,
            '&.Mui-disabled': {
                opacity: 0.6
            }
        }
    })
);



const DisabledHomePageButton: React.FC<Props> = (props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Paper onClick={handleClick}>
                <CardActionArea className={classes.actionArea} disabled={true}>
                    <ColorButton>
                        {props.icon}
                        <h2 style={{ marginBottom: 4, marginTop: 6 }}>{props.text}</h2>
                        {
                            (!props.subtitle)
                                ? <p style={{ position: 'absolute', bottom: 24 }}>{!props.isBasis ? 'Bald verfügbar' : 'nicht verfügbar'}</p>
                                : ''
                        }
                        {props.subtitle &&
                            <p style={{ position: 'absolute', bottom: 24/* , color: 'red' */ }}>{props.subtitle}</p>}
                    </ColorButton>
                </CardActionArea>
            </Paper>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: 8, maxWidth: 400, whiteSpace: 'break-spaces' }}>
                    {props.tooltipContent}
                </div>
            </Popover>
        </div>
    );
};

export default DisabledHomePageButton;
